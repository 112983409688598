import React, { useEffect, useState } from "react";

import { Card, Grid, FormControl, TextField, Button } from "@mui/material";
import _ from "lodash";

import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import "../Login/Login.css";
import GoogleIcon from "@mui/icons-material/Google";
import LockIcon from "@mui/icons-material/Lock";
import logo from "../../assets/images/logo-title.png";
import Loader from "components/Loader";
import axiosInstance from "configs/axiosConfig";
import {
  APIRouteConstants,
  dashboardRouteConstants,
} from "constants/routeConstants";
import { Link } from "react-router-dom";

const Login = ({ setOpenPrivacyPolicy, setOpenTermsAndCondition }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [loader, setLoader] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const doLogin = async (formData) => {


    setLoader(true);
    let res = await axiosInstance.post(`${APIRouteConstants.AUTH.LOGIN}`, formData);
    // console.log("response987", res)
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;
    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        setLoader(false);
        enqueueSnackbar(response.data[i], { variant: "error" });
      }
    }
    if (response && response.status === 404 && response.data) {
      enqueueSnackbar("something went wrong", { variant: "error" });
      setLoader(false)
    }


    if (response && response.status === 404 && response.data) {

      enqueueSnackbar("something went wrong", { variant: "error" });
      setLoader(false);
    }



    if (data) {
      localStorage.setItem("access-token", data.access);
      localStorage.setItem("refresh-token", data.refresh);
      window.location.href = dashboardRouteConstants.HOME;
      setLoader(false);
      // if (data['initial_setup']) window.location.href = dashboardRouteConstants.HOME;
      // else window.location.href = dashboardRouteConstants.INITIAL_SETUP;
    }

  };

  return (
    <div className="login-wrapper">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            fontSize: "1.4rem",
            fontWeight: "600",
            paddingBottom: "20px",
          }}
        >
          Start Selling online with WebbieShop
        </p>
        {/* <p style={{ fontSize: "1rem" }}>
          Webbie is a full-featured and affordable ecommerce solution that
          includes web, mobile and social stores.
        </p> */}
      </div>
      <Loader open={loader} />
      <Card className="card-wrapper">
        <span className="form-label">Sign in to your account!</span>
        <form onSubmit={handleSubmit(doLogin)}>
          <Grid container className="form-container">
            <Grid item sm={12} className="form-wrapper">
              <FormControl className="form-control" fullWidth>
                <TextField
                  margin="dense"
                  id="name"
                  placeholder="Enter Email"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Email"
                  {...register("email", { required: true })}
                  error={errors.email?.type}
                  helperText={
                    (errors.email?.type === "required" &&
                      "Email is required") ||
                    (errors.email?.type === "pattern" && "Email is invalid")
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} className="form-wrapper">
              <FormControl className="form-control" fullWidth>
                <TextField
                  margin="dense"
                  id="name"
                  placeholder="Enter Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Password"
                  {...register("password", { required: true })}
                  error={errors.password?.type}
                  helperText={
                    errors.password?.type === "required" &&
                    "Password is required"
                  }
                />
              </FormControl>
            </Grid>
            <Grid
              item
              sm={12}
              className="form-wrapper"
              style={{ paddingBottom: 0 }}
            >
              <div style={{ paddingTop: "0" }} className="link-wrapper">
                <a href="/auth/forgot-password">
                  <span>Forgot password</span>
                </a>
              </div>
            </Grid>
            <Grid item sm={12} className="form-wrapper">
              <div className="button-wrapper">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "#CD1479",
                    "&:hover": {
                      background: "#CD1479", // The color you want on hover
                    },
                  }}
                >
                  Sign In
                </Button>
              </div>
              <div className="link-wrapper">
                <a href="/auth/signup">
                  <div
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      justifyContent: "center",
                    }}
                  >
                    <span>
                      Don't have an account,
                      <span style={{ fontWeight: "bold" }}>Create now!</span>
                    </span>
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Card className="sec-card-wrapper">
        {/* <div className="button-wrapper">
                    <Button 
                        variant="outlined"
                        href="/"
                        color="primary"
                        startIcon={<GoogleIcon />}
                    >
                        Sign Up with Google 
                    </Button>
                </div>
                <div className="button-wrapper">
                    <Button 
                        variant="outlined"
                        href="/"
                        color="primary"
                        startIcon={<LockIcon />}
                    >
                        Sign Up with SSO 
                    </Button>
                </div> */}
        <div className="text-wrapper">
          {/* <span>By continuing, you agree to the Terms of Service and Privacy Policy.</span> */}
          <span>
            By continuing, you agree to the{" "}
            <Link onClick={() => setOpenTermsAndCondition(true)}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link onClick={() => setOpenPrivacyPolicy(true)}>
            Returns & Refunds
            </Link>
            .
          </span>
        </div>
      </Card>
    </div>
  );
};

export default Login;

import { useState, useEffect } from 'react';

import {
    Card,
    Divider,
    Grid,
    CardContent,
    CardActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TablePagination,
    Button
} from '@mui/material';

import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import TableWithAction from 'components/Tables/TableWithAction';

import { orderStatus } from 'constants/appData/filters';

import SearchInput from 'components/Search';

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";
import OrderDetails from './OrderDetails';
import { GLOBAL_CURRENCY } from 'constants/appConstants';
import moment from 'moment';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: GLOBAL_CURRENCY.code,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2
});

const Orders = ({ breadcrumbs }) => {
    const [loader, setLoader] = useState(false);
    const [allOrders, setOrderList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [status, setOrderStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [orderId, selectOrder] = useState('');

    const getOrderList = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_ORDERS, {
            params: {
                page: pageCount + 1,
                search: searchString,
                status: status
            },
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            setOrderList(data.results);
            setTotalCount(data.count);
            setSearchString("")
        }
        setLoader(false)
    }

    useEffect(() => {
        getOrderList()
    }, [status, pageCount])

    const handleChangePage = (e, page) => {
        setPageCount(page)
    }

    const handleEdit = (order) => {
        selectOrder(order.id)
    }

    const downloadExcel = () => {
        // Convert JSON data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(allOrders);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Write the Excel file and create a blob
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        // Save the file using file-saver
        saveAs(dataBlob, "allOrders.xlsx");
    }

    return (
        <div className="store-analytics-wrapper website-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Orders"} />
            </div>
            {orderId ?
                <OrderDetails orderId={orderId} discardUpdateOrder={() => {
                    selectOrder()
                    getOrderList()
                }} />
                : <Grid container className='component-grid'>
                    <Grid item xs={12}>
                        <Card className='list-wrapper dashboard-card-wrapper'>
                            <CardContent>
                                <div className='header-wrapper'>
                                    <span className='header-text'>Orders Details</span>
                                </div>
                                <Divider />
                                <div className='content-wrapper content-flex'>
                                    <span className='content-text'>
                                        You can manage your customer orders here. Below you will see a list of your current orders based on their status, you can see whether an order is placed, whether it has been paid for and whether or not you have shipped the order to the customer. You can manage each order by clicking on the “Manage” order button. You can filter orders using the filter control, alternatively you can also search for orders by customer name or email address.
                                    </span>
                                </div>
                                <div className='content-wrapper content-flex'  >
                                    <Grid container className='filter-wrapper' >
                                        <Grid item md={2} sm={2} xs={4}>
                                            <span className='detail-sec-text'>
                                                Filter by:
                                            </span>
                                        </Grid>
                                        <Grid item md={2} sm={3} xs={8} className='select-wrapper'>
                                            <FormControl fullWidth>
                                                <InputLabel sx={{ fontSize: '16px' }} size="small" id="demo-simple-select-label">Order Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={status}
                                                    size="small"
                                                    label="Order Status"
                                                    onChange={e => {
                                                        setOrderStatus(e.target.value)
                                                    }}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .MuiMenuItem-root": {
                                                                fontSize: '16px' // Change this value to your desired font size
                                                            },
                                                            "& .MuiList-root": {
                                                                fontSize: '16px' // This affects the list item font size
                                                            }
                                                        }
                                                    }}
                                                    sx={{
                                                        fontSize: '16px' // Change this value to your desired font size
                                                    }}
                                                >
                                                    <MenuItem value={''}>All</MenuItem>
                                                    {orderStatus.length > 0 ? orderStatus.map((status) => (
                                                        <MenuItem key={status.value} value={status.value}>{status.label}</MenuItem>
                                                    )) : ''}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item sm={2} className='select-wrapper'>
                                    <FormControl fullWidth>
                                        <InputLabel size="small" id="demo-simple-select-label">Payment Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={paymentStatus}
                                            label="Payment Status"
                                            size="small"
                                            onChange={e => {
                                                setPaymentStatus(e.target.value)
                                                getOrderList()
                                            }}
                                        >
                                            <MenuItem value={''}>All</MenuItem>
                                            <MenuItem value={'processing'}>Processing</MenuItem>
                                            <MenuItem value={'complete'}>Complete</MenuItem>
                                            <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                        {/* <Grid item md={4} sm={1} xs={12} /> */}
                                        <Grid item md={2} sm={2} xs={4} style = {{justifyContent:"space-around",marginLeft:"90px", marginRight:"40px", marginTop:"15px"}}>
                                            {/* <button onClick={downloadExcel}>Download Excel</button> */}
                                            <Button fullWidth sx={{
                                                width: { xs: '38vw', sm: '9vw', md: '12vw' }, // Responsive width
                                                // minWidth: '150px', // Minimum width
                                                backgroundColor: "black",
                                                color: "white"
                                            }}
                                                // sx={{
                                                //      // White text (dynamic contrast)
                                                //     backgroundColor: 'black',
                                                //     '&:hover': {
                                                //         backgroundColor: '#333', // Darker black on hover
                                                //     },
                                                //     paddingLeft: '16px', // Adjust padding for icon placement
                                                //     paddingRight: '16px',
                                                // }}
                                                onClick={downloadExcel}
                                                variant="contained"
                                            // startIcon={icon ? <📄 style={{ marginRight: '8px', width: '16px', height: '16px' }} /> : null} // Conditionally render icon
                                            >
                                                📄 <span style={{ fontSize: "12px", marginLeft:"3px" }}> Download Excel </span> {/* Render the button text (passed as children) */}
                                            </Button>
                                        </Grid>
                                        {/* <Grid item md={4} sm={1} xs={12} /> */}
                                        <Grid item md={4} sm={6} xs={12} className='content-flex content-flex-right'>
                                            <SearchInput
                                                value={searchString}
                                                onChange={(e) => {
                                                    setSearchString(e.target.value)

                                                }}
                                                style={{ fontSize: '14px' }}
                                                onEnter={getOrderList}
                                            // onBlur={getOrderList}
                                            />
                                        </Grid>
                                    </Grid>

                                </div>
                                {allOrders.length === 0 ?
                                    <div className='content-wrapper content-flex content-flex-center'>
                                        <NoData />
                                        <div className='detail-action-wrapper content-flex content-flex-center'>
                                            <span className='detail-sec-text'>
                                                No order details yet
                                            </span>
                                        </div>
                                    </div> :
                                    <Grid container className='dashboard-table-container' spacing={4} wrap='wrap'>
                                        <Grid item md={12} sm={12}>
                                            <TableWithAction
                                                domain={"orderpage"}
                                                rows={allOrders}
                                                headers={[{
                                                    label: 'Order Id',
                                                    name: 'order_id',
                                                    width: '10%'
                                                }, {
                                                    label: 'Transaction Id',
                                                    name: 'transaction_id',
                                                    width: '10%'
                                                }, {
                                                    label: 'Product Name',
                                                    name: 'product_name',
                                                    width: '20%'
                                                }, {
                                                    label: 'Quantity',
                                                    name: 'quantity',
                                                }, {
                                                    label: 'Unit Price',
                                                    type: 'custom',
                                                    arguments: ['unit_price'],
                                                    component: (unitPrice) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                <span>{formatCurrency.format(parseInt(unitPrice) || 0)}</span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Total Price',
                                                    type: 'custom',
                                                    arguments: ['total_price'],
                                                    component: (totalPrice) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                <span>{formatCurrency.format(parseInt(totalPrice) || 0)}</span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Created On',
                                                    type: 'custom',
                                                    arguments: ['created'],
                                                    component: (created) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                <span>{moment(created).format("[On] ddd[,] D MMM YYYY")}</span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Order Status',
                                                    name: 'delivery_status_type',
                                                    // type: 'custom',
                                                    // arguments: ['delivery_status_type'],
                                                    // component: (type) => {
                                                    //     return (
                                                    //         <div className='cell-label'>
                                                    //             <span>{type}</span>
                                                    //             {/* <CheckCircleIcon /> <span>{count > 0 ? "Available" : "Out of Stock"}</span> */}
                                                    //         </div>
                                                    //     )
                                                    // }
                                                }]}
                                                handleEdit={handleEdit}
                                            />
                                            <TablePagination
                                                rowsPerPageOptions={[]}
                                                component="div"
                                                count={totalCount}
                                                rowsPerPage={totalCount < 10 ? totalCount : 10}
                                                page={pageCount}
                                                onPageChange={handleChangePage}
                                            />
                                        </Grid>
                                    </Grid>}
                            </CardContent>
                            <CardActions className='footer-wrapper'>
                                <div className="action-wrapper">
                                    {/* <Button variant="contained" color="error" onClick={() => discardNewProduct(false)}>
                                Discard
                            </Button>
                            <Button variant="contained" color="secondary">
                                Save Product
                            </Button> */}
                                </div>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>}
        </div>
    )
}

// const StyledButton = styled(Button)(({ theme }) => ({
//     color: theme.palette.getContrastText('black'), // White text (dynamic contrast)
//     backgroundColor: 'black',
//     '&:hover': {
//         backgroundColor: '#333', // Darker black on hover
//     },
//     paddingLeft: '16px', // Adjust padding for icon placement
//     paddingRight: '16px', // Adjust padding for icon placement

// }));

export default Orders
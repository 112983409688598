import React, { createContext, useContext, useState, useEffect } from 'react';
import SimpleModal from 'views/Dashboard/Modal/DocsModal';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  
  const isChecklistOpened = localStorage.getItem("ischecklistopened");
  // Initialize state based on the value in localStorage
  const [open, setOpen] = useState(false);
  // Effect to run when the component mounts
  const isloggedin = localStorage.getItem("access-token");
  useEffect(() => {
    const isChecklistOpened = localStorage.getItem("ischecklistopened");
    // If the checklist hasn't been opened before, open the modal
    
    console.log("docsmodal123456",isloggedin ==null)
    if ((isChecklistOpened !== "true" || isChecklistOpened == "null" ) ) {
      setOpen(true);
    }
  }, []);
  const handleOpen = () => setOpen(true);

  const handleClose = () => { 
    localStorage.setItem("ischecklistopened", true);  // Save to localStorage when the modal is closed
    setOpen(false);
  };

  return (
    <ModalContext.Provider value={{ open, handleOpen, handleClose }}>
      
     {isloggedin !=null && <SimpleModal open={open} handleClose={handleClose}  />}
      {children}

    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};

import React, { Fragment, useState } from "react";
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { display } from "@mui/system";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getArguments = (obj, keyList) => {
  let args = [];
  for (let key of keyList) {
    args.push(obj[key]);
  }
  return args;
};

function TableWithAction({
  headers,
  rows,
  handleEdit,
  handleDeleteProduct,
  domain,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    handleDeleteProduct(currentRow);
    setOpenDialog(false);
  };

  const handleDelete = (event, row) => {
    // Your delete logic here
    event.stopPropagation();
    console.log("Delete row:", row);
    handleDeleteProduct(row);
    handleClose();
  };

  return (
    <Fragment>
      {domain === "product" ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  {headers.map((header, i) => (
                    <StyledTableCell width={header.width} key={i}>
                      <span>{header.label}</span>
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="center">
                    <span>Actions</span>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rows.length
                  ? rows.map((row) => (
                      <StyledTableRow
                        onClick={() => {
                          handleEdit(row);
                        }}
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        {headers.map((header, i) => (
                          <StyledTableCell component="th" scope="row" key={i}>
                            {header.type === "status" ? (
                              <div className="cell-label">
                                <CheckCircleIcon />{" "}
                                <span>
                                  {row[header.name] ? "Active" : "Inactive"}
                                </span>
                              </div>
                            ) : header.type === "custom" ? (
                              header.component(
                                ...getArguments(row, header.arguments)
                              )
                            ) : (
                              <span className="cell-text">
                                {row[header.name]}
                              </span>
                            )}
                          </StyledTableCell>
                        ))}
                        <StyledTableCell align="center">
                          <IconButton
                            aria-label="more"
                            onClick={(event) => handleClick(event, row)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            {/* <MenuItem onClick={() => console.log("productid",currentRow)}> */}
                            <MenuItem onClick={() => handleEdit(currentRow)}>
                              <EditIcon
                                fontSize="small"
                                sx={{ color: "#1876d2" }}
                              />{" "}
                              Edit
                            </MenuItem>
                            <MenuItem onClick={(e) => handleOpenDialog(e)}>
                              <DeleteIcon
                                fontSize="small"
                                sx={{ color: "red" }}
                              />{" "}
                              Delete
                            </MenuItem>
                          </Menu>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="confirm-delete-dialog-title"
            aria-describedby="confirm-delete-dialog-description"
          >
            <DialogTitle
              id="confirm-delete-dialog-title"
              sx={{ fontSize: "1rem" }}
            >
              Confirm Delete
            </DialogTitle>
            <Divider />
            <DialogContent>
              <DialogContentText
                id="confirm-delete-dialog-description"
                sx={{ fontSize: "0.9rem" }}
              >
                Are you sure you want delete this product?
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: "0 !important" }}>
              {/* <Grid sx={{display:"flex",}}> */}
              <Button
                sx={{ width: "100%" }}
                fullWidth
                onClick={handleCloseDialog}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                fullWidth
                sx={{ width: "100%", borderRadius: 0 }}
                onClick={handleConfirmDelete}
                variant="contained"
                autoFocus
              >
                Confirm
              </Button>
              {/* </Grid> */}
            </DialogActions>
          </Dialog>
        </>
      ) : domain === "orderpage"? ( <TableWithActionOrder
        headers={headers}
        rows={rows}
        handleEdit={handleEdit}
      />):(
        <TableWithActionWithDomain
          headers={headers}
          rows={rows}
          handleEdit={handleEdit}
        />
      )}
    </Fragment>
  );
}





function TableWithActionWithDomain({ headers, rows, handleEdit }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            {headers.map((header, i) => (
              <StyledTableCell width={header.width}>
                <span>{header.label}</span>
              </StyledTableCell>
            ))}
            <StyledTableCell align="center">
              <span>Actions</span>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.length
            ? rows.map((row) => (
                <StyledTableRow
                  key={row.name}
                  onClick={() => {
                    handleEdit(row);
                  }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  {headers.map((header, i) => (
                    <StyledTableCell component="th" scope="row">
                      {header.type == "status" ? (
                        <div className="cell-label">
                          <CheckCircleIcon />{" "}
                          <span>
                            {row[header.name] ? "Active" : "Inactive"}
                          </span>
                        </div>
                      ) : header.type == "custom" ? (
                        header.component(...getArguments(row, header.arguments))
                      ) : (
                        <span className="cell-text">{row[header.name]}</span>
                      )}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="center">
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        handleEdit(row);
                      }}
                    >
                      Manage
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


function TableWithActionOrder({ headers, rows, handleEdit }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            {headers.map((header, i) => (
              <StyledTableCell width={header.width}>
                <span>{header.label}</span>
              </StyledTableCell>
            ))}
            <StyledTableCell align="center">
              <span>Actions</span>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0
            ? rows.map((row) => (
                <StyledTableRow
                  key={row.name}
                  onClick={() => {
                    if (row?.delivery_status_type !== "Order Failed") {
                      handleEdit(row);
                    }
                  }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  {headers.map((header, i) => (
                    <StyledTableCell component="th" scope="row">
                      {header.type == "status" ? (
                        <div className="cell-label">
                          <CheckCircleIcon />{" "}
                          <span>
                            {row[header.name] ? "Active" : "Inactive"}
                          </span>
                        </div>
                      ) : header.type == "custom" ? (
                        header.component(...getArguments(row, header.arguments))
                      ) : (
                        <span className="cell-text">{row[header.name]}</span>
                      )}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="center">
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        handleEdit(row);
                      }}
                      disabled={
                        row?.delivery_status_type !== "Order Failed"
                          ? false
                          : true
                      }
                    >
                      Manage
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableWithAction;

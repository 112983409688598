import React from "react";
import WithFooter from "./WithFooter";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { Link, useNavigate } from "react-router-dom";
function Aboutv1() {
  const naviagte = useNavigate();
  return (
    <WithFooter>
      <div className="aboutpage">
        <div className="hero">
          <div className="hero__herosection">
            <img
              src="images/homebanner-bg-0.png"
              alt=""
              className="hero__herosection__img1"
            />
            <img
              src="images/homebanner-bg-1.svg"
              alt=""
              className="hero__herosection__img2"
            />
            <div className="hero__content">
              <div className="hero__content__div">
                <div className="hero__content__div__text">
                  <div className="hero__content__div__text__heading">
                    About us
                  </div>
                  <div className="hero__content__div__text__content">
                    We've combined creating an online store, payment, shipping
                    and shop management into a single, easy-to-use platform.
                    Unleash that Entrepreneurial spirit in you :)
                  </div>
                  <div className="hero__content__div__text__button">
                    <Link to={"/auth/signup"}>Get started — it's free</Link>
                  </div>
                </div>
                <div className="hero__content__div__image">
                  <img src="images/about-hero-image.svg" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutpage__container">
          <div className="aboutpage__container__div">
            <div className="aboutpage__container__div__para">
              <p>
                Webbieshop, stemming from the concept of a "webbie" as someone
                knowledgeable about the World Wide Web, aims to support
                individuals venturing into online business.
              </p>
              <p>
                We strive to empower those apprehensive about the internet by
                providing a seamless experience through Webbieshop, enabling
                them to achieve online success with just a click.
              </p>
              <p>
                As part of The Venture Studio, a hub of innovative solutions,
                Webbieshop has been supported from the start by trusted partners
                who share our vision. Together, we deliver comprehensive
                services that empower you to take full control of your online
                store, simplifying the journey from setup to success.
              </p>
              {/* <p>
                With Peach Payments handling transactions and Shiprazor ensuring
                smooth shipping, all you need is an account with both synced
                seamlessly with Webbieshop. This marks the future of shopping,
                extending beyond just a website with upcoming features like
                social commerce and machine learning advancements to further
                assist you and your users.
              </p> */}
              <p>
                Let's launch your store on Webbieshop in under 15 minutes and
                embark on your online journey.
              </p>
            </div>
          </div>

          <div className="aboutpage__container__trustdiv">
            <div className="aboutpage__container__trustdiv__left">
              <div className="aboutpage__container__trustdiv__left__title">
                <span>TRUSTED AND </span>
                <span>SUPPORTED BY </span>
              </div>
              <div className="aboutpage__container__trustdiv__left__images">
                <div className="aboutpage__container__trustdiv__left__images__top">
                  <div>
                    <img src="images/brandimagewhite1.svg" alt="brand image" />
                  </div>
                  <div>
                    <img src="images/brandimagewhite2.svg" alt="brand image" />
                  </div>
                </div>
                <div className="aboutpage__container__trustdiv__left__images__top">
                  <div>
                    <img
                      src="images/brandimagewhite3.svg"
                      className="wid80"
                      alt="brand image"
                    />
                  </div>
                  <div>
                    <img src="images/white-landscape.svg" alt="brand image" />
                  </div>
                  <div>
                    <img src="images/brandimagewhite4.svg" alt="brand image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="aboutpage__container__trustdiv__right">
              <div>
                <span> OPEN YOUR DIGITAL </span>
                <span> STORE NOW </span>
              </div>
              <div className="aboutpage__container__trustdiv__right__nextbtn">
                <div className="aboutpage__container__trustdiv__right__nextbtn__imgdiv">
                  <img
                    onClick={() => naviagte("/auth/signup")}
                    src="images/rightarrowpink.svg"
                    alt="right icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutpage__container__welcomediv">
          <div>EVERYONE IS WELCOME</div>
          <div>
            <div>Freelancers</div>
            <div>Small Business</div>
            <div>Big Business</div>
          </div>
        </div>
        <div className="homepage__support">
          <div className="homepage__support__div">
            <div className="homepage__support__div__card">
              <div className="homepage__support__div__card__image">
                <img src="images/supportimg1.svg" alt="Image" />
                <div className="roundshape"></div>
              </div>
              <div className="homepage__support__div__card__content">
                <div>Responsive Support</div>
                <div>
                  Prompt assistance and swift resolution to any inquiries,
                  concerns, or issues.
                </div>
              </div>
              <div className="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div className="homepage__support__div__card__nextbtn__imgdiv">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div className="homepage__support__div__card">
              <div className="homepage__support__div__card__image">
                <img src="images/supportimg2.svg" alt="Image" />
                <div className="roundshape"></div>
              </div>
              <div className="homepage__support__div__card__content">
                <div>Simplified Marketing Tools</div>
                <div>
                  Streamline promotional efforts with easy to use and efficient
                  marketing tools.
                </div>
              </div>
              <div className="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div className="homepage__support__div__card__nextbtn__imgdiv">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div className="homepage__support__div__card">
              <div className="homepage__support__div__card__image">
                <img src="images/supportimg3.svg" alt="Image" />
                <div className="roundshape"></div>
              </div>
              <div className="homepage__support__div__card__content">
                <div>Mobile Optimized</div>
                <div>
                  A highly responsive interface for effortless mobile shopping.
                </div>
              </div>
              <div className="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div className="homepage__support__div__card__nextbtn__imgdiv">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FailingComponent/> */}
    </WithFooter>
  );
}



const FailingComponent = () => {
  // Simulated error
  throw new Error("Test Error!");
};
export default Aboutv1;

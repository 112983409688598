import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  CardActions,
  Typography,
  CardMedia,
  Box,
  TextField,
  InputAdornment,
  FormLabel,
  FormControl,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Checkbox,
  Paper,
  FormControlLabel
} from '@mui/material';
import _, { size } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import Carousel from 'components/Carousel';
import { useSelector } from 'react-redux';
import { updateUserDetail } from 'utils/auth';
import { GLOBAL_CURRENCY } from 'constants/appConstants';
import ImageUploader from 'components/Inputs/ImageUploader';
import { useSnackbar } from 'notistack';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PendingIcon from '@mui/icons-material/Pending';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OTP from 'components/Inputs/OTP';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Whastapp from 'components/Whatsapp/Whastapp';
import SimpleModal from '../Modal/DocsModal';
import { useModal } from 'utils/context';
// import OrderDetails from './OrderDetails';
import OrderDetails from '../Store/Orders/OrderDetails';
import TableWithAction from 'components/Tables/TableWithAction';
import { orderStatus } from 'constants/appData/filters';
import SearchInput from 'components/Search';
import moment from 'moment';
import { fontSize, fontWeight, width } from '@mui/system';
import CloseIcon from "@mui/icons-material/Close";

let formatCurrency = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'ZAR',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 2
});

const Home = ({ breadcrumbs }) => {

  const [expandedItems, setExpandedItems] = useState({});
  const toggleExpand = (orderId) => {
    setExpandedItems({
      ...expandedItems,
      [orderId]: !expandedItems[orderId],
    });
  };


  // const [loader, setLoader] = useState(false);
  const [allOrders, setOrderList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [status, setOrderStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [orderId, selectOrder] = useState('');
  let shouldShowSimpleModal;
  const { open, handleClose, handleOpen } = useModal();
  const [loader, setLoader] = useState(true);
  const [salesSummary, setSalesSummary] = useState({});
  const orders = [
    { id: 1235, time: "3 mins ago", items: "2 x Aloo Parantha, 3 x French Fries, 1 x Burger", status: "Pending", color: "#E53935" },
    { id: 1236, time: "3 mins ago", items: "2 x Aloo Parantha, 3 x French Fries, 1 x Burger", status: "Pending", color: "#E53935" },
    { id: 2235, time: "2 mins ago", items: "2 x Aloo Parantha, 3 x French Fries, 1 x Burger", status: "Processing", color: "#FB8C00" },
    { id: 3235, time: "2 mins ago", items: "2 x Aloo Parantha, 3 x French Fries, 1 x Burger", status: "Accepted", color: "#43A047" },
  ];
  const [bestProduct, setBestProductList] = useState([]);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const loggedInUser = useSelector(state => state.userDetails);
  const { user } = loggedInUser;
  const [logoUrl, setLogoUrl] = useState('');
  const [logo, setLogo] = useState([]);
  const [checklist, setCheckListStatus] = useState({});
  const [checkListComplete, setIfCheckListComplete] = useState(false);
  const [shippingAmount, setshippingAmount] = useState(null);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);


  // console.log("checklist",checklist)

  const getSalesReport = async () => {
    let res = await axiosInstance.get(APIRouteConstants.STORE.SALES_ANALYTICS)
    if (res === false) {
      setLoader(false);
      return
    }

    let { data, response } = res;

    if (data) {
      setSalesSummary(data)
    }
  }


  const getShippingAmount = async () => {
    try {
      setLoader(true);
      let res = await axiosInstance.get(
        APIRouteConstants.SHIPRAZOR.GET_SHIPPING_AMOUNT
      );

      if (res === false) {
        setLoader(false);
        return
      }

      let { data, response } = res;
      if (data && res.status) {
        setshippingAmount(data?.amount);
        sessionStorage.setItem("shippng_amount", JSON.stringify(data?.amount))
      } else {
        enqueueSnackbar(response?.data?.error, { variant: "error" });
      }
      setLoader(false);
    } catch (err) {
      console.log("getShippingAmount", err);
    }
  };


  const getBestSellingProducts = async () => {
    let res = await axiosInstance.get(APIRouteConstants.STORE.TOP_COLLECTION)
    let { data, response } = res;

    if (res === false) {
      setLoader(false);
      return
    }

    if (data) {
      setBestProductList(data)
    }
    setLoader(false)
  }

  console.log("user", user)
  const loadWebsiteDetail = async () => {
    if (!_.isEmpty(user)) {
      var webSetup = false
      // if (user.storeName && user.hostName && user?.logo !== null && user?.logo !== undefined) webSetup = true
      if (user.storeName && user.hostName) webSetup = true

      if (user.logo) {
        setLogo([user.logo])
        setLogoUrl(user.logo)
      }
      setCheckListStatus({
        webSetup: webSetup,
        // shiprazorSetup: user?.shiprazor_key_exist ? true : (user.own_shipping_selected && shippingAmount !== null) ? true : false,
        shiprazorSetup: user?.is_shiprazor_selected ? true : (user.own_shipping_selected && user?.shipping_amount !== null) ? true : false,
        warehouseSetup: user.warehouse_exist,
        categoryExist: user.is_category,
        productExist: user.is_product,
        paymentSetup: user.is_payment_selected
      })
    }
    setLoader(false)
  }
  // console.log("selected", user.is_shiprazor_selected, user?.own_shipping_selected)
  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (user.isPublished) {
        getSalesReport()
        getBestSellingProducts()
      } else {
        let setup = true
        if (!user.storeName || !user.hostName) setup = false
        // else if (!user.shiprazor_key_exist) setup = false
        else if (!user.is_shiprazor_selected && !user.own_shipping_selected) setup = false
        else if (!user.warehouse_exist) setup = false
        else if (!user.is_category) setup = false
        else if (!user.is_product) setup = false
        else if (!user.is_product) setup = false
        else if (!user.is_payment_selected) setup = false
        setIfCheckListComplete(setup)
      }
      loadWebsiteDetail()
    }
    if (user?.own_shipping_selected) {
      getShippingAmount()
    }
  }, [user])

  useEffect(() => {
    handleNext();
  }, [])

  const getUser = async () => {
    let res = await axiosInstance.get(APIRouteConstants.AUTH.ME)

    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data && data.success) {
      const user = data.data;
      updateUserDetail(user)
    }
    setLoader(false);
  }

  const handlePublish = async () => {
    setLoader(true)
    let res = await axiosInstance.put(APIRouteConstants.WEBSITE.PUBLISH_WEBSITE, {
      "is_Published": true
    })

    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (!_.isEmpty(data)) {
      getUser();
    }
  }

  const handleStoreUpdate = async () => {
    let res = await axiosInstance.post(APIRouteConstants.WEBSITE.UPDATE_STORE)
    if (res === false) {
      setLoader(false);
      return
    }
    let { data } = res;

    if (data) {
      getUser()
    }
  }

  const handleNext = async () => {
    setLoader(true)
    await getUser()
    setLoader(false)
  };


  shouldShowSimpleModal = localStorage.getItem("ischecklistopened")




  const getOrderList = async () => {
    setLoader(true)
    let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_ORDERS_DASHBOARD, {
      params: {
        page: pageCount + 1,
        paginate: true,
        // search: searchString,
        // status: status
      },
    })
    if (res === false) {
      setLoader(false);
      return
    }
    let { data } = res;

    if (data) {
      setOrderList(data.results);
      setTotalCount(data.count);
      setSearchString("")
    }
    setLoader(false)
  }

  useEffect(() => {
    getOrderList()
  }, [pageCount])

  const handleChangePage = (e, page) => {
    setPageCount(page)
  }

  const handleEdit = (order) => {
    selectOrder(order.id)
  }

  // const handleStatusUpdate = (event, orderid) => {
  //   updateOrderStatus(event.target.value, orderid);
  // };

  // const handleStatusUpdate = (event, orderIds) => {
  //   orderIds.forEach(orderId => {
  //     updateOrderStatus(event.target.value, orderId);
  //   });
  // };

  // const handleStatusUpdate = (event) => {
  //   if (!Array.isArray(selectedOrderIds) || selectedOrderIds.length === 0) { // ✅ Prevents errors
  //     enqueueSnackbar("Please select at least one order", { variant: "warning" });
  //     return;
  //   }
  //   updateOrderStatus(event.target.value, selectedOrderIds);
  // };
  // const handleStatusUpdate = (event, orderIds) => {
  //   if (!Array.isArray(orderIds) || orderIds.length === 0) {
  //     enqueueSnackbar("Please select at least one order", { variant: "warning" });
  //     return;
  //   }
  //   updateOrderStatus(event.target.value, orderIds);
  // };

  const handleStatusUpdate = (event) => {
    if (!Array.isArray(selectedOrderIds) || selectedOrderIds.length === 0) {
      enqueueSnackbar("Please select at least one order", { variant: "warning" });
      return;
    }

    console.log("Updating orders:", selectedOrderIds); // Debugging

    updateOrderStatus(event.target.value, selectedOrderIds);
  };

  const handlePaymentStatus = (transactionId, payBy, orderNotes) => {
    updatePaymentStatus(transactionId, payBy, orderNotes);
  };
  // const handleCheckboxChange = (orderId) => {
  //   setSelectedOrderIds(prevSelected =>
  //     prevSelected.includes(orderId)
  //       ? prevSelected.filter(id => id !== orderId) // Remove if already selected
  //       : [...prevSelected, orderId] // Add if not selected
  //   );
  //   console.log(orderId, 'vcxfvghjkjhgfdfghjkhbvcvfgbhjkjhgbvfghjkjhnbv')
  // };

  // const handleCheckboxChange = (orderId) => {
  //   setSelectedOrderIds((prevSelected) => {
  //     const updatedSelection = prevSelected.includes(orderId)
  //       ? prevSelected.filter((id) => id !== orderId) // Remove if already selected
  //       : [...prevSelected, orderId]; // Add if not selected

  //     console.log(updatedSelection, "Updated selected order IDs");

  //     return updatedSelection;
  //   });
  // };


  const handleCheckboxChange = (orderId) => {
    setSelectedOrderIds(prevSelected => {
      const updatedSelection = prevSelected.includes(orderId)
        ? prevSelected.filter(id => id !== orderId) // Remove if already selected
        : [...prevSelected, orderId]; // Add if not selected

      console.log("Updated selection:", updatedSelection); // Debugging
      return updatedSelection;
    });
  };
  const handleSelectAll = (event, relatedOrders) => {
    if (event.target.checked) {
      const allIds = relatedOrders.map((ro) => ro.id);
      setSelectedOrderIds(allIds);
    } else {
      setSelectedOrderIds([]);
    }
  };

  const getOrderDetail = async (orderId) => {
    let res = await axiosInstance.get(APIRouteConstants.STORE.GET_ORDER(orderId))
    if (res === false) {
      setLoader(false);
      return
    }
    let { data } = res;

    if (data) {
      console.log("oderdetail", data)
      // console.log(data)
      // setOrderDetail(data)
      // console.log('getOrderStatusCycle(data.delivery_status) ', getOrderStatusCycle(data.delivery_status))
      // if (data.delivery_status)
      // setOrderCycle(getOrderStatusCycle(data.delivery_status))
    }
    setLoader(false)
  }

  // const updateOrderStatus = async (status, orderId) => {
  //   // console.log("cfghjklskjhgfcxcfghj", status, orderId.id)
  //   setLoader(true)
  //   let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER, {
  //     order_ids: orderId,
  //     delivery_status: status
  //   })
  //   if (res === false) {
  //     setLoader(false);
  //     return
  //   }
  //   let { data, response } = res;

  //   if (data) {
  //     getOrderList(orderId.id)
  //   } else {
  //     enqueueSnackbar(response?.data?.error, { variant: "error" });
  //     setLoader(false);
  //   }
  // }

  // const updateOrderStatus = async (status, orderIds) => {
  //   setLoader(true);

  //   try {
  //     const res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER, {
  //       order_ids: orderIds,
  //       delivery_status: status
  //     });

  //     if (!res || !res.data) {
  //       setLoader(false);
  //       enqueueSnackbar("Failed to update order status", { variant: "error" });
  //       return;
  //     }

  //     orderIds.forEach(id => getOrderList(id));
  //     setSelectedOrderIds([]); // Clear selection after update

  //   } catch (error) {
  //     enqueueSnackbar(error?.response?.data?.error || "Something went wrong", { variant: "error" });
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  // const updateOrderStatus = async (status, orderIds) => {
  //   setLoader(true);

  //   // Validate orderIds to prevent errors
  //   if (!Array.isArray(orderIds) || orderIds.length === 0) {
  //     enqueueSnackbar("Select at least one item to proceed", { variant: "warning" });
  //     setLoader(false);
  //     return;
  //   }

  //   try {
  //     const res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER, {
  //       order_ids: orderIds,
  //       delivery_status: status
  //     });

  //     if (!res?.data) {
  //       enqueueSnackbar("Failed to update order status", { variant: "error" });
  //       return;
  //     }

  //     orderIds.forEach(id => getOrderList(id));
  //     setSelectedOrderIds([]); // Clear selection after update
  //     enqueueSnackbar("Order status updated successfully", { variant: "success" });

  //   } catch (error) {
  //     console.error("Update order error:", error);
  //     enqueueSnackbar(error?.response?.data?.error || "Something went wrong", { variant: "error" });
  //   } finally {
  //     setLoader(false);
  //   }
  // };


  const updateOrderStatus = async (status, orderIds) => {
    setLoader(true);

    // Get the latest selected orders
    const latestOrderIds = [...orderIds]; // Ensure we are working with a fresh copy

    console.log("Selected Order IDs:", latestOrderIds); // Debugging

    // Validate orderIds to prevent errors
    if (!Array.isArray(latestOrderIds) || latestOrderIds.length === 0) {
      enqueueSnackbar("Select at least one item to proceed", { variant: "warning" });
      setLoader(false);
      return;
    }

    try {
      const res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER, {
        order_ids: latestOrderIds,
        delivery_status: status
      });

      // if (!res?.data) {
      //   enqueueSnackbar("Failed to update order status", { variant: "error" });
      //   return;
      // }

      if (res?.response?.data?.error) {
        enqueueSnackbar(res?.response?.data?.error, { variant: "error" });
        return;
      }


      latestOrderIds.forEach(id => getOrderList(id));
      setSelectedOrderIds([]); // Clear selection after update
      enqueueSnackbar("Order status updated successfully", { variant: "success" });

    } catch (error) {
      console.error("Update order error:", error);
      enqueueSnackbar(error?.response?.data?.error || "Something went wrong", { variant: "error" });
    } finally {
      setLoader(false);
    }
  };




  const updatePaymentStatus = async (transactionId, payBy, orderNotes) => {
    // console.log("cfghjklskjhgfcxcfghj", status, orderId.id)
    setLoader(true)
    let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_PAYMENT_STATUS, {
      transaction_id: transactionId,
      pay_by: payBy,
      order_notes: orderNotes
    })
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data) {
      getOrderList(orderId.id)
      enqueueSnackbar("Payment status updated successfully.", { variant: "success" });
    } else {
      enqueueSnackbar(response?.data?.pay_by[0], { variant: "error" });
      setLoader(false);
    }
  }

  console.log("shouldShowSimpleModal", open)
  return (
    <div className="home-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs
          list={breadcrumbs}
          name={user?.isPublished ? "Dashboard" : "Complete your store setup"}
        />
      </div>
      {/* <SimpleModal open={open} handleClose={handleClose}  /> */}
      <Grid
        container
        className="component-grid"
        spacing={3}
        sx={{ gridAutoRows: "1fr" }}
      >
        {user?.isPublished ?
          <Card className="setup-card dashboard-card-wrapper" style={{ height: "100%", width: "100%" }}>
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">Order List</span>
                {/* ... filter and search section */}

                {allOrders.length === 0 ? (
                  <div className='content-wrapper content-flex content-flex-center'>
                    <NoData />
                    <div className='detail-action-wrapper content-flex content-flex-center'>
                      <span className='detail-sec-text'>No order details yet</span>
                    </div>
                  </div>
                ) : (

                  // <Grid container spacing={2} justifyContent="space-around">
                  //   {/* Outer Grid Container */}

                  //   {/* New Orders Column */}
                  //   {/* <Grid item xs={12} sm={6} md={4} lg={3}> */}
                  //   {/* Responsive Grid Item */}
                  //   <Card style={{ backgroundColor: "#D3D3D3", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       New Orders
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Order Placed").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Order Placed")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab1"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>

                  //   {/* Processing Column */}
                  //   <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       Processing
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab2"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>

                  //   {/* Delivered Column */}
                  //   <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       Delivered / Collected
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Delivered").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Delivered")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab3"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>
                  //   {/* </Grid> */}
                  // </Grid>







                  // #  previous

                  // <Grid container spacing={2} justifyContent="space-around">
                  //   {/* Outer Grid Container */}

                  //   {/* New Orders Column */}
                  //   {/* <Grid item xs={12} sm={6} md={4} lg={3}> */}
                  //   {/* Responsive Grid Item */}
                  //   <Card style={{ backgroundColor: "#D3D3D3", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       New Orders
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Order Placed").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Order Placed")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab1"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>

                  //   {/* Processing Column */}
                  //   <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       Processing
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab2"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>

                  //   {/* Delivered Column */}
                  //   <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       Delivered / Collected
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Delivered").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Delivered")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab3"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>
                  //   {/* </Grid> */}
                  // </Grid>




                  <Grid container spacing={2} justifyContent="space-between">
                    {/* New Orders Column */}
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Card style={{ backgroundColor: "#D3D3D3", marginTop: "40px", padding: "10px", borderRadius: "25px" }}>
                        <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                          New Orders
                        </Grid>
                        {allOrders.filter(order =>
                          order.related_orders?.some(related => related.delivery_status_str === "Order Placed")
                        ).length === 0 ? (
                          <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                            <NoData />
                            <div className='detail-action-wrapper content-flex content-flex-center'>
                              <span className='detail-sec-text'>No order details yet</span>
                            </div>
                          </div>
                        ) : (
                          // allOrders.filter(order => order.delivery_status_type === "Order Placed")
                          //   .map(order => (
                          //     <CardContent key={order.order_id}>
                          //       <div style={{ backgroundColor: "grey.300" }}>
                          //         <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab1"} toggleExpand={toggleExpand} />
                          //       </div>
                          //     </CardContent>
                          //   ))
                          allOrders
                            .filter(order =>
                              order.related_orders?.some(related => related.delivery_status_str === "Order Placed")
                            )
                            .slice(0, 10) // Show only the first 5 orders
                            .map(order => (
                              <CardContent key={order.transaction_id}>
                                <div style={{ backgroundColor: "grey.300" }}>
                                  <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab1"} toggleExpand={toggleExpand} selectedOrderIds={selectedOrderIds} handleCheckboxChange={handleCheckboxChange} handleSelectAll={handleSelectAll} />
                                </div>
                              </CardContent>
                            ))
                        )}
                      </Card>
                    </Grid>

                    {/* Processing Column */}
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", padding: "10px", borderRadius: "25px" }}>
                        <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                          Processing
                        </Grid>
                        {allOrders.filter(order => order.related_orders?.some(related => ["Confirmed", "Packed", "Shipped"].includes(related.delivery_status_str))).length === 0 ? (
                          <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                            <NoData />
                            <div className='detail-action-wrapper content-flex content-flex-center'>
                              <span className='detail-sec-text'>No order details yet</span>
                            </div>
                          </div>
                        ) : (
                          allOrders.filter(order => order.related_orders?.some(related => ["Confirmed", "Packed", "Shipped"].includes(related.delivery_status_str)))
                            .slice(0, 10)
                            .map(order => (
                              <CardContent key={order.transaction_id}>
                                <div style={{ backgroundColor: "grey.300" }}>
                                  <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab2"} toggleExpand={toggleExpand} selectedOrderIds={selectedOrderIds} handleCheckboxChange={handleCheckboxChange} handleSelectAll={handleSelectAll} />
                                </div>
                              </CardContent>
                            ))
                        )}
                      </Card>
                    </Grid>

                    {/* Delivered Column */}
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", padding: "10px", borderRadius: "25px" }}>
                        <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px", marginRight: "20px" }}>
                          Delivered / Collected
                        </Grid>
                        {allOrders.filter(order =>
                          order.related_orders?.some(related => related.delivery_status_str === "Delivered")
                        ).length === 0 ? (
                          <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                            <NoData />
                            <div className='detail-action-wrapper content-flex content-flex-center'>
                              <span className='detail-sec-text'>No order details yet</span>
                            </div>
                          </div>
                        ) : (
                          allOrders.filter(order =>
                            order.related_orders?.some(related => related.delivery_status_str === "Delivered")
                          ).slice(0, 10)
                            .map(order => (
                              <CardContent key={order.transaction_id}>
                                <div style={{ backgroundColor: "grey.300" }}>
                                  <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} selectedOrderIds={selectedOrderIds} handleCheckboxChange={handleCheckboxChange} handleSelectAll={handleSelectAll} handlePaymentStatus={handlePaymentStatus} value={"Tab3"} toggleExpand={toggleExpand} />
                                </div>
                              </CardContent>
                            ))
                        )}
                      </Card>
                    </Grid>
                  </Grid>

                )}
              </div>
            </CardContent>
          </Card>
          :
          <Grid item xs={12} md={12}>
            <Card className="setup-card dashboard-card-wrapper">
              <CardContent className="stepper-wrapper">
                <CheckListItem
                  title={"Set up your website and storefront [Required]"}
                  isCollapsible={true}
                  isMandatory={true}
                  // isDisabled={checklist.webSetup}
                  description={
                    "Tell us a bit about your business so we can get you up and running. To help you get started we'll give you a free sub domain."
                  }
                  status={checklist.webSetup}
                >
                  {!loader ? (
                    <StoreDetail
                      user={user}
                      store={{
                        name: user?.storeName,
                        logo: logo,
                        logoUrl: logoUrl,
                        host: user?.hostName,
                      }}
                      loader={loader}
                      setLogo={setLogo}
                      status={checklist.webSetup}
                      setLoader={setLoader}
                      handleUpdate={handleStoreUpdate}
                    />
                  ) : (
                    ""
                  )}
                </CheckListItem>
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  redirectTo={dashboardRouteConstants.ABOUT}
                  title={"Review and Update about your business [Optional]"}
                  description={
                    "Congrats! You have successfully setup your Store and domain. Please provide more details about your business to go ahead."
                  }
                  status={true}
                  isOptional={true}
                  isDisabled={!checklist.webSetup}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  redirectTo={dashboardRouteConstants.SHIPPING}
                  isMandatory={true}
                  isDisabled={!checklist.webSetup}
                  title={"Please choose your shipping method:"}
                  description={
                    "Would you like to use Shiprazor (recommended) or arrange your own shipping?"
                  }
                  status={checklist.shiprazorSetup}
                >
                  {/* <ShipRazorSetup
                                                    refreshUser={getUser} 
                                                    setLoader={setLoader} /> */}
                </CheckListItem>
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  redirectTo={dashboardRouteConstants.DELIVERY}
                  title={"Choose how to deliver your goods"}
                  status={checklist.warehouseSetup}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  title={"Add categories and products"}
                  redirectTo={
                    !checklist.categoryExist
                      ? dashboardRouteConstants.CATEGORIES
                      : dashboardRouteConstants.PRODUCTS
                  }
                  status={checklist.categoryExist && checklist.productExist}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  title={"Set up payment options"}
                  redirectTo={dashboardRouteConstants.PAYMENTS}
                  status={checklist.paymentSetup}
                />
              </CardContent>
            </Card>
          </Grid>
        }


        <Grid item xs={12} md={6}>
          <Card className="overlay-wrapper dashboard-card-wrapper">
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">Your Dashboard</span>
              </div>
              <Divider />
              <div className="header-wrapper">
                <span className="header-text">Total Sales</span>
                <br />
                <span className="header-sec-text">
                  Overall customer purchases.
                </span>
              </div>
              <div className="content-wrapper content-flex content-flex-right">
                <span className="content-text-right figure-text">
                  {/* {salesSummary['total_sale'] ? GLOBAL_CURRENCY.symbol + salesSummary['total_sale'].toFixed(2) : GLOBAL_CURRENCY.symbol + '0.00'} */}
                  {/* {new Intl.NumberFormat({style:"currency", currency:"ZAR", minimumFractionDigits: 2, currencyDisplay: 'narrowSymbol'}).format(salesSummary['total_sale'] || 0)} */}
                  {formatCurrency.format(salesSummary["total_sale"] || 0)}
                </span>
              </div>
              <Divider />
              <div className="header-wrapper">
                <span className="header-text">Today Sales</span>
                <br />
                <span className="header-sec-text">
                  Total sale by customer purchases.
                </span>
              </div>
              <div className="content-wrapper content-flex content-flex-right">
                <span className="content-text-right figure-text">
                  {/* {salesSummary['today_sale'] ? GLOBAL_CURRENCY.symbol + salesSummary['today_sale'].toFixed(2) : GLOBAL_CURRENCY.symbol + '0.00'} */}
                  {formatCurrency.format(salesSummary["today_sale"] || 0)}
                </span>
              </div>
            </CardContent>
            {user?.isPublished ? (
              ""
            ) : (
              <div className="overlay-card">
                <NoData className="statement-svg" />
                <span className="statement">
                  This content will get available
                  <br /> after publishing website
                </span>
              </div>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className="overlay-wrapper dashboard-card-wrapper">
            {user?.isPublished ? (
              <CardContent>
                <div className="header-wrapper">
                  <span className="header-text">View your website</span>
                </div>
                <Divider />
                <div className="header-wrapper">
                  <span className="header-sec-text">
                    Click here to view your website
                  </span>
                </div>
                <div className="content-wrapper content-flex content-flex-right">
                  <Button
                    onClick={() => {
                      window
                        .open(
                          `https://${user.websiteUrl}.webbieshop.com`,
                          "_blank"
                        )
                        .focus();
                    }}
                    variant="contained"
                  >
                    View your Website
                  </Button>
                </div>
              </CardContent>
            ) : (
              <CardContent>
                <div className="header-wrapper">
                  <span className="header-text">Publish your website</span>
                </div>
                <Divider />
                <div className="header-wrapper">
                  <span className="header-sec-text">
                    Click here to publish your website
                  </span>
                </div>
                <div className="content-wrapper content-flex content-flex-right">
                  <Button onClick={handlePublish} variant="contained">
                    Publish your Website
                  </Button>
                </div>
              </CardContent>
            )}
            {!checkListComplete && !user?.isPublished ? (
              <div className="overlay-card">
                <NoData
                  className="statement-svg"
                  style={{ height: "3rem" }}
                />
                <span className="statement">
                  This content will get available
                  <br /> after publishing website
                </span>
              </div>
            ) : (
              ""
            )}
          </Card>
        </Grid>
      </Grid>
      {/* <Grid container className="component-grid">
        <Grid item xs={12}>
          <Card className="overlay-wrapper dashboard-card-wrapper">
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">Best Selling Products</span>
              </div>
              <Divider />

              {bestProduct.length > 0 ? (
                <div className="content-wrapper content-flex content-flex-center">
                  <Carousel>
                    {bestProduct.map((product) => (
                      <DetailCard product={product} navigate={navigate} />
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="content-wrapper content-flex content-flex-center">
                  <NoData />
                  <span className="content-text">No results found</span>
                </div>
              )}
            </CardContent>
            {user?.isPublished ? (
              ""
            ) : (
              <div className="overlay-card">
                <NoData className="statement-svg" />
                <span className="statement">
                  This content will get available
                  <br /> after publishing website
                </span>
              </div>
            )}
          </Card>
        </Grid>
      </Grid> */}

      {/* COMMENTING CODE */}
      {/* <Grid container className="component-grid" style={{ height: "100vh", maxWidth: "100vh", width: "80vh" }}>
        <Grid item xs={12} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Card className="overlay-wrapper dashboard-card-wrapper" style={{ flex: 1, display: "flex", flexDirection: "column", }}>
            <CardContent style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden", paddingBottom: "10px" }}>
           
              <div className="header-wrapper">
                <span className="header-text">Best Selling Products</span>
              </div>
              <Divider />
              <div>
                {bestProduct.length > 0 ? (
                  <div >
                    <Carousel style={{ width: '20vh' }}>
                      {bestProduct.map((product) => (
                        <DetailCard product={product} navigate={navigate} key={product.id} />
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <NoData />
                    <span className="content-text">No results found</span>
                  </div>
                )}
              </div>
            </CardContent>
            {!user?.isPublished && (
              <div className="overlay-card">
                <NoData className="statement-svg" />
                <span className="statement">
                  This content will get available
                  <br /> after publishing website
                </span>
              </div>
            )}
          </Card>
        </Grid>
      </Grid> */}


      <Grid
        container
        className="component-grid"
        sx={{
          height: "100vh",
          maxWidth: "100%",
          width: "100%",
          padding: { xs: "10px", md: "0px" },
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center" }}
        >
          <Card
            className="overlay-wrapper dashboard-card-wrapper"
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              maxWidth: { xs: "80vw", sm: "60vw", md: "85vw", lg: "65vw" }, // Adjust width dynamically
              margin: "auto",
            }}
          >
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                paddingBottom: "10px",
              }}
            >
              <div className="header-wrapper">
                <span className="header-text">Best Selling Products</span>
              </div>
              <Divider />
              <div>
                {bestProduct.length > 0 ? (
                  <div>
                    <Carousel sx={{ width: { xs: "100%", sm: "80%", md: "70%" } }}>
                      {bestProduct.map((product) => (
                        <DetailCard product={product} navigate={navigate} key={product.id} />
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <NoData />
                    <span className="content-text">No results found</span>
                  </div>
                )}
              </div>
            </CardContent>
            {!user?.isPublished && (
              <div className="overlay-card">
                <NoData className="statement-svg" />
                <span className="statement">
                  This content will get available
                  <br /> after publishing website
                </span>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>



    </div>
  );
}

const CheckListItem = (props) => {
  const { title, description, status, isCollapsible, isMandatory, redirectTo, isDisabled, isOptional } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(!isDisabled && !status)
  }, [isDisabled, status])

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box className="checklist-wrapper">
      <span className='title'></span>
      <ListItemButton
        disabled={isDisabled}
        onClick={() => {
          if (isCollapsible) handleClick()
          else navigate(redirectTo)
        }}>
        <ListItemIcon>
          {status ?
            <CheckIcon color="success" /> :
            isOptional ?
              <PendingIcon style={{ color: '#ffc400' }} /> :
              isMandatory ?
                <PriorityHighIcon color='error' /> :
                <AnnouncementIcon color='warning' />}
        </ListItemIcon>
        {/* <ListItemText primary={title} /> */}
        <ListItemText>
          <span className='title'>
            {title}
          </span><br />
          <span className='description'>
            {description}
          </span>
        </ListItemText>
        {isCollapsible ?
          open ? <ExpandLess /> : <ExpandMore /> :
          <IconButton
            aria-label="delete"
            className='icon-btn-directional'
            size="small">
            <ArrowForwardIosIcon />
          </IconButton>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </Box>
  )
}

const DetailCard = ({ product, navigate }) => (
  <Card className="slider-card content-card">
    <CardMedia
      sx={{ height: '17rem' }}
      image={product?.product_images[0]?.image}
      title={product?.name}
    />
    <CardContent align="left">
      {/* <Typography gutterBottom component="span">
                {product.name ? _.truncate(product.name, { length: 30 }) : ''}
            </Typography> */}
      <CardContent align="left">
        <Typography
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            minHeight: "10vh",
            fontSize: 18,
            alignItems: "baseline"

          }}
          gutterBottom component="span">
          {_.truncate(_.trim(product.name), { length: 30 })}
        </Typography>
        <Typography
          sx={{
            display: {
              xs: "flex", sm: "flex", md: "none",
              fontSize: 18,
            },
          }}
          gutterBottom component="span">
          {_.truncate(_.trim(product.name), { length: 40 })}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatCurrency.format(product?.price || 0)}
        </Typography>
      </CardContent>
    </CardContent>
    <CardActions align="center">
      <Button
        color="success"
        size="small"
        onClick={() => {
          navigate(dashboardRouteConstants.PRODUCTS, { state: { productId: product.id } })
        }}
        variant="outlined">View</Button>
    </CardActions>
  </Card>
)

const StoreDetail = ({ setLoader, store, setLogo, handleUpdate, status, loader }) => {
  const { formState: { errors }, handleSubmit, control, getValues } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const updateStoreDetails = async (formData) => {
    const { logo, logoUrl } = store;
    // if (!logo.length) return;

    setLoader(true);

    let res = await axiosInstance.post(APIRouteConstants.WEBSITE.ADD_WEB_DETAILS, formData)
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data && data.success) {
      console.log("response12", data.message)
      enqueueSnackbar(data.message, { variant: 'success' });
      handleUpdate()
    }

    if (response
      && response.status === 400
      && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
        // setLoader(false);
        // return;
      }
    }

    let logoFormData = new FormData();
    if (logo[0] === undefined) {
      setLoader(false);
      return
    }
    if (logo[0] != logoUrl) {
      logoFormData.append('logo', logo[0], logo[0]?.fileName)

      const { logoData, logoResponse } = await axiosInstance({
        method: 'post',
        url: APIRouteConstants.WEBSITE.WEB_LOGO_BANNER,
        data: logoFormData
      })

      if (logoResponse
        && logoResponse.status === 400
        && logoResponse.data) {
        for (let i in logoResponse.data) {
          enqueueSnackbar(_.capitalize(i) + ': ' + logoResponse.data[i], { variant: 'error' });
          // setLoader(false);
          // return;
        }
      }

      if (!_.isEmpty(logoData)) {
        enqueueSnackbar('Details updated successfully', { variant: 'success' });
      }
    }
    handleUpdate()
  }

  console.log("response", store)
  return (
    <form
      className="step-form-container"
      onSubmit={handleSubmit(updateStoreDetails)}
    >
      <Grid
        container
        className="form-grid-container dashboard-form-container"
        spacing={4}
        wrap="wrap"
      >
        <Grid item md={12} sm={12}>
          <div className="form-wrapper">
            <FormLabel className="form-label">
              <span className="label-text">
                Store name
                <span className="required">*</span>
              </span>
              <br />
              <span className="label-sec-text">
                This is the name we will display for your online store
              </span>
            </FormLabel>
            <FormControl fullWidth className="select-wrapper">
              <Controller
                control={control}
                name="store_name"
                rules={{
                  required: true,
                  maxLength: {
                    value: 16,
                    message: "Store name must be 16 characters or fewer to maintain proper website display.",
                  },
                }}
                defaultValue={store.name}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="Store Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    value={value}
                    disabled={status}
                    InputLabelProps={{ shrink: false }}
                    error={!!errors.store_name}
                    helperText={errors.store_name?.message}
                  />
                )}
              />
              <span className="error-text">
                {errors.store_name?.type === "required" &&
                  "Store name is required"}
              </span>
            </FormControl>
          </div>
          <div className="form-wrapper">
            <FormLabel className="form-label">
              <span className="label-text">
                Store website address (URL)
                <span className="required">*</span>
              </span>
              <br />
              <span className="label-sec-text">
                You can change your free domain name later.
              </span>
            </FormLabel>
            <FormControl fullWidth className="select-wrapper">
              <Controller
                control={control}
                name="host_name"
                rules={{ required: true }}
                defaultValue={store.host}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="Host Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    disabled={status}
                    value={value}
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          .webbieshop.com
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <span className="error-text">
                {errors.host_name?.type === "required" &&
                  "Host name is required"}
              </span>
            </FormControl>
          </div>
        </Grid>
        <Grid item md={12} sm={12}>
          <div className="form-wrapper">
            <FormLabel className="form-label">
              <span className="label-text">
                Upload your logo (Optional)
                {/* <span className='required'>*</span> */}
              </span>
              <br />
              <span className="label-sec-text">
                This is the logo we will display for your online store.
              </span>
              <br />
              <span className="label-sec-text">
                Recommended size: 1024 x 1024 pixels
              </span>
            </FormLabel>
            <FormControl fullWidth className="select-wrapper">

              <ImageUploader
                imageList={store.logo}
                message={"Recommended size: 1024 x 1024 pixels"}
                setImageList={setLogo}
                id="logo"
                name="logo"
                isDisabled={status}
              />

              {/* <span className="error-text">
                                {!store.logo.length && (
                                    'Logo is required'
                                )}
                            </span> */}
            </FormControl>
          </div>
        </Grid>
        {!status ? (
          <Grid item md={12} sm={12}>
            <Button type="submit" variant="contained">
              Save and Continue {">"}
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </form>
  );
}


const OrderCard = ({ order, handleStatusUpdate, handlePaymentStatus, value, selectedOrderIds, handleCheckboxChange, handleSelectAll }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [payBy, setPayBy] = useState(order.pay_by || "");
  const [orderNotes, setOrderNotes] = useState(order.order_notes || "");
  const [isEditable, setIsEditable] = useState(!order.pay_by && !order.order_notes);
  const [open, setOpen] = useState(false);
  const [isExpandedDialog, setIsExpandedDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // event.stopPropagation();
    setOpen(false);
  };

  const handleButtonClick = () => {
    if (isEditable) {
      // Save the data and make fields uneditable
      handlePaymentStatus(order.transaction_id, payBy, orderNotes);
    }
    // Toggle edit mode
    setIsEditable(!isEditable);
  };

  // const handleUpdateStatus = () => {
  //   // if (isEditable) {
  //     // Save the data and make fields uneditable
  //     handleCheckboxChange(order.id);
  //   // }
  //   // Toggle edit mode
  //   // setIsEditable(!isEditable);
  // };

  const toggleExpand = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  const [expandedOrderIds, setExpandedOrderIds] = useState([]);

  const toggleExpandDialog = (event, orderId) => {
    event.stopPropagation();
    setExpandedOrderIds((prev) =>
      prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId]
    );
  };


  function capitalizeFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function timeAgo(timestamp) {
    if (!timestamp) return "No date available";

    const now = moment();
    const then = moment(timestamp);
    const diff = now.diff(then);

    if (diff < 60000) {
      return "just now";
    } else if (diff < 3600000) {
      const minutes = Math.round(diff / 60000);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diff < 86400000) {
      const hours = Math.round(diff / 3600000);
      return `${hours} hr${hours > 1 ? 's' : ''} ago`;
    } else if (diff < 2592000000) {
      const days = Math.round(diff / 86400000);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (diff < 31536000000) {
      const months = Math.round(diff / 2592000000);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      return moment(timestamp).format("ddd, D MMM YYYY");
    }
  }

  const selectStyles = {
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    "& .MuiSvgIcon-root": { color: "white" },
    "& .MuiSelect-select": { color: "white" },
  };

  const menuItemStyles = {
    fontSize: "16px",
    color: "white",
    backgroundColor: "black",
    "&:hover": { backgroundColor: "#333" },
  };

  return (
    //     <div 
    //   // onClick={() => handleCardClick(order)} 
    //   style={{ cursor: "pointer" }}
    // >
    <>
      <Grid
        style={{ cursor: "pointer" }}
        onClick={handleClickOpen}
      >
        <div style={{ paddingBottom: "15px" }}>
          <Card style={{
            paddingTop: "10px", paddingBottom: "10px",
            // borderRadius:"25px" 
          }}>
            {/* <Grid variant="h6" style={{ fontWeight: "bold", fontSize: "17px", marginRight: "20px", marginLeft: "20px"}}>
                {order.customer_name}
              </Grid> */}
            {/* ... (Card Content remains the same) ... */}
            <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", marginBottom: "5px", marginRight: "5px" }}>
              <Grid variant="h6" style={{ fontSize: "0.8rem", }}>
                • {capitalizeFirstLetter(order.user_name)}
              </Grid>
              <Grid style={{ fontSize: "0.7rem", color: "gray" }}>
                {timeAgo(order.updated_at)}
              </Grid>
            </CardContent>
            <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              {/* <img
              src={order.product_image} 
              style={{
                width: '20%',
                // borderRadius: '40%', // Make it a circle
                objectFit: 'cover', // Prevent image distortion
                border: '2px solid white',  // Optional: Add a white border
              }}
            /> */}

              <div >
                {/* {order.customer_name && (
                  <span style={{ marginBottom: "5px", fontSize: "0.8rem", }}>Customer Name : <span>{order.customer_name}</span></span>
                )} */}
                {/* <Grid variant="h6" style={{ fontSize: "0.8rem", marginRight: "10px" }}>
                • {capitalizeFirstLetter(order.customer_name)}
              </Grid> */}
                {order.transaction_id && (
                  <Grid
                    variant="h6"
                    style={{
                      fontSize: "0.8rem",
                      marginRight: "20px",
                      // whiteSpace: "nowrap",
                      // overflow: "hidden",
                      // textOverflow: order.order_instruction?.length > 7 ? "ellipsis" : "unset",
                      // maxWidth: order.order_instruction?.length > 7 ? "100px" : "unset", // Adjust width dynamically
                    }}
                  >
                    #{order.transaction_id.slice(7)}
                    <span style={{ color: "grey", fontSize: "0.7rem" }}>{order.order_instruction
                      ? ` (${order.order_instruction.length > 7 ? order.order_instruction.substring(0, 7) + "..." : order.order_instruction})`
                      : ""}</span>
                  </Grid>
                )}
                {/* {order.quantity && (
                <span style={{ marginBottom: "5px", fontSize: "0.8rem", color: "gray" }}>Qty : <span style={{ color: "black" }}>{order.quantity}</span></span>
              )} */}
                {/* <Grid variant="h6" style={{ fontWeight: "bold", fontSize: "17px", marginRight: "20px" }}>
                #{order.order_id}
              </Grid> */}
              </div>
              <div style={{ textAlign: "right", }}>
                {/* <Grid style={{ fontSize: "0.7rem", }}>
                {moment(order.created).format(" ddd[,] D MMM YYYY")}
              </Grid> */}
                {/* <Grid style={{ fontSize: "0.8rem", color: "gray" }}>
                {timeAgo(order.created)}
              </Grid> */}
                <Grid variant="body2" color="textSecondary" marginTop="5px">{order.time}</Grid>
                {/* <Button
              style={{
                backgroundColor: "grey",
                color: "white",
                marginTop: "5px",
                fontSize: "13px",
                width: "140px"
              }}
            >
              {order.delivery_status_type}
            </Button> */}
                {/* // # previous */}
                {/* {value === "Tab1" && (
                  <Grid item xs={12} sm={6}> 
                    <FormControl size="small" fullWidth sx={{
                      width: { xs: '28vw', sm: '9vw', md: '9vw' }, // Responsive width
                      // minWidth: '150px', // Minimum width
                      backgroundColor: "black",
                      color: "white"
                    }}>
                      <Select
                        labelId="order-status-label"
                        id="order-status"
                        value={order.delivery_status}
                        onChange={(e) => handleStatusUpdate(e, order)}
                        displayEmpty
                        sx={{
                          fontSize: "0.8rem",
                          color: "white",
                          "& .MuiSvgIcon-root": { color: "white" },
                          "& .MuiSelect-select": { color: "white" },
                        }}
                        MenuProps={{
                          sx: {
                            "& .MuiMenuItem-root": {
                              fontSize: "0.8rem",
                              color: "white",
                              backgroundColor: "black",
                              "&:hover": { backgroundColor: "#333" },
                            },
                            "& .MuiList-root": {
                              backgroundColor: "black",
                            },
                          },
                        }}
                      >
                        <MenuItem value={1} disabled style={{ fontSize: "15px" }}>
                          Order Placed
                        </MenuItem>
                        <MenuItem value={2}>✅Confirmed </MenuItem>
                        <MenuItem value={3}>📦 Packed</MenuItem>
                        <MenuItem value={4}>🚚 Shipped</MenuItem>
                        <MenuItem value={5}>🎉 Delivered</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> 
                )} */}
                {/* <Grid item xs={12} sm={6} style={{
                  fontSize: "0.8rem",
                }}>
                  {order.payment_status}
                </Grid> */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "0.65rem", // Slightly smaller font
                    textTransform: "none",
                    borderRadius: "6px", // Slightly reduced for a compact look
                    padding: "5px 12px", // Smaller padding
                    minWidth: "auto", // Keeps button compact
                    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#333", // Slightly lighter black on hover
                      boxShadow: "0px 5px 8px rgba(0, 0, 0, 0.15)",
                    },
                  }}
                >
                  Update Status
                </Button>
              </div>
            </CardContent>
            {/* {order.quantity && (
            <span style={{ marginBottom: "5px", fontSize: "0.8rem", marginLeft: "20px" }}>Quantity : <span>{order.quantity}</span></span>
          )} */}
            <CardContent style={{
              // display: "flex", justifyContent: "space-between",
              alignItems: "center", width: "100%", marginBottom: "5px", marginRight: "5px", marginTop: "5px",
            }}>
              {/* {order.quantity && (
                <span style={{ fontSize: "0.7rem", color: "gray" }}>Qty : <span style={{ color: "black" }}>{order?.related_orders?.filter(
                  (relatedOrder) => relatedOrder.delivery_status_str === "Order Placed"
                ).length}</span></span>
              )} */}


              <div style={{ justifyContent: "space-between", display: "flex" }}>
                {order.payment_status && (
                  <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                    Payment Status : <span style={{ color: "black" }}>{capitalizeFirstLetter(order.payment_status)}</span>
                    {/* ` (${order.order_instruction.length > 7 ? order.order_instruction.substring(0, 7) + "..." : order.order_instruction})` */}
                  </span>
                )}
                <span
                  style={{
                    color: "Gray",
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "0.7rem"
                  }}
                  onClick={toggleExpand}
                >
                  {isExpanded ? "View Less" : "View More"}
                </span>
              </div>
              {/* <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  fontSize: "0.65rem", // Slightly smaller font
                  textTransform: "none",
                  borderRadius: "6px", // Slightly reduced for a compact look
                  padding: "5px 12px", // Smaller padding
                  minWidth: "auto", // Keeps button compact
                  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#333", // Slightly lighter black on hover
                    boxShadow: "0px 5px 8px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                Related Orders
              </Button> */}
            </CardContent>
            {isExpanded && (
              <Grid item xs={12} style={{ marginTop: "10px", marginRight: "20px", color: "black" }}>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "15px" }}>
                  {order.transaction_id && (
                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                      Transaction Id : <span style={{ color: "black" }}>{order.transaction_id.slice(7)}</span>
                    </span>
                  )}
                  {order.order_instruction && (
                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                      Order Notes : <span style={{ color: "black" }}>{capitalizeFirstLetter(order.order_instruction)}</span>
                    </span>
                  )}
                  {/* {order.payment_status && (
                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                      Payment Status : <span style={{ color: "black" }}>{capitalizeFirstLetter(order.payment_status)}</span>
                     </span>
                  )} */}
                  {order.total_price && (
                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                      Total Price : <span style={{ color: "black" }}>{order.total_price}</span>
                    </span>
                  )}
                  {order.final_price && (
                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                      Final Price : <span style={{ color: "black" }}>{order.final_price}</span>
                    </span>
                  )}
                  {order.payment_mode_name && (
                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                      Mode Of Payment : <span style={{ color: "black" }}>{order.payment_mode_name}</span>
                    </span>
                  )}
                  {/* {order.payment_mode.payment_method_name === "Pay at Store" && (
                  <>
                    <FormControl
                      onChange={(e) => handlePaymentStatus(e, order)}
                    >
                      <select style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem" }}>
                        <option value="" style={{ backgroundColor: "black", color: "white" }}>Select Payment Option</option>
                        <option value="cash" style={{ backgroundColor: "black", color: "white" }}>Cash</option>
                        <option value="card" style={{ backgroundColor: "black", color: "white" }}>Card</option>
                        <option value="online" style={{ backgroundColor: "black", color: "white" }}>Online</option>
                      </select>
                    </FormControl>
                    <input
                      type="text"
                      placeholder="Other Instructions(Optional)"
                      style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                    />
                    <button
                      style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                    >
                      Submit
                    </button>
                  </>
                )} */}

                  {order.payment_mode_name === "Pay at Store" && value === "Tab3" && (
                    <>
                      <FormControl>
                        <select
                          onClick={(e) => e.stopPropagation()} // Prevent card click
                          // value={payBy || order.pay_by} // Default value from order
                          // onChange={(e) => setPayBy(e.target.value)} // Update state correctly
                          value={payBy}
                          onChange={(e) => setPayBy(e.target.value)}
                          disabled={!isEditable}
                          style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", borderRadius: "4px" }}>
                          <option value="">Select Payment Option</option>
                          <option value="cash">Cash</option>
                          <option value="card">Card</option>
                          <option value="online">Online</option>
                          <option value="Snap Scan">Snap Scan</option>
                        </select>
                      </FormControl>
                      <input
                        type="text"
                        placeholder="Other Instructions(Optional)"
                        // value={orderNotes || order.order_notes} // Default value from order
                        // onChange={(e) => setOrderNotes(e.target.value)} // Update state correctly
                        value={orderNotes}
                        onChange={(e) => setOrderNotes(e.target.value)}
                        disabled={!isEditable}
                        style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                        onClick={(e) => e.stopPropagation()} // Prevent card click
                      />
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          handleButtonClick();
                        }}
                        style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                      >
                        {isEditable ? "Save" : "Edit"}
                      </button>
                    </>
                  )}

                  {order.created_at && (
                    <Grid style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                      <span>
                        Date : <span style={{ color: "black" }}>{moment(order.created_at).format(" ddd[,] D MMM YYYY, h:mm A")}</span>
                      </span>
                    </Grid>
                  )}
                </div>
              </Grid>
            )}
            <div style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "1px",
              backgroundColor: "gray.300",
            }} />
          </Card>
        </div>
      </Grid>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        {/* Dialog Header with Close Icon */}
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", bgcolor: "#f5f5f5" }}>
          <strong>Related Orders</strong>
          <IconButton onClick={handleClose} sx={{ color: "black" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 2 }}>
          {order.related_orders && order.related_orders.length > 0 ? (
            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2, p: 2, m: 2 }}>
              <CardContent>
                <Grid container spacing={2}>
                  {/* <Grid container spacing={1} style={{ marginTop: "30px", marginLeft: "20px" }}> */}
                  <Grid item xs={12} container alignItems="center" spacing={1} wrap="nowrap">
                    {/* START OF CHANGED SECTION */}
                    {
                      (value === "Tab1" || value === "Tab2") && (
                        <Grid item xs={12} container alignItems="center">
                          {/* Hide Select All on xs screens */}
                          <Grid item xs={2} >
                            <FormControlLabel
                              style={{ marginLeft: "4px" }}
                              control={
                                <Checkbox
                                  checked={
                                    order.related_orders.length > 0 &&
                                    order.related_orders.every(ro =>
                                      Array.isArray(selectedOrderIds) && selectedOrderIds.includes(ro.id)
                                    )
                                  }
                                  onChange={(e) => handleSelectAll(e, order.related_orders)}
                                />
                              }
                              label={
                                <span
                                  style={{
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    // visibility: "hidden",
                                    display: { xs: "none", sm: "flex" }
                                  }}
                                >
                                  <Grid sx={{ display: { xs: "none", sm: "block" } }}>
                                    <strong>Select</strong>
                                    <strong style={{ marginLeft: "5px" }}>All</strong>
                                  </Grid>
                                </span>
                              }
                            />
                          </Grid>
                          <Grid item xs={10} container justifyContent="flex-end" alignItems="center" spacing={1}>
                            <Grid item>
                              {value === "Tab1" && (
                                <FormControl
                                  size="small"
                                  sx={{ width: { xs: "34vw", sm: "25vw", md: "18vw", lg: "13vw" }, backgroundColor: "black", color: "white", borderRadius: "8px" }}
                                >
                                  <Select
                                    labelId="order-status-label"
                                    id="order-status"
                                    value={0}
                                    onChange={(e) => handleStatusUpdate(e, order.related_orders.map((o) => o.id))}
                                    displayEmpty
                                    sx={{ fontSize: "0.8rem", color: "white", "& .MuiSvgIcon-root": { color: "white" }, "& .MuiSelect-select": { color: "white", textAlign: "center" } }}
                                    MenuProps={{
                                      sx: {
                                        "& .MuiMenuItem-root": { fontSize: "0.8rem", color: "white", backgroundColor: "black", "&:hover": { backgroundColor: "#333" } },
                                        "& .MuiList-root": { backgroundColor: "black" }
                                      }
                                    }}
                                  >
                                    <MenuItem value={0} disabled>Update Status</MenuItem>
                                    <MenuItem value={1} disabled>Order Placed</MenuItem>
                                    <MenuItem value={2}>✅ Confirmed</MenuItem>
                                    <MenuItem value={3}>📦 Packed</MenuItem>
                                    <MenuItem value={4}>🚚 Shipped</MenuItem>
                                    <MenuItem value={5}>🎉 Delivered</MenuItem>
                                    <MenuItem value={10}>❌ Cancelled</MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                              {value === "Tab2" && (
                                <FormControl
                                  size="small"
                                  sx={{ width: { xs: "30vw", sm: "20vw", md: "15vw", lg: "13vw" }, backgroundColor: "black", color: "white", borderRadius: "8px" }}
                                >
                                  <Select
                                    labelId="order-status-label"
                                    id="order-status"
                                    value={0}
                                    onChange={(e) => handleStatusUpdate(e, order.related_orders.map((o) => o.id))}
                                    displayEmpty
                                    sx={{ fontSize: "0.8rem", color: "white", "& .MuiSvgIcon-root": { color: "white" }, "& .MuiSelect-select": { color: "white", textAlign: "center" } }}
                                    MenuProps={{
                                      sx: {
                                        "& .MuiMenuItem-root": { fontSize: "0.8rem", color: "white", backgroundColor: "black", "&:hover": { backgroundColor: "#333" } },
                                        "& .MuiList-root": { backgroundColor: "black" }
                                      }
                                    }}
                                  >
                                    <MenuItem value={0} disabled>Update Status</MenuItem>
                                    <MenuItem value={2} disabled>✅ Confirmed</MenuItem>
                                    <MenuItem value={3}>📦 Packed</MenuItem>
                                    <MenuItem value={4}>🚚 Shipped</MenuItem>
                                    <MenuItem value={5}>🎉 Delivered</MenuItem>
                                    <MenuItem value={10}>❌ Cancelled</MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {/* END OF CHANGED SECTION */}
                  </Grid>

                  {order.related_orders.map((relatedOrder, index) => (
                    <Grid item xs={12} key={relatedOrder.id}>
                      {value === "Tab1" && relatedOrder?.delivery_status_str === "Order Placed" && <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2, p: 1.5 }}>
                        <CardContent>
                          {/* <Grid container spacing={1}>
                        <Grid item xs={6} sm={4}><strong>Order ID:</strong></Grid>
                        <Grid item xs={6} sm={8}>{relatedOrder.order_id}</Grid>

                        <Grid item xs={6} sm={4}><strong>Product:</strong></Grid>
                        <Grid item xs={6} sm={8}>{relatedOrder.product_name}</Grid>

                        <Grid item xs={6} sm={4}><strong>Quantity:</strong></Grid>
                        <Grid item xs={6} sm={8}>{relatedOrder.quantity}</Grid>

                        <Grid item xs={6} sm={4}><strong>Unit Price:</strong></Grid>
                        <Grid item xs={6} sm={8}>${relatedOrder.unit_price}</Grid>

                        <Grid item xs={6} sm={4}><strong>Total Price:</strong></Grid>
                        <Grid item xs={6} sm={8}>${relatedOrder.total_price}</Grid>
                      </Grid>

                      <Divider sx={{ my: 1 }} /> */}



                          <Grid item xs={12} key={index}>
                            <Grid container spacing={1} alignItems="center">
                              {/* Checkbox */}
                              <Grid container spacing={1} alignItems="center" wrap="nowrap">

                                {/* <Grid item xs>
                                  <strong> {relatedOrder.product_name}</strong>
                                </Grid> */}
                                <Grid container spacing={1} alignItems="center" justifyContent="flex-start" wrap="wrap">
                                  {/* Checkbox */}
                                  <Grid item xs="auto">
                                    <Checkbox
                                      checked={Array.isArray(selectedOrderIds) && selectedOrderIds.includes(relatedOrder.id)}
                                      onChange={() => handleCheckboxChange(relatedOrder.id)}
                                      sx={{
                                        padding: 0,
                                        '&:hover': { backgroundColor: 'transparent' },
                                      }}
                                    />
                                  </Grid>

                                  {/* Product Name */}
                                  <Grid
                                    item
                                    xs
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexGrow: 1,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      fontSize: "17px",
                                    }}
                                  >
                                    <strong>{relatedOrder.product_name}</strong>
                                  </Grid>

                                  {/* Delivery Status - Moves to Next Line on Small Screens */}
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      color: "#757575",
                                      pointerEvents: 'none',
                                      marginLeft: { xs: 0, md: '8px' }, // No margin-left for small screens to align properly
                                      textAlign: { xs: 'right', sm: "right", md: 'right', lg: "right" }, // Align left for small screens, right for larger screens
                                      fontSize: "17px",
                                      flexBasis: { xs: '100%', md: 'auto' }, // Forces a new line only on small screens
                                    }}
                                  >
                                    {relatedOrder.delivery_status_str}
                                  </Grid>
                                </Grid>



                              </Grid>
                              {/* Add-ons (if any) */}
                              {relatedOrder.order_instruction && (
                                // (order.order_instruction) &&
                                <Grid item xs={12} sx={{ fontSize: '0.875rem', color: "#757575", pointerEvents: 'none', fontSize: '16px' }}>
                                  Notes: {relatedOrder.order_instruction}
                                  {/* {order.order_instruction} */}
                                </Grid>
                              )}

                              {/* Quantity, Unit Price, Total Price - No Space between Quantity and Unit Price */}
                              <Grid container item xs={12} spacing={1} alignItems="center" justifyContent="space-between">
                                {/* Quantity and Unit Price */}
                                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                  <Box sx={{ background: "#E8F5E9", borderRadius: 1, padding: "4px 8px", display: "inline-block", fontSize: '17px' }}>
                                    <span>qty: <strong>{relatedOrder.quantity}</strong></span>
                                  </Box>
                                  <span style={{ marginLeft: "4px", fontSize: '17px' }}>x {relatedOrder.unit_price}</span>
                                </Grid>

                                {/* Total Price aligned to the extreme right */}
                                <Grid item>
                                  <strong style={{ fontSize: '17px' }}>R{relatedOrder.total_price}</strong>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Divider between items */}
                            {<Divider sx={{ my: 2 }} />}
                            <div style={{ textAlign: "center", }}>
                              <span
                                style={{
                                  color: "gray",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  fontSize: "16px"
                                }}
                                onClick={(e) => toggleExpandDialog(e, relatedOrder.id)}
                              >
                                {expandedOrderIds.includes(relatedOrder.id) ? "View Less" : "View More"}
                              </span>
                            </div>

                            {expandedOrderIds.includes(relatedOrder.id) && (
                              <Grid item xs={12} style={{ marginTop: "10px", marginRight: "20px", color: "black" }}>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "15px" }}>
                                  {order.transaction_id && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Order Id : <span style={{ color: "black" }}>{relatedOrder.order_id}</span>
                                    </span>
                                  )}
                                  {relatedOrder.order_instruction && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Order Notes : <span style={{ color: "black" }}>{capitalizeFirstLetter(relatedOrder.order_instruction)}</span>
                                    </span>
                                  )}
                                  {relatedOrder.quantity && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Quantity : <span style={{ color: "black" }}>{relatedOrder.quantity}</span>
                                    </span>
                                  )}
                                  {relatedOrder.unit_price && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Unit Price : <span style={{ color: "black" }}>{relatedOrder.unit_price}</span>
                                    </span>
                                  )}
                                  {order.total_price && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Total Price : <span style={{ color: "black" }}>{relatedOrder.total_price}</span>
                                    </span>
                                  )}
                                  {(relatedOrder.discount_amount != 0) && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Discount : <span style={{ color: "black" }}>{relatedOrder.discount_amount}</span>
                                    </span>
                                  )}
                                  {(relatedOrder.discount_amount != 0) && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Final Price : <span style={{ color: "black" }}>{relatedOrder.final_price}</span>
                                    </span>
                                  )}
                                  {/* {order.payment_status && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Payment Status : <span style={{ color: "black" }}>{capitalizeFirstLetter(order.payment_status)}</span>
                                    </span>
                                  )} */}
                                  {/* {order.payment_mode.payment_method_name === "Pay at Store" && (
                  <>
                    <FormControl
                      onChange={(e) => handlePaymentStatus(e, order)}
                    >
                      <select style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem" }}>
                        <option value="" style={{ backgroundColor: "black", color: "white" }}>Select Payment Option</option>
                        <option value="cash" style={{ backgroundColor: "black", color: "white" }}>Cash</option>
                        <option value="card" style={{ backgroundColor: "black", color: "white" }}>Card</option>
                        <option value="online" style={{ backgroundColor: "black", color: "white" }}>Online</option>
                      </select>
                    </FormControl>
                    <input
                      type="text"
                      placeholder="Other Instructions(Optional)"
                      style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                    />
                    <button
                      style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                    >
                      Submit
                    </button>
                  </>
                )} */}

                                  {order.payment_mode_name === "Pay at Store" && value === "Tab3" && (
                                    <>
                                      <FormControl>
                                        <select
                                          onClick={(e) => e.stopPropagation()} // Prevent card click
                                          // value={payBy || order.pay_by} // Default value from order
                                          // onChange={(e) => setPayBy(e.target.value)} // Update state correctly
                                          value={payBy}
                                          onChange={(e) => setPayBy(e.target.value)}
                                          disabled={!isEditable}
                                          style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", borderRadius: "4px" }}>
                                          <option value="">Select Payment Option</option>
                                          <option value="cash">Cash</option>
                                          <option value="card">Card</option>
                                          <option value="online">Online</option>
                                          <option value="Snap Scan">Snap Scan</option>
                                        </select>
                                      </FormControl>
                                      <input
                                        type="text"
                                        placeholder="Other Instructions(Optional)"
                                        // value={orderNotes || order.order_notes} // Default value from order
                                        // onChange={(e) => setOrderNotes(e.target.value)} // Update state correctly
                                        value={orderNotes}
                                        onChange={(e) => setOrderNotes(e.target.value)}
                                        disabled={!isEditable}
                                        style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                                        onClick={(e) => e.stopPropagation()} // Prevent card click
                                      />
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent card click
                                          handleButtonClick();
                                        }}
                                        style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                                      >
                                        {isEditable ? "Save" : "Edit"}
                                      </button>
                                    </>
                                  )}

                                  {order.created_at && (
                                    <Grid style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      <span>
                                        Date : <span style={{ color: "black" }}>{moment(order.created_at).format(" ddd[,] D MMM YYYY, h:mm A")}</span>
                                      </span>
                                    </Grid>
                                  )}
                                </div>
                              </Grid>
                            )}
                          </Grid>

                          {/* <Grid container spacing={1}>
                        <Grid item xs={6}><strong>Delivery Status:</strong></Grid>
                        {value === "Tab1" && (
                          <Grid item xs={12} sm={6}>
                            <FormControl size="small" fullWidth sx={{
                              width: { xs: '28vw', sm: '9vw', md: '9vw' }, // Responsive width
                              // minWidth: '150px', // Minimum width
                              backgroundColor: "black",
                              color: "white"
                            }}>
                              <Select
                                labelId="order-status-label"
                                id="order-status"
                                value={relatedOrder.delivery_status}
                                onChange={(e) => handleStatusUpdate(e, relatedOrder)}
                                displayEmpty
                                sx={{
                                  fontSize: "0.8rem",
                                  color: "white",
                                  "& .MuiSvgIcon-root": { color: "white" },
                                  "& .MuiSelect-select": { color: "white" },
                                }}
                                MenuProps={{
                                  sx: {
                                    "& .MuiMenuItem-root": {
                                      fontSize: "0.8rem",
                                      color: "white",
                                      backgroundColor: "black",
                                      "&:hover": { backgroundColor: "#333" },
                                    },
                                    "& .MuiList-root": {
                                      backgroundColor: "black",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value={1} disabled style={{ fontSize: "15px" }}>
                                  Order Placed
                                </MenuItem>
                                <MenuItem value={2}>✅Confirmed </MenuItem>
                                <MenuItem value={3}>📦 Packed</MenuItem>
                                <MenuItem value={4}>🚚 Shipped</MenuItem>
                                <MenuItem value={5}>🎉 Delivered</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        )}

                      </Grid> */}
                        </CardContent>
                      </Card>}
                      {value === "Tab2" && ["Confirmed", "Packed", "Shipped"].includes(relatedOrder?.delivery_status_str) && <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2, p: 1.5 }}>
                        {/* <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={4}><strong>Order ID:</strong></Grid>
                            <Grid item xs={6} sm={8}>{relatedOrder.order_id}</Grid>

                            <Grid item xs={6} sm={4}><strong>Product:</strong></Grid>
                            <Grid item xs={6} sm={8}>{relatedOrder.product_name}</Grid>

                            <Grid item xs={6} sm={4}><strong>Quantity:</strong></Grid>
                            <Grid item xs={6} sm={8}>{relatedOrder.quantity}</Grid>

                            <Grid item xs={6} sm={4}><strong>Unit Price:</strong></Grid>
                            <Grid item xs={6} sm={8}>${relatedOrder.unit_price}</Grid>

                            <Grid item xs={6} sm={4}><strong>Total Price:</strong></Grid>
                            <Grid item xs={6} sm={8}>${relatedOrder.total_price}</Grid>
                          </Grid>

                          <Divider sx={{ my: 1 }} />

                          <Grid container spacing={1}>
                            <Grid item xs={6}><strong>Delivery Status:</strong></Grid>

                            <Grid item xs={12} sm={6}>
                              <FormControl size="small" fullWidth sx={{
                                width: { xs: '28vw', sm: '9vw', md: '9vw' }, // Responsive width
                                // minWidth: '150px', // Minimum width
                                backgroundColor: "black",
                                color: "white"
                              }}>
                                <Select
                                  labelId="order-status-label"
                                  id="order-status"
                                  value={relatedOrder.delivery_status}
                                  onChange={(e) => handleStatusUpdate(e, relatedOrder)}
                                  displayEmpty
                                  sx={{
                                    fontSize: "0.8rem",
                                    color: "white",
                                    "& .MuiSvgIcon-root": { color: "white" },
                                    "& .MuiSelect-select": { color: "white" },
                                  }}
                                  MenuProps={{
                                    sx: {
                                      "& .MuiMenuItem-root": {
                                        fontSize: "0.8rem",
                                        color: "white",
                                        backgroundColor: "black",
                                        "&:hover": { backgroundColor: "#333" },
                                      },
                                      "& .MuiList-root": {
                                        backgroundColor: "black",
                                      },
                                    },
                                  }}
                                >

                                  <MenuItem value={2} disabled>✅ Confirmed</MenuItem>
                                  {(relatedOrder.delivery_status_str === "Packed" || relatedOrder.delivery_status_str === "Shipped") ? <MenuItem value={3} disabled>📦 Packed</MenuItem> : <MenuItem value={3}>📦 Packed</MenuItem>}
                                  {relatedOrder.delivery_status_str === "Shipped" ? <MenuItem value={4} disabled>🚚 Shipped</MenuItem> : <MenuItem value={4}>🚚 Shipped</MenuItem>}
                                  <MenuItem value={5}>🎉 Delivered</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>


                          </Grid>
                        </CardContent> */}

                        <CardContent>
                          <Grid item xs={12} key={index}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid container spacing={1} alignItems="center" wrap="nowrap">

                                {/* <Grid item xs>
  <strong> {relatedOrder.product_name}</strong>
</Grid> */}
                                <Grid container spacing={1} alignItems="center" justifyContent="flex-start" wrap="wrap">
                                  {/* Checkbox */}
                                  <Grid item xs="auto">
                                    <Checkbox
                                      checked={Array.isArray(selectedOrderIds) && selectedOrderIds.includes(relatedOrder.id)}
                                      onChange={() => handleCheckboxChange(relatedOrder.id)}
                                      sx={{
                                        padding: 0,
                                        '&:hover': { backgroundColor: 'transparent' },
                                      }}
                                    />
                                  </Grid>

                                  {/* Product Name */}
                                  <Grid
                                    item
                                    xs
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexGrow: 1,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      fontSize: "17px",
                                    }}
                                  >
                                    <strong>{relatedOrder.product_name}</strong>
                                  </Grid>

                                  {/* Delivery Status - Moves to Next Line on Small Screens */}
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      color: "#757575",
                                      pointerEvents: 'none',
                                      marginLeft: { xs: 0, md: '8px' }, // No margin-left for small screens to align properly
                                      textAlign: { xs: 'right', sm: "right", md: 'right', lg: "right" }, // Align left for small screens, right for larger screens
                                      fontSize: "17px",
                                      flexBasis: { xs: '100%', md: 'auto' }, // Forces a new line only on small screens
                                    }}
                                  >
                                    {relatedOrder.delivery_status_str}
                                  </Grid>
                                </Grid>



                              </Grid>
                              {/* Add-ons (if any) */}
                              {relatedOrder.order_instruction && (
                                // (order.order_instruction) &&
                                <Grid item xs={12} sx={{ fontSize: '0.875rem', color: "#757575", pointerEvents: 'none', fontSize: '16px' }}>
                                  Notes: {relatedOrder.order_instruction}
                                  {/* {order.order_instruction} */}
                                </Grid>
                              )}

                              {/* Quantity, Unit Price, Total Price - No Space between Quantity and Unit Price */}
                              <Grid container item xs={12} spacing={1} alignItems="center" justifyContent="space-between">
                                {/* Quantity and Unit Price */}
                                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                  <Box sx={{ background: "#E8F5E9", borderRadius: 1, padding: "4px 8px", display: "inline-block", fontSize: '17px' }}>
                                    <span>qty: <strong>{relatedOrder.quantity}</strong></span>
                                  </Box>
                                  <span style={{ marginLeft: "4px", fontSize: '17px' }}>x {relatedOrder.unit_price}</span>
                                </Grid>

                                {/* Total Price aligned to the extreme right */}
                                <Grid item>
                                  <strong style={{ fontSize: '17px' }}>R{relatedOrder.total_price}</strong>
                                </Grid>
                              </Grid>
                            </Grid>
                            {<Divider sx={{ my: 2 }} />}
                            <div style={{ textAlign: "center", }}>
                              <span
                                style={{
                                  color: "Gray",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  fontSize: "16px"
                                }}
                                onClick={(e) => toggleExpandDialog(e, relatedOrder.id)}
                              >
                                {expandedOrderIds.includes(relatedOrder.id) ? "View Less" : "View More"}
                              </span>
                            </div>

                            {expandedOrderIds.includes(relatedOrder.id) && (
                              <Grid item xs={12} style={{ marginTop: "10px", marginRight: "20px", color: "black" }}>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "15px" }}>
                                  {order.transaction_id && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Order Id : <span style={{ color: "black" }}>{relatedOrder.order_id}</span>
                                    </span>
                                  )}
                                  {relatedOrder.order_instruction && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Order Notes : <span style={{ color: "black" }}>{capitalizeFirstLetter(relatedOrder.order_instruction)}</span>
                                    </span>
                                  )}
                                  {relatedOrder.quantity && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Quantity : <span style={{ color: "black" }}>{relatedOrder.quantity}</span>
                                    </span>
                                  )}
                                  {relatedOrder.unit_price && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Unit Price : <span style={{ color: "black" }}>{relatedOrder.unit_price}</span>
                                    </span>
                                  )}
                                  {order.total_price && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Total Price : <span style={{ color: "black" }}>{relatedOrder.total_price}</span>
                                    </span>
                                  )}
                                  {(relatedOrder.discount_amount != 0) && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Discount : <span style={{ color: "black" }}>{relatedOrder.discount_amount}</span>
                                    </span>
                                  )}
                                  {(relatedOrder.discount_amount != 0) && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Final Price : <span style={{ color: "black" }}>{relatedOrder.final_price}</span>
                                    </span>
                                  )}
                                  {/* {order.payment_status && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Payment Status : <span style={{ color: "black" }}>{capitalizeFirstLetter(order.payment_status)}</span>
                                    </span>
                                  )} */}
                                  {/* {order.payment_mode.payment_method_name === "Pay at Store" && (
                  <>
                    <FormControl
                      onChange={(e) => handlePaymentStatus(e, order)}
                    >
                      <select style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem" }}>
                        <option value="" style={{ backgroundColor: "black", color: "white" }}>Select Payment Option</option>
                        <option value="cash" style={{ backgroundColor: "black", color: "white" }}>Cash</option>
                        <option value="card" style={{ backgroundColor: "black", color: "white" }}>Card</option>
                        <option value="online" style={{ backgroundColor: "black", color: "white" }}>Online</option>
                      </select>
                    </FormControl>
                    <input
                      type="text"
                      placeholder="Other Instructions(Optional)"
                      style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                    />
                    <button
                      style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                    >
                      Submit
                    </button>
                  </>
                )} */}

                                  {order.payment_mode_name === "Pay at Store" && value === "Tab3" && (
                                    <>
                                      <FormControl>
                                        <select
                                          onClick={(e) => e.stopPropagation()} // Prevent card click
                                          // value={payBy || order.pay_by} // Default value from order
                                          // onChange={(e) => setPayBy(e.target.value)} // Update state correctly
                                          value={payBy}
                                          onChange={(e) => setPayBy(e.target.value)}
                                          disabled={!isEditable}
                                          style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", borderRadius: "4px" }}>
                                          <option value="">Select Payment Option</option>
                                          <option value="cash">Cash</option>
                                          <option value="card">Card</option>
                                          <option value="online">Online</option>
                                          <option value="Snap Scan">Snap Scan</option>
                                        </select>
                                      </FormControl>
                                      <input
                                        type="text"
                                        placeholder="Other Instructions(Optional)"
                                        // value={orderNotes || order.order_notes} // Default value from order
                                        // onChange={(e) => setOrderNotes(e.target.value)} // Update state correctly
                                        value={orderNotes}
                                        onChange={(e) => setOrderNotes(e.target.value)}
                                        disabled={!isEditable}
                                        style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                                        onClick={(e) => e.stopPropagation()} // Prevent card click
                                      />
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent card click
                                          handleButtonClick();
                                        }}
                                        style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                                      >
                                        {isEditable ? "Save" : "Edit"}
                                      </button>
                                    </>
                                  )}

                                  {order.created_at && (
                                    <Grid style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      <span>
                                        Date : <span style={{ color: "black" }}>{moment(order.created_at).format(" ddd[,] D MMM YYYY, h:mm A")}</span>
                                      </span>
                                    </Grid>
                                  )}
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </CardContent>
                      </Card>}
                      {value === "Tab3" && relatedOrder?.delivery_status_str === "Delivered" && <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2, p: 1.5 }}>
                        {/* <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={4}><strong>Order ID:</strong></Grid>
                            <Grid item xs={6} sm={8}>{relatedOrder.order_id}</Grid>

                            <Grid item xs={6} sm={4}><strong>Product:</strong></Grid>
                            <Grid item xs={6} sm={8}>{relatedOrder.product_name}</Grid>

                            <Grid item xs={6} sm={4}><strong>Quantity:</strong></Grid>
                            <Grid item xs={6} sm={8}>{relatedOrder.quantity}</Grid>

                            <Grid item xs={6} sm={4}><strong>Unit Price:</strong></Grid>
                            <Grid item xs={6} sm={8}>${relatedOrder.unit_price}</Grid>

                            <Grid item xs={6} sm={4}><strong>Total Price:</strong></Grid>
                            <Grid item xs={6} sm={8}>${relatedOrder.total_price}</Grid>
                          </Grid>

                          <Divider sx={{ my: 1 }} />

                          <Grid container spacing={1}>
                            <Grid item xs={6}><strong>Delivery Status:</strong></Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl size="small" fullWidth sx={{
                                width: { xs: '28vw', sm: '9vw', md: '9vw' }, // Responsive width
                                // minWidth: '150px', // Minimum width
                                backgroundColor: "black",
                                color: "white"
                              }}>
                                <Select
                                  labelId="order-status-label"
                                  id="order-status"
                                  value={relatedOrder.delivery_status}
                                  onChange={(e) => handleStatusUpdate(e, relatedOrder)}
                                  displayEmpty
                                  sx={{
                                    fontSize: "0.8rem",
                                    color: "white",
                                    "& .MuiSvgIcon-root": { color: "white" },
                                    "& .MuiSelect-select": { color: "white" },
                                  }}
                                  MenuProps={{
                                    sx: {
                                      "& .MuiMenuItem-root": {
                                        fontSize: "0.8rem",
                                        color: "white",
                                        backgroundColor: "black",
                                        "&:hover": { backgroundColor: "#333" },
                                      },
                                      "& .MuiList-root": {
                                        backgroundColor: "black",
                                      },
                                    },
                                  }}
                                >

                                  <MenuItem value={5} disabled>🎉 Delivered</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>


                          </Grid>
                        </CardContent> */}
                        <CardContent>
                          {/* <Grid container spacing={1}>
                        <Grid item xs={6} sm={4}><strong>Order ID:</strong></Grid>
                        <Grid item xs={6} sm={8}>{relatedOrder.order_id}</Grid>

                        <Grid item xs={6} sm={4}><strong>Product:</strong></Grid>
                        <Grid item xs={6} sm={8}>{relatedOrder.product_name}</Grid>

                        <Grid item xs={6} sm={4}><strong>Quantity:</strong></Grid>
                        <Grid item xs={6} sm={8}>{relatedOrder.quantity}</Grid>

                        <Grid item xs={6} sm={4}><strong>Unit Price:</strong></Grid>
                        <Grid item xs={6} sm={8}>${relatedOrder.unit_price}</Grid>

                        <Grid item xs={6} sm={4}><strong>Total Price:</strong></Grid>
                        <Grid item xs={6} sm={8}>${relatedOrder.total_price}</Grid>
                      </Grid>

                      <Divider sx={{ my: 1 }} /> */}



                          <Grid item xs={12} key={index}>
                            <Grid container spacing={1} alignItems="center">
                              {/* Checkbox */}
                              <Grid container spacing={1} alignItems="center" wrap="nowrap">

                                {/* <Grid item xs>
                                  <strong> {relatedOrder.product_name}</strong>
                                </Grid> */}
                                <Grid container spacing={1} alignItems="center" justifyContent="flex-start" wrap="wrap">
                                  {/* Checkbox */}
                                  <Grid item xs="auto">
                                    <Checkbox
                                      // checked={Array.isArray(selectedOrderIds) && selectedOrderIds.includes(relatedOrder.id)}
                                      checked={true}
                                      onChange={() => handleCheckboxChange(relatedOrder.id)}
                                      sx={{
                                        padding: 0,
                                        '&:hover': { backgroundColor: 'transparent' },
                                      }}
                                      disabled
                                    />
                                  </Grid>

                                  {/* Product Name */}
                                  <Grid
                                    item
                                    xs
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexGrow: 1,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      fontSize: "17px",
                                    }}
                                  >
                                    <strong>{relatedOrder.product_name}</strong>
                                  </Grid>

                                  {/* Delivery Status - Moves to Next Line on Small Screens */}
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      color: "#757575",
                                      pointerEvents: 'none',
                                      marginLeft: { xs: 0, md: '8px' }, // No margin-left for small screens to align properly
                                      textAlign: { xs: 'right', sm: "right", md: 'right', lg: "right" }, // Align left for small screens, right for larger screens
                                      fontSize: "17px",
                                      flexBasis: { xs: '100%', md: 'auto' }, // Forces a new line only on small screens
                                    }}
                                  >
                                    {relatedOrder.delivery_status_str}
                                  </Grid>
                                </Grid>

                              </Grid>
                              {/* Add-ons (if any) */}
                              {relatedOrder.order_instruction && (
                                // (order.order_instruction) &&
                                <Grid item xs={12} sx={{ fontSize: '0.875rem', color: "#757575", pointerEvents: 'none' }}>
                                  Notes: {relatedOrder.order_instruction}
                                  {/* {order.order_instruction} */}
                                </Grid>
                              )}

                              {/* Quantity, Unit Price, Total Price - No Space between Quantity and Unit Price */}
                              <Grid container item xs={12} spacing={1} alignItems="center" justifyContent="space-between">
                                {/* Quantity and Unit Price */}
                                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                  <Box sx={{ background: "#E8F5E9", borderRadius: 1, padding: "4px 8px", display: "inline-block", fontSize: '17px' }}>
                                    <span>qty: <strong>{relatedOrder.quantity}</strong></span>
                                  </Box>
                                  <span style={{ marginLeft: "4px", fontSize: '17px' }}>x {relatedOrder.unit_price}</span>
                                </Grid>

                                {/* Total Price aligned to the extreme right */}
                                <Grid item style={{ fontSize: '17px' }}>
                                  <strong>R{relatedOrder.total_price}</strong>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Divider between items */}
                            {<Divider sx={{ my: 2 }} />}
                            <div style={{ textAlign: "center", }}>
                              <span
                                style={{
                                  color: "Gray",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  fontSize: "16px"
                                }}
                                onClick={(e) => toggleExpandDialog(e, relatedOrder.id)}
                              >
                                {expandedOrderIds.includes(relatedOrder.id) ? "View Less" : "View More"}
                              </span>
                            </div>

                            {expandedOrderIds.includes(relatedOrder.id) && (
                              <Grid item xs={12} style={{ marginTop: "10px", marginRight: "20px", color: "black" }}>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "15px" }}>
                                  {order.transaction_id && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Order Id : <span style={{ color: "black" }}>{relatedOrder.order_id}</span>
                                    </span>
                                  )}
                                  {relatedOrder.order_instruction && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Order Notes : <span style={{ color: "black" }}>{capitalizeFirstLetter(relatedOrder.order_instruction)}</span>
                                    </span>
                                  )}
                                  {relatedOrder.quantity && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Quantity : <span style={{ color: "black" }}>{relatedOrder.quantity}</span>
                                    </span>
                                  )}
                                  {relatedOrder.unit_price && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Unit Price : <span style={{ color: "black" }}>{relatedOrder.unit_price}</span>
                                    </span>
                                  )}
                                  {order.total_price && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Total Price : <span style={{ color: "black" }}>{relatedOrder.total_price}</span>
                                    </span>
                                  )}
                                  {(relatedOrder.discount_amount != 0) && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Discount : <span style={{ color: "black" }}>{relatedOrder.discount_amount}</span>
                                    </span>
                                  )}
                                  {(relatedOrder.discount_amount != 0) && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Final Price : <span style={{ color: "black" }}>{relatedOrder.final_price}</span>
                                    </span>
                                  )}
                                  {/* {order.payment_status && (
                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      Payment Status : <span style={{ color: "black" }}>{capitalizeFirstLetter(order.payment_status)}</span>
                                      </span>
                                  )} */}
                                  {/* {order.payment_mode.payment_method_name === "Pay at Store" && (
                  <>
                    <FormControl
                      onChange={(e) => handlePaymentStatus(e, order)}
                    >
                      <select style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem" }}>
                        <option value="" style={{ backgroundColor: "black", color: "white" }}>Select Payment Option</option>
                        <option value="cash" style={{ backgroundColor: "black", color: "white" }}>Cash</option>
                        <option value="card" style={{ backgroundColor: "black", color: "white" }}>Card</option>
                        <option value="online" style={{ backgroundColor: "black", color: "white" }}>Online</option>
                      </select>
                    </FormControl>
                    <input
                      type="text"
                      placeholder="Other Instructions(Optional)"
                      style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                    />
                    <button
                      style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                    >
                      Submit
                    </button>
                  </>
                )} */}

                                  {/* {order.payment_mode_name === "Pay at Store" && value === "Tab3" && (
                                    <>
                                      <FormControl>
                                        <select
                                          onClick={(e) => e.stopPropagation()} // Prevent card click
                                          // value={payBy || order.pay_by} // Default value from order
                                          // onChange={(e) => setPayBy(e.target.value)} // Update state correctly
                                          value={payBy}
                                          onChange={(e) => setPayBy(e.target.value)}
                                          disabled={!isEditable}
                                          style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", borderRadius: "4px" }}>
                                          <option value="">Select Payment Option</option>
                                          <option value="cash">Cash</option>
                                          <option value="card">Card</option>
                                          <option value="online">Online</option>
                                          <option value="Snap Scan">Snap Scan</option>
                                        </select>
                                      </FormControl>
                                      <input
                                        type="text"
                                        placeholder="Other Instructions(Optional)"
                                        // value={orderNotes || order.order_notes} // Default value from order
                                        // onChange={(e) => setOrderNotes(e.target.value)} // Update state correctly
                                        value={orderNotes}
                                        onChange={(e) => setOrderNotes(e.target.value)}
                                        disabled={!isEditable}
                                        style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                                        onClick={(e) => e.stopPropagation()} // Prevent card click
                                      />
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent card click
                                          handleButtonClick();
                                        }}
                                        style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                                      >
                                        {isEditable ? "Save" : "Edit"}
                                      </button>
                                    </>
                                  )} */}

                                  {order.created_at && (
                                    <Grid style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                      <span>
                                        Date : <span style={{ color: "black" }}>{moment(order.created_at).format(" ddd[,] D MMM YYYY, h:mm A")}</span>
                                      </span>
                                    </Grid>
                                  )}
                                </div>
                              </Grid>
                            )}
                          </Grid>

                          {/* <Grid container spacing={1}>
                        <Grid item xs={6}><strong>Delivery Status:</strong></Grid>
                        {value === "Tab1" && (
                          <Grid item xs={12} sm={6}>
                            <FormControl size="small" fullWidth sx={{
                              width: { xs: '28vw', sm: '9vw', md: '9vw' }, // Responsive width
                              // minWidth: '150px', // Minimum width
                              backgroundColor: "black",
                              color: "white"
                            }}>
                              <Select
                                labelId="order-status-label"
                                id="order-status"
                                value={relatedOrder.delivery_status}
                                onChange={(e) => handleStatusUpdate(e, relatedOrder)}
                                displayEmpty
                                sx={{
                                  fontSize: "0.8rem",
                                  color: "white",
                                  "& .MuiSvgIcon-root": { color: "white" },
                                  "& .MuiSelect-select": { color: "white" },
                                }}
                                MenuProps={{
                                  sx: {
                                    "& .MuiMenuItem-root": {
                                      fontSize: "0.8rem",
                                      color: "white",
                                      backgroundColor: "black",
                                      "&:hover": { backgroundColor: "#333" },
                                    },
                                    "& .MuiList-root": {
                                      backgroundColor: "black",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value={1} disabled style={{ fontSize: "15px" }}>
                                  Order Placed
                                </MenuItem>
                                <MenuItem value={2}>✅Confirmed </MenuItem>
                                <MenuItem value={3}>📦 Packed</MenuItem>
                                <MenuItem value={4}>🚚 Shipped</MenuItem>
                                <MenuItem value={5}>🎉 Delivered</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        )}

                      </Grid> */}
                        </CardContent>
                      </Card>}
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <h3>Order Details</h3>
                  </Grid>

                  <Grid item xs={6}>
                    <strong style={{ fontSize: '16px' }}>TRANSACTION ID:</strong>
                  </Grid>
                  <Grid item xs={6} textAlign="right" style={{ fontSize: '0.875rem' }}>
                    {order.transaction_id.slice(7)}
                  </Grid>

                  <Grid item xs={6}>
                    <strong style={{ fontSize: '16px' }}>PAYMENT STATUS:</strong>
                  </Grid>
                  <Grid item xs={6} textAlign="right" style={{ fontSize: '0.875rem' }}>
                    {order.payment_status}
                  </Grid>

                  <Grid item xs={6}>
                    <strong style={{ fontSize: '16px' }}>PAYMENT MODE:</strong>
                  </Grid>
                  <Grid item xs={6} textAlign="right" style={{ fontSize: '0.875rem' }}>
                    {order.payment_mode_name}
                  </Grid>

                  <Grid item xs={6}>
                    <strong style={{ fontSize: '16px' }}>TOTAL PRICE:</strong>
                  </Grid>
                  <Grid item xs={6} textAlign="right" style={{ fontSize: '0.875rem' }}>
                    R{(order.total_price)}
                  </Grid>

                  {(order.voucher_code) && <Grid item xs={6}>
                    <strong style={{ fontSize: '16px' }}>VOUCHER CODE:</strong>
                  </Grid>}
                  {(order.voucher_code) && <Grid item xs={6} textAlign="right" style={{ fontSize: '0.875rem' }}>
                    {(order.voucher_code)}
                  </Grid>}

                  {(order.voucher_discount != 0) && <Grid item xs={6}>
                    <strong style={{ fontSize: '16px' }}>VOUCHER DISCOUNT:</strong>
                  </Grid>}
                  {(order.voucher_discount != 0) && <Grid item xs={6} textAlign="right" style={{ fontSize: '0.875rem' }}>
                    R{(order.voucher_discount)}
                  </Grid>}

                  <Grid item xs={6}>
                    <strong style={{ fontSize: '16px' }}>FINAL PRICE:</strong>
                  </Grid>
                  <Grid item xs={6} textAlign="right" style={{ fontSize: '0.875rem' }}>
                    R{(order.final_price)}
                  </Grid>

                  <Grid item xs={6}>
                    <strong style={{ fontSize: '16px' }}>DATE:</strong>
                  </Grid>
                  <Grid item xs={6} textAlign="right" style={{ fontSize: '0.875rem' }}>
                    {moment(order.created_at).format(" ddd[,] D MMM YYYY, h:mm A")}
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
            // <Paper sx={{ maxWidth: "100%", margin: "auto", padding: 3, boxShadow: 3, borderRadius: 3 }}>
            // <Grid container spacing={2}>
            //                                         <Grid item xs={12}>
            //                                             <h2>Your Orders</h2>
            //                                         </Grid>

            //                                         {order.related_orders.map((item, index) => (
            //                                             <Grid item xs={12} key={index}>
            //                                                 <Grid container spacing={1} alignItems="center">
            //                                                     {/* Checkbox */}
            //                                                     <Grid container spacing={1} alignItems="center" wrap="nowrap">
            //                                                         <Grid item xs="auto"> {/* Auto width to fit checkbox */}
            //                                                             <Checkbox
            //                                                                 sx={{
            //                                                                     padding: 0,
            //                                                                     '&:hover': { backgroundColor: 'transparent' },
            //                                                                 }}
            //                                                             />
            //                                                         </Grid>
            //                                                         <Grid item xs> {/* Let the text take remaining space */}
            //                                                             <strong> 🟢 {item.name}</strong>
            //                                                         </Grid>
            //                                                     </Grid>
            //                                                      {/* Add-ons (if any) */}
            //                                                      {item.addOns && (
            //                                                         // (order.order_instruction) &&
            //                                                          <Grid item xs={12} sx={{ color: "#757575", pointerEvents: 'none' }}>
            //                                                             Notes: {item.addOns}
            //                                                             {/* {order.order_instruction} */}
            //                                                         </Grid>
            //                                                     )}

            //                                                     {/* Quantity, Unit Price, Total Price - No Space between Quantity and Unit Price */}
            //                                                     <Grid container item xs={12} spacing={1} alignItems="center" justifyContent="space-between">
            //                                                         {/* Quantity and Unit Price */}
            //                                                         <Grid item sx={{ display: "flex", alignItems: "center" }}>
            //                                                             <Box sx={{ background: "#E8F5E9", borderRadius: 1, padding: "4px 8px", display: "inline-block" }}>
            //                                                                 <strong>{item.quantity}</strong>
            //                                                             </Box>
            //                                                             <span style={{ marginLeft: "4px" }}>x ₹{item.unitPrice}</span>
            //                                                         </Grid>

            //                                                         {/* Total Price aligned to the extreme right */}
            //                                                         <Grid item>
            //                                                             <strong>₹{item.totalPrice}</strong>
            //                                                         </Grid>
            //                                                     </Grid>
            //                                                 </Grid>

            //                                                 {/* Divider between items */}
            //                                                 {index !== order.related_orders.length - 1 && <Divider sx={{ my: 2 }} />}
            //                                             </Grid>
            //                                         ))}

            //                                         <Grid item xs={12}>
            //                                             <Divider sx={{ my: 2 }} />
            //                                         </Grid>

            //                                         {/* Order Details Section */}
            //                                         <Grid item xs={12}>
            //                                             <h3>Order Details</h3>
            //                                         </Grid>

            //                                         <Grid item xs={6}>
            //                                             <strong>TRANSACTION ID:</strong>
            //                                         </Grid>
            //                                         <Grid item xs={6} textAlign="right">
            //                                             {order.transaction_id.slice(7)}
            //                                         </Grid>

            //                                         <Grid item xs={6}>
            //                                             <strong>PAYMENT MODE:</strong>
            //                                         </Grid>
            //                                         <Grid item xs={6} textAlign="right">
            //                                             {order.payment_mode_name}
            //                                         </Grid>

            //                                         <Grid item xs={6}>
            //                                             <strong>DATE:</strong>
            //                                         </Grid>
            //                                         <Grid item xs={6} textAlign="right">
            //                                             {moment(order.created_at).format(" ddd[,] D MMM YYYY, h:mm A")}
            //                                         </Grid>

            //                                         {/* <Grid item xs={6}>
            //                                             <strong>PHONE NUMBER:</strong>
            //                                         </Grid>
            //                                         <Grid item xs={6} textAlign="right">
            //                                             {orderDetails.phoneNumber}
            //                                         </Grid> */}
            //                                     </Grid>
            //                                     </Paper>
          ) : (
            <Grid container justifyContent="center">
              <strong>No related orders found.</strong>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
    // </div>
  );
};


export default Home;





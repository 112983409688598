import React from "react";
import WithFooter from "./WithFooter";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useMediaQuery } from "@mui/system";
function Shippingv1() {

  const isMobile = useMediaQuery("(max-width: 568px)");

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true,
  };
  return (
    <WithFooter>
      <div class="shipping">
        <div class="hero">
          <div class="hero__herosection">
            <img
              src="images/homebanner-bg-0.png"
              alt=""
              class="hero__herosection__img1"
            />
            <img
              src="images/homebanner-bg-1.svg"
              alt=""
              class="hero__herosection__img2"
            />
            <div class="hero__content">
              <div class="hero__content__div">
                <div class="hero__content__div__text">
                  <div class="hero__content__div__text__heading">
                    We have your <br />
                    Shipping covered
                  </div>
                  <div class="hero__content__div__text__content">
                    <div class="hero__content__div__text__content__top">
                      We've combined creating an online store, payment, shipping
                      and shop management into a single, easy-to-use platform.
                    </div>
                    <div class="hero__content__div__text__button">
                      <Link to={"/auth/signup"}>Get started — it's free</Link>
                    </div>
                  </div>
                </div>
                <div class="hero__content__div__image">
                  <img src="images/shipping-hero-image.svg" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shipping__container">
          <div class="shipping__container__toppart">
            <div class="shipping__container__toppart__heading">
              Business Simplified I Shipping Streamlined
            </div>
            <div class="shipping__container__toppart__box">
              <div class="shipping__container__toppart__box__content">
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Simple, Easy-to-use Platform
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: "35vh",
                        // alignItems: "center",
                        paddingTop: "15%",
                      }}
                    >
                      <div class="text">
                        Be empowered with the tools and expertise to streamline,
                        simplify, and succeed. Compare rates, access a network
                        of reliable carriers, and optimize your shipping process
                        with ease.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Your Delivery, Your Choice
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: "35vh",
                        // alignItems: "center",
                        paddingTop: "15%",
                      }}
                    >
                      <div class="text">
                        Shiprazor compares rates from multiple logistics
                        providers, so you can find the best price for your
                        delivery needs.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
              <div class="shipping__container__toppart__box__content">
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div
                      class="heading"
                      style={{
                        color: "#688ad5",
                      }}
                    >
                      Deliver Anything, Anytime
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: "35vh",
                        // alignItems: "center",
                        paddingTop: "15%",
                      }}
                    >
                      <div class="text">
                        Shiprazor can deliver anything! Just enter the
                        dimensions of your item, and they'll provide you with a
                        list of logistics providers that best suits your needs.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Easier and Faster
                    </div>

                    <div
                      style={{
                        display: "flex",
                        minHeight: "35vh",
                        // alignItems: "center",
                        paddingTop: "15%",
                      }}
                    >
                      <div class="text">
                        They assist your team to ensure that everything is made
                        easier and faster, all while keeping you informed at
                        every stage in the process.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="payments__container__partners">
            <div class="payments__container__partners__div">
              <div class="payments__container__partners__div__button">
                <Link to={"/auth/signup"}>Get started — it's free</Link>
              </div>
              <div class="payments__container__partners__div__head">
                <span></span>
                <span>Trusted And Supported By</span>
                <span></span>
              </div>
              <div
            className={isMobile ? "" : "homepage__partners__div__brandlogos"}
          >
            {isMobile ? (
              <div className="image-slider-container" style={{padding:"1rem"}}>
                <Slider {...carouselSettings}>
                  <div>
                  <img src="images/brandimg1.svg" alt="brandimg1" />
                  </div>
                  <div>
                  <img src="images/brandimg2.svg" alt="brandimg2" />
                  </div>
                  <div >
                  <img src="images/brandimg3.svg" alt="brandimg3" />
                  </div>
                  <div >
                  <img src="images/black-landscape (1).svg" style={{width:"55%",margin:"auto", objectFit:"contain"}} />
            
                  </div>
                  <div>
                  <img src="images/brandimg4.svg" alt="brandimg4" />
                  </div>
                </Slider>
              </div>
            ) : (
              <>
                <div style={{ width: "25%" }}>
                  <img src="images/brandimg1.svg" alt="brandimg1" />
                </div>
                <div style={{ width: "20%" }}>
                  <img src="images/brandimg2.svg" alt="brandimg2" />
                </div>
                {/* </div> */}
                {/* <div className="homepage__partners__div__brandlogos__c1"> */}
                <div style={{ width: "20%" }}>
                  <img src="images/brandimg3.svg" alt="brandimg3" />
                </div>
                <div style={{ width: "20%" }}>
                  <img src="images/sticitt-landscape.svg" alt="brandimg3" />
                </div>
                <div style={{ width: "20%" }}>
                  <img src="images/brandimg4.svg" alt="brandimg4" />
                </div>
              </>
            )}
          </div>
            </div>
          </div>
        </div>
        <div class="homepage__support">
          <div class="homepage__support__div">
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg1.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Responsive Support</div>
                <div>
                  Prompt assistance and swift resolution to any inquiries,
                  concerns, or issues.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg2.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Simplified Marketing Tools</div>
                <div>
                  Streamline promotional efforts with easy to use and efficient
                  marketing tools.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg3.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Mobile Optimized</div>
                <div>
                  A highly responsive interface for effortless mobile shopping.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithFooter>
  );
}

export default Shippingv1;

import React, { useState } from "react";
import WithFooter from "./WithFooter";
import { Player } from "@lottiefiles/react-lottie-player";
import { APIRouteConstants } from "constants/routeConstants";
import CircularProgress from "@mui/material/CircularProgress";
import axiosInstance from "configs/axiosConfig";
import { enqueueSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import { Box } from "@mui/material";
function ContactUsv1() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    contactno: "",
    email: "",
    message: "",
    selectoption: "",
  });
  const [errors, setErrors] = useState({});
  console.log("type12", formData.selectoption);
  const validate = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (formData.selectoption === "none") {
      newErrors.selectoption = "Option is required";
    }

    const phoneRegex = /^[0-9\b]+$/;
    if (!formData.contactno) {
      newErrors.contactno = "Contact number is required";
    } else if (!phoneRegex.test(formData.contactno)) {
      newErrors.contactno = "Invalid contact number";
    } else if (formData.contactno.length < 10) {
      newErrors.contactno = "Contact number must be exactly 10 digits.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log("type", errors);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendFeedback = async () => {
    try {
      const formDAta = {
        name: formData.name,
        contact: formData.contactno,
        email: formData.email,
        msg: formData.message,
        interest: formData.selectoption,
      };
      setLoading(true);
      let res = await axiosInstance.post(
        APIRouteConstants.LANDING.FEEDBACK,
        formDAta
      );
      if (res === false) {
        setLoading(false);
        return
      }

      let { data, response } = res;
      if (data != undefined && data.status === 200) {
        enqueueSnackbar(
          "Thank you. We've received your message and will be in touch shortly.",
          {
            variant: "success",
          }
        );
        setLoading(false);
        setFormData({
          name: "",
          contactno: "",
          email: "",
          message: "",
          selectoption: "",
        });
      } else if (response?.data?.error) {
        console.log("asfasfsaf", JSON.parse(response.data.error));
        setLoading(false);
        enqueueSnackbar(response?.data?.["error"], { variant: "error" });
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar("Something went srong", { variant: "error" });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (validate()) {
      sendFeedback();
    }
  };

  return (
    <WithFooter>
      <div className="contactuspage">
        <div className="hero">
          <div className="hero__herosection">
            <img
              src="images/homebanner-bg-0.png"
              alt=""
              className="hero__herosection__img1"
            />
            <img
              src="images/homebanner-bg-1.svg"
              alt=""
              className="hero__herosection__img2"
            />
            <div className="hero__content">
              <div className="hero__content__div">
                <div className="hero__content__div__text">
                  <div className="hero__content__div__text__heading">
                    Contact us
                  </div>
                  <div className="hero__content__div__text__content">
                    <div className="hero__content__div__text__content__top">
                      We've combined creating an online store, payment, shipping
                      and shop management into a single, easy-to-use platform.
                      Need support?
                    </div>
                    {/* <div className="hero__content__div__text__button">
                      <a href="Login2.html">Get in touch</a>
                    </div> */}
                  </div>
                </div>
                <div className="hero__content__div__image">
                  <div id="animation-container">
                    <Player
                      autoplay
                      loop
                      src="https://assets3.lottiefiles.com/packages/lf20_aesgckiv.json"
                      style={{ height: "300px", width: "300px" }}
                    />
                    {/* <Player
                      src="https://assets3.lottiefiles.com/packages/lf20_aesgckiv.json"
                      background="transparent"
                      speed="1"
                      style="width: 520px; height: 520px"
                      loop
                      autoplay
                    ></Player> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contactuspage__container">
          <div className="contactuspage__container__div">
            <div className="contactuspage__container__div__para">
              <div className="contactuspage__container__div__para__form">
                {/* <!-- Sign up form --> */}
                <div className="contactuspage__container__div__para__form__container">
                  <h3 className="heading">Get In Touch</h3>
                  <div className="contact__form">
                    <div className="contact__form__inputfield">
                      <label className="contact__form__label" for="name">
                        NAME
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter Your Name"
                      />
                      {errors && (
                        <p style={{ fontSize: 12, color: "#DD4899" }}>
                          {errors.name}
                        </p>
                      )}
                    </div>

                    <div className="contact__form__inputfield">
                      <div>
                        <label className="contact__form__label" for="contactno">
                          CONTACT NUMBER
                        </label>
                        <input
                          type="tel"
                          name="contactno"
                          id="contactno"
                          maxLength={10}
                          pattern="\d{10}"
                          value={formData.contactno}
                          onChange={handleChange}
                          placeholder="Enter Contact Number"
                        />
                        {errors && (
                          <p
                            style={{
                              fontSize: 12,
                              paddingTop: 10,
                              color: "#DD4899",
                            }}
                          >
                            {errors.contactno}
                          </p>
                        )}
                      </div>
                      <div>
                        <label className="contact__form__label" for="email">
                          EMAIL ADDRESS
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Enter Email Address"
                        />
                      </div>
                      {errors && (
                        <p style={{ fontSize: 12, color: "#DD4899" }}>
                          {errors.email}
                        </p>
                      )}
                    </div>

                    <div className="contact__form__inputfield">
                      <label
                        className="contact__form__label"
                        for="selectoption"
                      >
                        I AM INTERESTED IN
                      </label>

                      <select
                        name="selectoption"
                        id="selectoption"
                        value={formData.selectoption}
                        onChange={handleChange}
                      >
                        <option value="none">Choose your interest</option>
                        <option value="I need a demo">I need a demo</option>
                        <option value="I want to become a partner">
                          I want to become a partner
                        </option>
                        <option value="Facing issues with the system">
                          Facing issues with the system
                        </option>
                        <option value="General enquiry">General enquiry</option>
                      </select>
                      {errors && (
                        <p style={{ fontSize: 12, color: "#DD4899" }}>
                          {errors.selectoption}
                        </p>
                      )}
                    </div>
                    <div className="contact__form__inputfield">
                      <label className="contact__form__label" for="message">
                        MESSAGE (OPTIONAL)
                      </label>
                      <input
                        onChange={handleChange}
                        value={formData.message}
                        type="text"
                        name="message"
                        id="message"
                        placeholder="Type Your Message"
                      />
                      {errors && (
                        <p style={{ fontSize: 12, color: "#DD4899" }}>
                          {errors.message}
                        </p>
                      )}
                    </div>
                    <div id="contact__form__inputfield__button">
                      {loading ? (
                        <Box
                          sx={{
                            padding: "2px 75px",
                            background: "transparent",
                            display: "flex",
                          }}
                        >
                          <CircularProgress
                            style={{ color: "#dd4498" }}
                            size={25}
                          />
                        </Box>
                      ) : (
                        <button
                          className="contact__form__button__submit"
                          onClick={handleClick}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {/* <!-- Lottie animation -->
          <!-- <div id="animation-container">
              <lottie-player
                src="https://assets3.lottiefiles.com/packages/lf20_aesgckiv.json"
                background="transparent"
                speed="1"
                style="width: 520px; height: 520px"
                loop
                autoplay
              ></lottie-player>
            </div> --> */}
              </div>
            </div>
          </div>
          <div className="contactuspage__container__welcomediv">
            <div className="contactuspage__container__welcomediv__title">
              <span></span>
              <span>Everyone Is Welcome</span>
              <span></span>
            </div>
            <div className="contactuspage__container__welcomediv__div1">
              <div>Freelancers</div>
              <div>Digital Agency</div>
              <div>Bloggers</div>
            </div>
            <div className="contactuspage__container__welcomediv__div2">
              <div>Influencers</div>
              <div>Small Businesses</div>
              <div>Side Income Lovers</div>
            </div>
          </div>
          <div className="aboutpage__container__trustdiv">
            <div className="aboutpage__container__trustdiv__left">
              <div className="aboutpage__container__trustdiv__left__title">
                <span>TRUSTED AND </span>
                <span>SUPPORTED BY </span>
              </div>
              <div className="aboutpage__container__trustdiv__left__images">
                <div className="aboutpage__container__trustdiv__left__images__top">
                  <div>
                    <img src="images/brandimagewhite1.svg" alt="brand image" />
                  </div>
                  <div>
                    <img src="images/brandimagewhite2.svg" alt="brand image" />
                  </div>
                </div>
                <div className="aboutpage__container__trustdiv__left__images__top">
                  <div>
                    <img src="images/brandimagewhite3.svg" alt="brand image" />
                  </div>
                  <div>
                    <img src="images/white-landscape.svg" alt="brand image" />
                  </div>
                  <div>
                    <img src="images/brandimagewhite4.svg" alt="brand image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="aboutpage__container__trustdiv__right">
              <div>
                <span> OPEN YOUR DIGITAL </span>
                <span> STORE NOW </span>
              </div>
              <div className="aboutpage__container__trustdiv__right__nextbtn">
                <div className="aboutpage__container__trustdiv__right__nextbtn__imgdiv">
                  <img src="images/rightarrowpink.svg" alt="right icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="homepage__support">
          <div className="homepage__support__div">
            <div className="homepage__support__div__card">
              <div className="homepage__support__div__card__image">
                <img src="images/supportimg1.svg" alt="Image" />
                <div className="roundshape"></div>
              </div>
              <div className="homepage__support__div__card__content">
                <div>Responsive Support</div>
                <div>
                  Prompt assistance and swift resolution to any inquiries,
                  concerns, or issues.
                </div>
              </div>
              <div className="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div className="homepage__support__div__card__nextbtn__imgdiv">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div className="homepage__support__div__card">
              <div className="homepage__support__div__card__image">
                <img src="images/supportimg2.svg" alt="Image" />
                <div className="roundshape"></div>
              </div>
              <div className="homepage__support__div__card__content">
                <div>Simplified Marketing Tools</div>
                <div>
                  Streamline promotional efforts with easy to use and efficient
                  marketing tools.
                </div>
              </div>
              <div className="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div className="homepage__support__div__card__nextbtn__imgdiv">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div className="homepage__support__div__card">
              <div className="homepage__support__div__card__image">
                <img src="images/supportimg3.svg" alt="Image" />
                <div className="roundshape"></div>
              </div>
              <div className="homepage__support__div__card__content">
                <div>Mobile Optimized</div>
                <div>
                  A highly responsive interface for effortless mobile shopping.
                </div>
              </div>
              <div className="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div className="homepage__support__div__card__nextbtn__imgdiv">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithFooter>
  );
}

export default ContactUsv1;

import React from "react";
import WithFooter from "./WithFooter";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Grid,
  InputAdornment,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
const FAQV1 = () => {
  return (
    <WithFooter>
      <SpendaFAQ />
    </WithFooter>
  );
};

export default FAQV1;

function SpendaFAQ() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ backgroundColor: "#fff", color: "white", padding: "30px" }}>
      <Grid container justifyContent="space-between" alignItems="center" pb={1}>
        <Grid item>
          <Typography
            style={{ fontSize: "18px", fontWeight: "bold" }}
            gutterBottom
            color={"#000"}
          >
            Webbie Shop addresses frequently asked questions. For any other
            enquiries, please email us at{" "}
            <a href="mailto:hello@webbieshop.com">hello@webbieshop.com</a>.
          </Typography>
        </Grid>
      </Grid>
      {/* support@webbieshop.com */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            1. What is Webbie Shop?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            Webbie Shop is an online platform that enables you to create,
            customize, and manage your own online store with ease. It integrates
            store creation, payment processing, shipping, and shop management
            into a single, user-friendly platform.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            2. How does WebbieShop support South African entrepreneurs?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            WebbieShop offers localised support, including easy integration with
            South African banks and payment methods, shipping operators,
            compliance with local regulations, and a platform designed to
            address the specific needs of South African businesses.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            3. What types of businesses can use WebbieShop?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            WebbieShop is suitable for a wide range of businesses, from small
            startups to larger enterprises, selling products or booking services
            in various categories, including fashion, beauty, catering,
            electronics, home goods, and more.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            4. Are there any transaction fees?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            There are no transaction fees. WebbieShop does not charge any
            transaction fees for sales made through the platform.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            5. How do I enroll as a merchant?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            To enroll, click the "Sign Up" button, choose the merchant option,
            and fill in the required details. Video tutorials are available upon
            signing up to guide you through the steps.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            6. What features does Webbie Shop offer for merchants?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            We provide inventory management, secure payment processing, shipping
            integrations, and marketing tools to help grow your business.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            7. What are the payment options available?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            We offer Peach Payments, Sticitt, cash-on-delivery, and other
            popular local payment options available in South Africa.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            8. Will my store be mobile-responsive?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            Absolutely! Our platform ensures a highly responsive interface for
            effortless mobile use.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            9. How do I set up shipping options?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            Set up shipping options by navigating to the "Settings" section in
            your account and selecting "Shipping." From there, you can define
            your shipping methods, rates, and regions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            10. What support is available for merchants?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            Our support team is available via the "Contact Us" page or email at
            <a href="mailto:hello@webbieshop.com">hello@webbieshop.com.</a> We
            ensure all emails are responded to within 24 hours.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            11. How do I add products to my store?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            After signing up, you can add products through the merchant
            dashboard. Simply click "Add Product," fill in the product details,
            and upload images.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
        style={{
          paddingBottom: "10px",
          margin: "0px",
          borderRadius: "0px",
          boxShadow: "none",
          borderBottom: "1px solid #e5e5e5",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "700" }}>
            12. How secure is my online store?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "15px" }}>
            Webbie Shop uses SSL encryption and adheres to strict security
            standards to protect your store and customer data.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

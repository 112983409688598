import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
    Card,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControl,
    TextField,
    InputAdornment,
    FormControlLabel,
    Switch,
    CardActions,
    CardContent,
    InputLabel,
    Select,
    Box,
    MenuItem, useMediaQuery, useTheme,
    Checkbox,
    Paper
} from '@mui/material';

// import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from 'notistack';
import Loader from 'components/Loader';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";

import ImageUploader from 'components/Inputs/ImageUploader';
import CustomStepper from 'components/Stepper';

import PendingIcon from '@mui/icons-material/Pending';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BackpackIcon from '@mui/icons-material/Backpack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckIcon from '@mui/icons-material/Check';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CloseIcon from '@mui/icons-material/Close';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { GLOBAL_CURRENCY } from 'constants/appConstants';

let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: GLOBAL_CURRENCY.code,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2
});

const deliveryStatus = {
    1: 'Order Placed',
    2: 'Confirmed',
    3: 'Packed',
    4: 'Shipped',
    5: 'Delivered',
    6: 'Return Requested',
    7: 'Return Confirmed',
    8: 'Return Completed',
    10: 'Cancelled',
    11: 'ORDER_FAILED',
    12: 'Update Order'
}

const status = {
    1: 'Order Placed',
    2: 'Order Confirmed',
    3: 'Order Packed',
    4: 'Order Shipped',
    5: 'Order Delivered',
    6: 'Return Requested',
    7: 'Return Confirmed',
    8: 'Return Completed',
    10: 'Cancelled'
}

const orderDetails = {
    items: [
        {
            name: "Sandwich",
            addOns: "Extra Cheese",
            quantity: 2,
            unitPrice: 100,
            totalPrice: 200,
        },
        {
            name: "Coffee",
            quantity: 2,
            unitPrice: 150,
            totalPrice: 300,
        },
    ],
    charges: {
        itemTotal: 835,
        taxes: 167.13,
        deliveryCharge: 51,
        packagingCharges: 83.5,
        platformFee: 10,
        grandTotal: 1146.63,
    },
    orderNumber: "48e",
    payment: "Using Card (R1146.63)",
    date: "March 16, 2025 at 09:15 AM",
    phoneNumber: "8777890987",
};



const defaultOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[2],
    id: 2,
    statusId: 2,
    icon: <ThumbUpAltIcon />
}, {
    label: status[3],
    id: 3,
    statusId: 3,
    icon: <BackpackIcon />
}, {
    label: status[4],
    id: 4,
    statusId: 4,
    icon: <LocalShippingIcon />
}, {
    label: status[5],
    id: 5,
    statusId: 5,
    icon: <CheckIcon />
}]

const cancelledOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[2],
    id: 2,
    statusId: 2,
    icon: <ThumbUpAltIcon />
}, {
    label: status[3],
    id: 3,
    statusId: 3,
    icon: <BackpackIcon />
}, {
    label: status[10],
    id: 4,
    statusId: 10,
    icon: <CloseIcon />
}]

const returnOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[5],
    id: 2,
    statusId: 5,
    icon: <CheckIcon />
}, {
    label: status[6],
    id: 3,
    statusId: 6,
    icon: <HourglassBottomIcon />
}, {
    label: status[7],
    id: 4,
    statusId: 7,
    icon: <CheckIcon />
}, {
    label: status[8],
    id: 5,
    statusId: 8,
    icon: <AssignmentReturnIcon />
}]

const getOrderStatusCycle = (status) => {
    const defaultStatusList = [1, 2, 3, 4, 5]
    const returnStatusList = [6, 7, 8]
    const cancelStatusCycle = [9, 10]
    if (_.includes(defaultStatusList, status)) return defaultOrderCycle;
    if (_.includes(returnStatusList, status)) return returnOrderCycle;
    if (_.includes(cancelStatusCycle, status)) return cancelledOrderCycle;
}

const capitalizeFirstLetter = (str) => {
    if (!str) return ""; // Handle null or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const showName = (args) => {
    return _.compact(args).join(' ')
}

const showAddress = (address) => {
    return (
        <div className="address-wrapper">
            <span>{showName([address.first_name, address.last_name])} | {showName([address.country_code, address.phone_number])}</span><br />
            <Divider className='content-divider' />
            <span>{address.street_address}</span><br />
            <span>{address.city}</span><br />
            <span>{address.state}, {address.country} - {address.pin}</span>
        </div>
    )
}

const OrderDetails = ({
    discardUpdateOrder,
    orderId
}) => {
    const [loader, setLoader] = useState(true);
    const [allCategories, setAllCategories] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrderDetail] = useState({});
    const [orderCycle, setOrderCycle] = useState(defaultOrderCycle);

    const getOrderDetail = async (orderId) => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.GET_ORDER(
            orderId
            // 465
        ))
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            setOrderDetail(data)
            console.log('getOrderStatusCycle(data.delivery_status) ', getOrderStatusCycle(data.delivery_status))
            if (data.delivery_status)
                setOrderCycle(getOrderStatusCycle(data.delivery_status))
        }
        setLoader(false)
    }

    useEffect(() => {
        if (orderId) {
            getOrderDetail(orderId)
        }
    }, [orderId])

    const getActiveStep = (steps, statusId) => {
        return _.get(_.find(steps, { statusId: statusId }), 'id', 1)
    }

    // const updateOrderStatus = async (status) => {
    //     setLoader(true)
    //     let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER(orderId), {
    //         delivery_status: status
    //     })
    //     if (res === false) {
    //         setLoader(false);
    //         return
    //     }
    //     let { data, response } = res;

    //     if (data) {
    //         getOrderDetail(orderId)
    //     }
    // }

    const updateOrderStatus = async (status, orderIds) => {
        setLoader(true);

        // Get the latest selected orders
        const latestOrderIds = [...orderIds]; // Ensure we are working with a fresh copy

        console.log("Selected Order IDs:", latestOrderIds); // Debugging

        // Validate orderIds to prevent errors
        if (!Array.isArray(latestOrderIds) || latestOrderIds.length === 0) {
            enqueueSnackbar("Select at least one item to proceed", { variant: "warning" });
            setLoader(false);
            return;
        }

        try {
            const res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER, {
                order_ids: latestOrderIds,
                delivery_status: status
            });

            // if (!res?.data) {
            //     enqueueSnackbar("Failed to update order status", { variant: "error" });
            //     console.log(res, 'RESPONSE');
            //     return;
            // }
            if (res?.response?.data?.error) {
                enqueueSnackbar(res?.response?.data?.error, { variant: "error" });
                return;
            }

            //   latestOrderIds.forEach(id => getOrderList(id));
            setSelectedOrderIds([]); // Clear selection after update
            enqueueSnackbar("Order status updated successfully", { variant: "success" });
            getOrderDetail(orderId)

        } catch (error) {
            console.error("Update order error:", error);
            enqueueSnackbar(error?.response?.data?.error || "Something went wrong", { variant: "error" });
        } finally {
            setLoader(false);
        }
    };

    const cancelOrder = async (status) => {
        setLoader(true)
        let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER(orderId), {
            delivery_status: 10
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            getOrderDetail(orderId)
        }
    }

    // const handleStatusUpdate = (event) => {
    //     updateOrderStatus(event.target.value);
    // };

    const [status1, setStatus] = useState(""); // Ensure initial state is valid
    const [isChecked, setIsChecked] = useState(order.isChecked || false); // Initialize state

    const [selectedOrderIds, setSelectedOrderIds] = useState([]);

    // const handleCheckboxChange = (event) => {
    //     setIsChecked(event.target.checked);
    //     // You might want to update the order object as well:
    //     // order.isChecked = event.target.checked;
    //     // ... any other logic you need
    // };

    const handleCheckboxChange = (orderId) => {
        setSelectedOrderIds(prevSelected => {
            const updatedSelection = prevSelected.includes(orderId)
                ? prevSelected.filter(id => id !== orderId) // Remove if already selected
                : [...prevSelected, orderId]; // Add if not selected

            console.log("Updated selection:", updatedSelection); // Debugging
            return updatedSelection;
        });
    };

    // const handleStatusUpdate = (event) => {
    //     updateOrderStatus(event.target.value);
    // };
    const handleStatusUpdate = (event) => {
        if (!Array.isArray(selectedOrderIds) || selectedOrderIds.length === 0) {
            enqueueSnackbar("Please select at least one order", { variant: "warning" });
            return;
        }

        console.log("Updating orders:", selectedOrderIds); // Debugging

        updateOrderStatus(event.target.value, selectedOrderIds);
    };

    const handleSelectAll = (event, relatedOrders) => {
        if (event.target.checked) {
            const allIds = relatedOrders.map((ro) => ro.id);
            setSelectedOrderIds(allIds);
        } else {
            setSelectedOrderIds([]);
        }
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen is small

    const [expandedOrderIds, setExpandedOrderIds] = useState([]);

    const toggleExpandDialog = (event, orderId) => {
        event.stopPropagation();
        setExpandedOrderIds((prev) =>
            prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId]
        );
    };



    return (

        <Grid container className='component-grid'>
            <Loader open={loader} />
            <Grid item xs={12}>
                <Card className='list-wrapper dashboard-card-wrapper'>
                    {!loader ?
                        <CardContent>
                            <div className='header-wrapper header-flex'>
                                <span className='header-text'>Order {`>`} {_.get(order, 'transaction_id')} </span>
                                {_.get(order.related_orders, 'delivery_status') < 3 || _.get(order.related_orders, 'delivery_status') === 10 ?
                                    <Button
                                        onClick={() => cancelOrder()}
                                        variant="outlined"
                                        color="error"
                                        disabled={_.get(order.related_orders, 'delivery_status') == 10}
                                        size="small">
                                        {_.get(order.related_orders, 'delivery_status') == 10 ? 'Cancelled' : 'Cancel Order'}
                                    </Button>
                                    : ''}
                            </div>
                            {/* <div className='header-wrapper'>
                        <span className='header-text'>Order {`>`} {_.get(order, 'order_id')} </span>
                    </div> */}
                            <Divider />
                            <div className="content-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div className="content-text" style={{ marginRight: '16px' }}> {/* Add margin for spacing */}
                                    This is where you will be creating and managing your orders.
                                </div>
                                <FormControl
                                    size="medium" // Use "medium" for a larger size
                                    sx={{
                                        minWidth: 180, // Increased width
                                        backgroundColor: 'black',  // Darker background
                                        borderRadius: '8px', // More rounded corners
                                        border: '1px solid #555', // Added a border
                                        '&:hover': {
                                            borderColor: '#777', // Lighter border on hover
                                        },
                                        '& .MuiInputBase-root': { // Style the input base
                                            height: '40px', // Increased height
                                            padding: '0 16px', // Adjust padding
                                            color: 'white', // Text color white
                                            fontSize: '18px', // Larger font size
                                            '& .MuiSelect-select': {
                                                paddingRight: '32px' //Adjust padding to show the dropdown icon
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Removed default border
                                            },
                                            '&:focus': {
                                                backgroundColor: '#444', // Slightly lighter background on focus
                                                outline: 'none' //remove outline
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'white', // Lighter gray
                                                fontSize: '24px',
                                                position: 'absolute',  // Position the *dropdown* arrow
                                                right: '20px',         // Place it on the right
                                                top: '50%',            // Vertically center it
                                                transform: 'translateY(-50%)'// Larger icon
                                            },
                                        },
                                        MenuProps: {
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#333', // Dark background for menu
                                                    color: 'white', // Text color white
                                                    '& .MuiMenuItem-root': {
                                                        fontSize: '18px',
                                                        '&:hover': {
                                                            backgroundColor: '#444', // Slightly lighter on hover
                                                        },
                                                    },
                                                },
                                            },
                                        }, marginRight: '15px'
                                    }}
                                >
                                    <Select
                                        labelId="order-status-label"
                                        id="order-status"
                                        value={0}
                                        onChange={(e) => handleStatusUpdate(e, order.related_orders.map(o => o.id))}
                                        displayEmpty
                                    >
                                        <MenuItem value={0} disabled>Update Status</MenuItem>
                                        {/* {order.delivery_status_type === "Cancelled" ? <MenuItem value={1} disabled>
                                            Order Cancelled
                                        </MenuItem> : <MenuItem value={1} disabled>
                                            Order Placed
                                        </MenuItem>} */}
                                        {/* <MenuItem value={2}>✅ Confirmed</MenuItem> */}
                                        {(order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped" || order.delivery_status_type === "Delivered" || order.delivery_status_type === "Cancelled") ? <MenuItem value={2} disabled>✅ Confirmed</MenuItem> : <MenuItem value={2}>✅ Confirmed</MenuItem>}
                                        {/* <MenuItem value={3}>📦 Packed</MenuItem> */}
                                        {(order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped" || order.delivery_status_type === "Delivered" || order.delivery_status_type === "Cancelled") ? <MenuItem value={3} disabled>📦 Packed</MenuItem> : <MenuItem value={3}>📦 Packed</MenuItem>}
                                        {/* <MenuItem value={4}>🚚 Shipped</MenuItem> */}
                                        {(order.delivery_status_type === "Shipped" || order.delivery_status_type === "Delivered" || order.delivery_status_type === "Cancelled") ? <MenuItem value={4} disabled>🚚 Shipped</MenuItem> : <MenuItem value={4}>🚚 Shipped</MenuItem>}
                                        {/* <MenuItem value={5}>🎉 Delivered</MenuItem> */}
                                        {(order.delivery_status_type === "Delivered" || order.delivery_status_type === "Cancelled") ? <MenuItem value={5} disabled>🎉 Delivered</MenuItem> : <MenuItem value={5}>🎉 Delivered</MenuItem>}
                                        {/* <MenuItem value={5}>❌ Cancelled</MenuItem> */}
                                        {(order.delivery_status_type === "Cancelled") ? <MenuItem value={10} disabled>❌ Cancelled</MenuItem> : <MenuItem value={10}>❌ Cancelled</MenuItem>}
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                                {/* <div className='status-wrapper' style={{ width: '100%', maxWidth: '1200px' }}>
                                    <CustomStepper
                                        steps={orderCycle}
                                        activeStep={getActiveStep(orderCycle, _.get(order, 'delivery_status'))}
                                        completedStep={_.get(order, 'delivery_status')}
                                    />
                                </div> */}
                                {/* Conditionally render the Grid based on screen size */}
                                {/* {!isSmallScreen && ( // Only render on medium screens and larger
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className='status-wrapper' style={{ width: '100%' }}>
                                                <CustomStepper
                                                    steps={orderCycle}
                                                    activeStep={getActiveStep(orderCycle, _.get(order, 'delivery_status'))}
                                                    completedStep={_.get(order, 'delivery_status')}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                )} */}

                                {/* <Grid container spacing={2} wrap='wrap' style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
                                    <Grid item xs={12} marginTop='15px'>
                                        <span><b>{capitalizeFirstLetter(_.get(order, 'product_detail.name'))}</b></span>
                                    </Grid>
                                    {_.get(order, 'product_detail.images')?.length > 0 && (
                                        <Grid item xs={12} sm={6} md={4} lg={3} className='image-grid' style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div className='image-container'>
                                                <img src={order.product_detail.images[0]?.image} alt='Product' style={{ maxHeight: '200px', width: '100%', objectFit: 'contain' }} />
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={6} md={8} lg={9} className='order-detail-container'>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <span><b>Transaction Id: </b>{_.get(order, 'transaction_id')}</span>
                                                <br />
                                                <span><b>Order Id: </b>{_.get(order, 'order_id')}</span>
                                                <br />
                                                <span ><b>Order Status: </b>{status[_.get(order, 'delivery_status')]}</span>
                                                <br />
                                                <span><b>Ordered On: </b>{moment(order.created).format('[On] ddd, D MMM')}</span>
                                                <br />
                                                <span><b>Quantity: </b>{order.quantity}</span>
                                                {order.availableStock && <br />}
                                                {order.availableStock && <span><b>Available Stock: </b>{order.availableStock}</span>}
                                                <br />
                                                <span><b>Price: </b>{formatCurrency.format(order.unit_price)}</span>
                                                <br />
                                            </Grid>
                                            {!_.isEmpty(order?.payment_mode) && (
                                                <Grid item xs={12}>
                                                    <div className='payment-mode-wrapper'>
                                                        <Grid container spacing={1} alignItems='center'>
                                                            <Grid item xs={6}>
                                                                <div style={{ wordBreak: 'break-word' }}>{order.payment_mode.payment_method_name}</div>
                                                            </Grid>
                                                            <Grid item xs={6} textAlign='right'>
                                                                <div>{formatCurrency.format(order.quantity * order.unit_price)}</div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            )}
                                            <Divider className='content-divider' style={{ width: '100%' }} />
                                            <Grid item xs={12}>
                                                <span><b>Total Order Price: </b>{formatCurrency.format(order.total_price)}</span>
                                                <br />
                                                <div style={{ marginBottom: '10px' }}><b>Ordered by: </b>{_.get(order, 'customer_name')}</div>
                                            </Grid>
                                            {order.shipping_address && (
                                                <Grid item xs={12} md={6}>
                                                    <div><b>Delivery Address:</b></div>
                                                    {showAddress(order.shipping_address)}
                                                </Grid>
                                            )}
                                            {order.billing_address && (
                                                <Grid item xs={12} md={6}>
                                                    <div style={{ marginTop: '16px' }}><b>Billing Address:</b></div>
                                                    {showAddress(order.billing_address)}
                                                </Grid>
                                            )}
                                            {(order.billing_address === null || order.shipping_address === null) && (
                                                <Grid item xs={12} className='action-wrapper'></Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid> */}



                                {/* //NEW UI  */}
                                {/* <Grid
                                    container
                                    spacing={2}
                                    direction="column" // Stack items vertically
                                    wrap="nowrap" // Prevent wrapping to rows
                                    sx={{
                                        width: '100%',
                                        maxWidth: '1200px',
                                        margin: '0 auto',
                                        padding: '16px',
                                        border: '1px solid #ddd',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    {orders.map((order, index) => (
                                        <Grid item key={index} xs={12} className="order-detail-container" sx={{ padding: '16px' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={order.isChecked || false} onChange={() => { }} />}
                                                        label={capitalizeFirstLetter(_.get(order, 'product_detail.name'))}
                                                        sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'bold', fontSize: '1.2rem' } }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <span><b>Transaction Id: </b>{_.get(order, 'transaction_id')}</span>
                                                    <br />
                                                    <span><b>Order Id: </b>{_.get(order, 'order_id')}</span>
                                                    <br />
                                                    <span><b>Order Status: </b>{status[_.get(order, 'delivery_status')]}</span>
                                                    <br />
                                                    <span><b>Ordered On: </b>{moment(order.created).format('[On] ddd, D MMM')}</span>
                                                    <br />
                                                    <span><b>Quantity: </b>{order.quantity}</span>
                                                    {order.availableStock && <br />}
                                                    {order.availableStock && <span><b>Available Stock: </b>{order.availableStock}</span>}
                                                    <br />
                                                    <span><b>Price: </b>{formatCurrency.format(order.unit_price)}</span>
                                                    <br />
                                                </Grid>
                                                {!_.isEmpty(order?.payment_mode) && (
                                                    <Grid item xs={12}>
                                                        <div className='payment-mode-wrapper'>
                                                            <Grid container spacing={1} alignItems='center'>
                                                                <Grid item xs={6}>
                                                                    <div style={{ wordBreak: 'break-word' }}>{order.payment_mode.payment_method_name}</div>
                                                                </Grid>
                                                                <Grid item xs={6} textAlign='right'>
                                                                    <div>{formatCurrency.format(order.quantity * order.unit_price)}</div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                )}
                                                <Divider className='content-divider' style={{ width: '100%' }} />
                                                <Grid item xs={12}>
                                                    <span><b>Total Order Price: </b>{formatCurrency.format(order.total_price)}</span>
                                                    <br />
                                                    <div style={{ marginBottom: '10px' }}><b>Ordered by: </b>{_.get(order, 'customer_name')}</div>
                                                </Grid>
                                                {order.shipping_address && (
                                                    <Grid item xs={12} md={6}>
                                                        <div><b>Delivery Address:</b></div>
                                                        {showAddress(order.shipping_address)}
                                                    </Grid>
                                                )}
                                                {order.billing_address && (
                                                    <Grid item xs={12} md={6}>
                                                        <div style={{ marginTop: '16px' }}><b>Billing Address:</b></div>
                                                        {showAddress(order.billing_address)}
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid> */}



                                <Paper sx={{ maxWidth: "100%", margin: "auto", padding: 3, boxShadow: 3, borderRadius: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <h2 style={{ fontSize: "26px" }}>Your Orders</h2>
                                        </Grid>
                                        <Grid container alignItems="center" marginTop="35px" marginLeft="15px" spacing={1} sx={{ display: "flex", flexWrap: "wrap" }}>
                                            <Grid item>
                                                {order.related_orders.some(ro => ro.delivery_status !== 5 && ro.delivery_status !== 10) && (
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={
                                                                order.related_orders.length > 0 &&
                                                                order.related_orders.every((ro) =>
                                                                    Array.isArray(selectedOrderIds) && selectedOrderIds.includes(ro.id)
                                                                )
                                                            }
                                                            onChange={(e) => handleSelectAll(e, order.related_orders)}
                                                            sx={{
                                                                padding: 0,
                                                                '&:hover': { backgroundColor: 'transparent' },
                                                            }}
                                                            style={{ marginLeft: "8px" }}
                                                        />
                                                        <strong style={{ fontSize: "18px", marginRight: "10px", marginLeft: "5px" }}>Select All</strong>
                                                    </Grid>
                                                )}


                                                {/* <strong style={{ fontSize: "18px", marginRight: "10px", marginLeft: "5px" }}>Select All</strong> */}
                                            </Grid>
                                        </Grid>

                                        {order.related_orders.map((item, index) => (
                                            <Grid item xs={12} key={index} > {/* Add padding for spacing */}
                                                <Grid container spacing={1} alignItems="center">
                                                    {/* Main Row: Checkbox, Image, Product Name, Delivery Status */}
                                                    <Grid container item xs={12} alignItems="center" justifyContent="space-between" wrap="nowrap" marginLeft="15px">
                                                        {/* Left Side: Checkbox, Image, Product Name */}
                                                        <Grid container item alignItems="center" wrap="wrap" spacing={1}>
                                                            {/* Checkbox */}
                                                            <Grid item xs="auto">
                                                                {item.delivery_status !== 5 && item.delivery_status !== 10 && (
                                                                    <Checkbox
                                                                        checked={Array.isArray(selectedOrderIds) && selectedOrderIds.includes(item.id)}
                                                                        onChange={() => handleCheckboxChange(item.id)}
                                                                        sx={{
                                                                            padding: 0,
                                                                            '&:hover': { backgroundColor: 'transparent' },
                                                                        }}
                                                                    />
                                                                )}
                                                            </Grid>

                                                            {/* Product Image (if available) */}
                                                            {_.get(item, 'product_image')?.length > 0 && (
                                                                <Grid item>
                                                                    <div className="image-container">
                                                                        <img
                                                                            src={item.product_image}
                                                                            alt="Product"
                                                                            style={{
                                                                                height: "50px",
                                                                                width: "50px",
                                                                                objectFit: "cover",
                                                                                verticalAlign: "middle",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            )}

                                                            {/* Product Name */}
                                                            <Grid
                                                                item
                                                                xs
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    flexGrow: 1,
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    fontSize: "18px",
                                                                }}
                                                            >
                                                                <strong>{item.product_name}</strong>
                                                            </Grid>

                                                            {/* Delivery Status - Moves to Next Line on Small Screens and is Centered */}
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sx={{
                                                                    color: "#757575",
                                                                    pointerEvents: 'none',
                                                                    marginLeft: { xs: 0, md: '8px' }, // No margin-left for small screens to align properly
                                                                    textAlign: { xs: 'right', sm: "right", md: 'right', lg: "right" }, // Align left for small screens, right for larger screens
                                                                    fontSize: "17px",
                                                                    flexBasis: { xs: '100%', md: 'auto' }, // Forces a new line only on small screens
                                                                  }}
                                                            >
                                                                {item.delivery_status_str}
                                                            </Grid>
                                                        </Grid>


                                                        {/* Right Side: Delivery Status */}
                                                        {/* <Grid item>
                                                            <span style={{ color: "#757575", pointerEvents: 'none', whiteSpace: 'nowrap', marginRight: "15px", fontSize: "18px" }}>
                                                                {item.delivery_status_str}
                                                            </span>
                                                        </Grid> */}

                                                    </Grid>

                                                    {/* Add-ons (if any) */}
                                                    {item.order_instruction && (
                                                        <Grid item xs={12} sx={{ color: "#757575", pointerEvents: 'none', marginTop: '8px', marginLeft: "15px", fontSize: "18px" }}>
                                                            Notes: {item.order_instruction}
                                                        </Grid>
                                                    )}

                                                    {/* Quantity, Unit Price, Total Price */}
                                                    <Grid container item xs={12} spacing={1} alignItems="center" justifyContent="space-between" style={{ marginTop: '8px' }}>
                                                        {/* Quantity and Unit Price */}
                                                        <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                                            <Box sx={{ background: "#E8F5E9", borderRadius: 1, padding: "4px 8px", display: "inline-block", marginLeft: "15px", fontSize: "17px" }}>
                                                                <span>qty: <strong>{item.quantity}</strong></span>
                                                            </Box>
                                                            <span style={{ marginLeft: "4px", fontSize: "17px" }}>x {item.unit_price}</span>
                                                        </Grid>

                                                        {/* Total Price aligned to the extreme right */}
                                                        <Grid item style={{ marginRight: "15px", fontSize: "17px" }}>
                                                            <strong >R {item.total_price}</strong>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <div style={{ textAlign: "center", marginTop: "10px" }}>
                                                    <span
                                                        style={{
                                                            color: "#757575",
                                                            cursor: "pointer",
                                                            textDecoration: "underline",
                                                            fontSize: '17px'
                                                        }}
                                                        onClick={(e) => toggleExpandDialog(e, item.id)}
                                                    >
                                                        {expandedOrderIds.includes(item.id) ? "View Less" : "View More"}
                                                    </span>
                                                </div>

                                                {expandedOrderIds.includes(item.id) && (
                                                    <Grid item xs={12} style={{ marginTop: "20px", marginRight: "20px", color: "black" }}>
                                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "15px" }}>
                                                            {item.order_id && (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', fontSize: '17px' }}>
                                                                    <span style={{ color: 'black' }}>Order Id:</span>
                                                                    <span style={{ color: '#757575', marginLeft: '5px' }}>{item.order_id}</span>
                                                                </div>
                                                            )}
                                                            {item.order_instruction && (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', fontSize: '17px' }}>
                                                                    <span style={{ color: 'black' }}>Order Notes:</span>
                                                                    <span style={{ color: '#757575', marginLeft: '5px' }}>{item.order_instruction}</span>
                                                                </div>
                                                            )}
                                                            {item.quantity && (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', fontSize: '17px' }}>
                                                                    <span style={{ color: 'black' }}>Quantity:</span>
                                                                    <span style={{ color: '#757575', marginLeft: '5px' }}>{item.quantity}</span>
                                                                </div>
                                                            )}
                                                            {item.unit_price && (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', fontSize: '17px' }}>
                                                                    <span style={{ color: 'black' }}>Unit Price:</span>
                                                                    <span style={{ color: '#757575', marginLeft: '5px' }}>R{item.unit_price}</span>
                                                                </div>
                                                            )}
                                                            {item.total_price && (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', fontSize: '17px' }}>
                                                                    <span style={{ color: 'black' }}>Total Price:</span>
                                                                    <span style={{ color: '#757575', marginLeft: '5px' }}>R{item.total_price}</span>
                                                                </div>
                                                            )}
                                                            {/* {order.payment_status && (
                                                                                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                                                                                      Payment Status : <span style={{ color: "black" }}>{capitalizeFirstLetter(order.payment_status)}</span>
                                                                                    </span>
                                                                                  )} */}
                                                            {/* {order.payment_mode.payment_method_name === "Pay at Store" && (
                                                                  <>
                                                                    <FormControl
                                                                      onChange={(e) => handlePaymentStatus(e, order)}
                                                                    >
                                                                      <select style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem" }}>
                                                                        <option value="" style={{ backgroundColor: "black", color: "white" }}>Select Payment Option</option>
                                                                        <option value="cash" style={{ backgroundColor: "black", color: "white" }}>Cash</option>
                                                                        <option value="card" style={{ backgroundColor: "black", color: "white" }}>Card</option>
                                                                        <option value="online" style={{ backgroundColor: "black", color: "white" }}>Online</option>
                                                                      </select>
                                                                    </FormControl>
                                                                    <input
                                                                      type="text"
                                                                      placeholder="Other Instructions(Optional)"
                                                                      style={{ marginBottom: "5px", padding: "5px", fontSize: "0.7rem", color: "black", border: "1px solid gray", borderRadius: "4px", borderColor: "#DEDEDE" }}
                                                                    />
                                                                    <button
                                                                      style={{ marginTop: "5px", marginBottom: "5px", padding: "5px", fontSize: "0.8rem", backgroundColor: "black", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}
                                                                    >
                                                                      Submit
                                                                    </button>
                                                                  </>
                                                                )} */}

                                                            {order.created_at && (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', fontSize: '17px' }}>
                                                                    <span style={{ color: 'black' }}>Date:</span>
                                                                    <span style={{ color: '#757575', marginLeft: '5px' }}>{moment(order.created_at).format(" ddd[,] D MMM YYYY, h:mm A")}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                )}

                                                {/* Divider between items */}
                                                {<Divider sx={{ my: 2 }} />}
                                            </Grid>
                                        ))}

                                        {/* <Grid item xs={12}>
                                            <Divider sx={{ my: 2 }} />
                                        </Grid> */}

                                        {/* Order Details Section */}
                                        <Grid item xs={12} style={{ fontSize: '20px' }}>
                                            <h3>Order Details</h3>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <strong style={{ fontSize: '18px' }}>TRANSACTION ID:</strong>
                                        </Grid>
                                        <Grid item xs={6} textAlign="right" style={{ fontSize: '18px' }}>
                                            {order.transaction_id.slice(7)}
                                        </Grid>

                                        <Grid item xs={6} style={{ fontSize: '18px' }}>
                                            <strong>PAYMENT STATUS:</strong>
                                        </Grid>
                                        <Grid item xs={6} textAlign="right" style={{ fontSize: '18px' }}>
                                            {order.payment_status}
                                        </Grid>

                                        <Grid item xs={6} style={{ fontSize: '18px' }}>
                                            <strong>PAYMENT MODE:</strong>
                                        </Grid>
                                        <Grid item xs={6} textAlign="right" style={{ fontSize: '18px' }}>
                                            {order.payment_mode_name}
                                        </Grid>

                                        <Grid item xs={6} style={{ fontSize: '18px' }}>
                                            <strong>DATE:</strong>
                                        </Grid>
                                        <Grid item xs={6} textAlign="right" style={{ fontSize: '18px' }}>
                                            {moment(order.created_at).format(" ddd[,] D MMM YYYY, h:mm A")}
                                        </Grid>

                                        {/* <Grid item xs={6}>
                                            <strong>PHONE NUMBER:</strong>
                                        </Grid>
                                        <Grid item xs={6} textAlign="right">
                                            {orderDetails.phoneNumber}
                                        </Grid> */}
                                    </Grid>
                                </Paper>



                            </div>
                        </CardContent>
                        : ''}
                    <CardActions className='footer-wrapper'>
                        <div className="action-wrapper">
                            <Button variant="contained" color="success" onClick={() => discardUpdateOrder(false)}>
                                Go Back
                            </Button>
                            {/* <Button variant="contained" type="submit" color="secondary">
                                {selectedProduct ? 'Update Product' : 'Save Product'}
                            </Button> */}
                        </div>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>

    )
}

export default OrderDetails;
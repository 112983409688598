import { useEffect, useState } from 'react';
import _ from 'lodash';

import {
    Card,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControl,
    TextField,
    InputAdornment,
    Switch,
    CardActions,
    CardContent,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete
} from '@mui/material';

// import Select, { StylesConfig } from 'react-select';

import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from 'notistack';
import Loader from 'components/Loader';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";

import ImageUploader from 'components/Inputs/ImageUploader';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { GLOBAL_CURRENCY } from 'constants/appConstants';
import { useSelector } from 'react-redux';

const NewProduct = ({
    discardNewProduct,
    productId
}) => {
    const [allCategories, setAllCategories] = useState([]);
    const { register, formState: { errors }, handleSubmit, control } = useForm();
    const [loader, setLoader] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [productImg, setProductImg] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [warehouseAddresses, setWarehouseAddresses] = useState([]);

    const loggedInUser = useSelector((state) => state.userDetails);
    const { user } = loggedInUser;
    const getCategoryList = async () => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.SEARCH_CATEGORIES)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data && data.length) {
            setAllCategories(data)
        }
        getWarehouseAddresses()
    }

    const getWarehouseAddresses = async () => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.GET_DELIVERY_LOCATION)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            setWarehouseAddresses(data)
        }
        if (productId) {
            getProductDetails(productId)
        } else setLoader(false)
    }

    const addProduct = async (formData) => {
        if (productId) {
            updateProduct(formData)
            return;
        }

        let payload = new FormData();

        for (let i in formData) {
            payload.append(i, formData[i])
        }

        setLoader(true);
        if (!productImg.length) {
            setLoader(false)
            enqueueSnackbar('Product Images are required', { variant: 'error' });
            return false
        } else {
            for (let i in productImg) {
                payload.append(`images[${i}].image`, productImg[i])
            }
        }

        payload.append('currency', GLOBAL_CURRENCY.code)

        let res = await axiosInstance.post(APIRouteConstants.STORE.ADD_PRODUCT, payload)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 400
            && response.data) {
            setProductImg([])
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }
        

        if (data) {
            enqueueSnackbar('Product added successfully', { variant: 'success' });
            discardNewProduct(false);
        }

        if (response === false) {
            setLoader(false);
            setProductImg([])
            return;
        }
        setLoader(false);
    };

    const updateProduct = async (formData) => {

        let payload = new FormData();

        for (let i in formData) {
            payload.append(i, formData[i])
        }

        setLoader(true);
        if (!productImg.length) {
            setLoader(false)
            enqueueSnackbar('Product Image are required', { variant: 'error' });
            return false
        } else {
            for (let i in productImg) {
                if (productImg[i] && typeof productImg[i].name == 'string') {
                    payload.append(`images[${i}].image`, productImg[i])
                } else payload.append(`images[${productImg[i].sequence}].image`, productImg[i].image)
            }
        }

        payload.append('currency', GLOBAL_CURRENCY.code)

        let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_PRODUCT(productId), payload)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 400
            && response.data) {

            console.log("response12", response?.data)
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response?.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('Product updated successfully', { variant: 'success' });
            discardNewProduct(false);
        }
        setLoader(false);
    };

    const getProductDetails = async (productId) => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.GET_PRODUCT(productId))
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            setSelectedProduct(data)

            if (!_.isEmpty(data.images)) {
                let imageObj = []
                for (let i in data.images) {
                    imageObj.push({
                        image: data.images[i].image,
                        sequence: i
                    })
                }
                setProductImg(imageObj)
            }
        }
        setLoader(false)
    }

    useEffect(() => {
        getCategoryList();
    }, [])



    // useEffect(() => {
    //     if (productId) {
    //         getProductDetails(productId)
    //     }
    // }, [productId])
    console.log("errors", errors)
    return (
        <Grid container className='component-grid'>
            <Loader open={loader} />
            <Grid item xs={12}>
                <Card className='dashboard-card-wrapper'>
                    <form onSubmit={handleSubmit(addProduct)}>
                        {!loader ?
                            <CardContent>
                                <div className='header-wrapper'>
                                    <span className='header-text'>Products {`>`} {selectedProduct ? selectedProduct.name : 'New'} Product</span>
                                </div>
                                <Divider />
                                <div className='content-wrapper'>
                                    <span className='content-text'>
                                        This is where you will be creating and managing your products. The success of your sales is highly dependent on how well your products are presented to your customers. In order to create a lasting, positive first impression and increase desirability of your products, it is important that the product information is clear, compelling and that it highlights the most important aspects of your product.
                                    </span>
                                </div>
                                <Grid container className='dashboard-form-container' spacing={4} wrap='wrap'>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Product name
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    Please enter your full product name here.
                                                </span>
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    id="name"
                                                    placeholder="Product Name"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={selectedProduct?.name}
                                                    {...register("name", { required: true })}
                                                    error={errors.name?.type}
                                                    helperText={errors.name?.type === "required" && (
                                                        'Product name is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='multiple-ele form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Categories
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    Add/select one or more categories in which the product will be shown.
                                                </span>
                                            </FormLabel>
                                            <FormControl fullWidth className='select-wrapper'>
                                                <Controller
                                                    control={control}
                                                    name="category"
                                                    rules={{ required: true }}
                                                    defaultValue={selectedProduct?.category || ''}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Autocomplete
                                                            disablePortal
                                                            size="small"
                                                            options={allCategories}
                                                            value={allCategories.find(option => option.id === value) || null}
                                                            onChange={(e, newValue) => onChange(newValue ? newValue.id : null)}
                                                            placeholder="Choose a category"
                                                            getOptionLabel={(option) => option ? option.name : ''}
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                    placeholder="Choose a category" />
                                                            }
                                                            InputLabelProps={{ shrink: false }}
                                                        />
                                                    )}
                                                />
                                                {/* <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    {...register("category", { required: true })}
                                                    label="Category"
                                                    defaultValue={selectedProduct?.category ? _.find(allCategories, { id: selectedProduct.category }) : undefined}
                                                    size="small"
                                                >
                                                    {allCategories.map((category, i) => 
                                                        <MenuItem value={category.id}>{category.name}</MenuItem>
                                                    )}
                                                </Select> */}
                                                {errors.category?.type === "required" && (
                                                    <span className="error-text">Category is required</span>
                                                )}
                                            </FormControl>
                                        </div>
                                        <div className='multiple-ele form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Description
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    Provide your full product description.
                                                </span>
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <div className='quill-wrapper'>
                                                    <Controller
                                                        control={control}
                                                        name="description"
                                                        rules={{ required: true }}
                                                        defaultValue={selectedProduct?.description}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <ReactQuill
                                                                className='editor'
                                                                value={value}
                                                                onChange={onChange}
                                                                theme="snow" />
                                                        )}
                                                    />
                                                    <span className="error-text" style={{ marginTop: '1.5rem' }}>
                                                        {errors.description?.type === "required" && (
                                                            'Product Description is required'
                                                        )}
                                                    </span>
                                                </div>
                                            </FormControl>
                                        </div>
                                        <div style={{ marginTop: '1.5rem' }} className='multiple-ele toggle-ele form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Feature this product?
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    This displays it as priority on the products page.
                                                </span>
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <Controller
                                                    control={control}
                                                    name="feature"
                                                    defaultValue={selectedProduct?.feature || false}
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <Switch
                                                            checked={value}
                                                            onChange={onChange}
                                                            color="secondary" />
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Product image
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    Upload/change your product image(s) here.
                                                </span>
                                            </FormLabel>
                                            <FormControl className='select-wrapper'>
                                                <ImageUploader
                                                    multiple
                                                    imageList={productImg}
                                                    setImageList={setProductImg}
                                                />
                                                <span className="error-text">
                                                    {!productImg.length && (
                                                        'Product Images are required'
                                                    )}
                                                </span>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Price
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    This is the price you will charge for this item excluding tax.
                                                    Please enter values without commas. On your website, numbers will be published with commas.
                                                </span>
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    id="name"
                                                    placeholder="Price"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={selectedProduct?.price}
                                                    {...register("price", { required: true })}
                                                    error={errors.price?.type}
                                                    helperText={errors.price?.type === "required" && (
                                                        'Price is required'
                                                    )}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">{GLOBAL_CURRENCY.symbol}</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>

                                </Grid>
                                <div className='header-wrapper'>
                                    <span className='header-text'>
                                        Shipping & Inventory Stock
                                    </span>
                                </div>
                                <Divider />
                                <div className='content-wrapper'>
                                    <span className='content-text'>
                                        <b>Inventory:</b> <br />How much stock you have for a specific item.
                                    </span><br />
                                    <span className='content-text'>
                                        <b>Delivery dimensions:</b> <br />
                                        Refers to the size in length, breadth and height as well as the weight of your product, these are used by delivery providers to determine how much the item will cost to deliver.
                                    </span>
                                </div>
                                <Grid container className='dashboard-form-container' spacing={4} wrap='wrap'>
                                    {/* <Add /> */}
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Stock Status
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    Here you choose the status that is displayed to customers.
                                                </span>
                                            </FormLabel>
                                            <FormControl fullWidth className='select-wrapper'>
                                                <InputLabel id="demo-simple-select-label" size="small">
                                                    Stock Status
                                                    <span className='required'>*</span>
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    {...register("status", { required: true })}
                                                    error={errors.status?.type}
                                                    defaultValue={selectedProduct?.status}
                                                    label="Status Status"
                                                    size="small"
                                                >
                                                    <MenuItem value={true}>Available</MenuItem>
                                                    <MenuItem value={false}>Out of Stock</MenuItem>
                                                </Select>
                                                {errors.status?.type === "required" && (
                                                    <span className="error-text">Stock status is required</span>
                                                )}
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Warehouse Address
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    Address of warehouse where inventory is available.
                                                </span>
                                            </FormLabel>
                                            <FormControl fullWidth className='select-wrapper'>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    {...register("warehouse_address", { required: true })}
                                                    error={errors.warehouse_address?.type}
                                                    defaultValue={selectedProduct && selectedProduct.warehouse_address ?
                                                        _.find(warehouseAddresses, { id: selectedProduct.warehouse_address }).id :
                                                        warehouseAddresses[0].id}
                                                    size="small"
                                                >
                                                    {warehouseAddresses.map((address, i) =>
                                                        <MenuItem key={i} value={address.id}>{_.truncate(address.warehouse_address_name, { length: 30 })}</MenuItem>
                                                    )}
                                                </Select>
                                                {errors.warehouse_address?.type === "required" && (
                                                    <span className="error-text">Warehouse address is required</span>
                                                )}
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Inventory
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    This number represents the number of items you have in stock.
                                                </span>
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    id="name"
                                                    placeholder="Inventory"
                                                    type="number"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={selectedProduct?.count}
                                                    {...register("count", { required: true })}
                                                    error={errors.count?.type}
                                                    helperText={errors.count?.type === "required" && (
                                                        'Inventory count is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    SKU
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    Add a unique code for this product to make tracing and managing inventory easier.
                                                </span>
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    id="name"
                                                    placeholder="SKU"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    {...register("sku", { required: true })}
                                                    error={errors.sku?.type}
                                                    helperText={errors.sku?.type === "required" && (
                                                        'SKU is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                    defaultValue={selectedProduct?.sku}
                                                />
                                            </FormControl>
                                            <span className='label-sec-text' style={{ fontSize: '0.7rem', fontWeight: 40 }}>
                                                (For example, if this is a white shirt in size XL with a quantity of 9 available, the SKU code could be WSXL9).
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    How many days do you take to process this product?
                                                    <span className='required'>*</span>
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    The number of days required to process this product.
                                                </span>
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    id="name"
                                                    placeholder="Delivery Days"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    {...register("order_processing_days", { required: true })}
                                                    error={errors.order_processing_days
                                                        ?.type}
                                                    helperText={errors.order_processing_days
                                                        ?.type === "required" && (
                                                            'Delivery days is required'
                                                        )}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">Day(s)</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{ shrink: false }}
                                                    defaultValue={selectedProduct?.order_processing_days}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={12} sm={12}>
                                        <Grid container>
                                            <Grid item md={3}>
                                                <div className='form-wrapper'>
                                                    <FormLabel className='form-label'>
                                                        <span className='label-text'>
                                                            Length
                                                            {user?.is_shiprazor_selected && <span className='required'>*</span>}
                                                        </span><br />
                                                        <span className='label-sec-text'>
                                                            Length of the product
                                                        </span>
                                                    </FormLabel>
                                                    <FormControl className='form-control'>
                                                        <TextField
                                                            margin="dense"
                                                            id="name"
                                                            placeholder="Length"
                                                            type="text"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            {...register("length", { required: user?.is_shiprazor_selected })}
                                                            error={errors.length?.type}
                                                            helperText={errors.length?.type === "required" && (
                                                                'Length is required'
                                                            )}
                                                            defaultValue={selectedProduct?.length}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                                            }}
                                                            InputLabelProps={{ shrink: false }}
                                                        />
                                                        <span className='divider-text'>
                                                            X
                                                        </span>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item md={3}>
                                                <div className='form-wrapper'>
                                                    <FormLabel className='form-label'>
                                                        <span className='label-text'>
                                                            Width

                                                            {user?.is_shiprazor_selected && <span className='required'>*</span>}
                                                        </span><br />
                                                        <span className='label-sec-text'>
                                                            Width of the product
                                                        </span>
                                                    </FormLabel>
                                                    <FormControl className='form-control'>
                                                        <TextField
                                                            margin="dense"
                                                            id="name"
                                                            placeholder="Width"
                                                            type="text"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            {...register("width", { required: user?.is_shiprazor_selected })}
                                                            error={errors.width?.type}
                                                            helperText={errors.width?.type === "required" && (
                                                                'Width is required'
                                                            )}
                                                            defaultValue={selectedProduct?.width}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                                            }}
                                                            InputLabelProps={{ shrink: false }}
                                                        />
                                                        <span className='divider-text'>
                                                            X
                                                        </span>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item md={3}>
                                                <div className='form-wrapper'>
                                                    <FormLabel className='form-label'>
                                                        <span className='label-text'>
                                                            Height

                                                            {user?.is_shiprazor_selected && <span className='required'>*</span>}
                                                        </span><br />
                                                        <span className='label-sec-text'>
                                                            Height of the product
                                                        </span>
                                                    </FormLabel>
                                                    <FormControl className='form-control'>
                                                        <TextField
                                                            margin="dense"
                                                            id="name"
                                                            placeholder="Height"
                                                            type="text"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            {...register("height", { required: user?.is_shiprazor_selected })}
                                                            error={errors.height?.type}
                                                            helperText={errors.height?.type === "required" && (
                                                                'Height is required'
                                                            )}
                                                            defaultValue={selectedProduct?.height}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                                            }}
                                                            InputLabelProps={{ shrink: false }}
                                                        />
                                                        <span className='divider-text'>
                                                            X
                                                        </span>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item md={3}>
                                                <div className='form-wrapper'>
                                                    <FormLabel className='form-label'>
                                                        <span className='label-text'>
                                                            Weight

                                                            {user?.is_shiprazor_selected && <span className='required'>*</span>}
                                                        </span><br />
                                                        <span className='label-sec-text'>
                                                            Product weight in Kilograms.
                                                        </span>
                                                    </FormLabel>
                                                    <FormControl className='form-control'>
                                                        <TextField
                                                            margin="dense"
                                                            id="name"
                                                            placeholder="Weight"
                                                            type="text"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            {...register("weight", { required: user?.is_shiprazor_selected })}
                                                            error={errors.weight?.type}
                                                            helperText={errors.weight?.type === "required" && (
                                                                'Weight is required'
                                                            )}
                                                            defaultValue={selectedProduct?.weight}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                                            }}
                                                            InputLabelProps={{ shrink: false }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            : ''}
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                <Button variant="contained" color="success" onClick={() => discardNewProduct(false)}>
                                    Discard
                                </Button>
                                <Button variant="contained" type="submit" color="secondary">
                                    {selectedProduct ? 'Update Product' : 'Save Product'}
                                </Button>
                            </div>
                        </CardActions>
                    </form>
                </Card>
            </Grid>
        </Grid>
    )
}

export default NewProduct
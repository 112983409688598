import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { SnackbarProvider } from "notistack";
import Routes from "routes/route";
import store from "store/store";
import { MaterialDesignContent } from "notistack";
import styled from "styled-components";
import { SnackbarConfigurator } from "./Notify";
import { ModalProvider } from "utils/context";
import { ModalProviderT } from "Context/GlobalModalContext";
import GlobalModal from "Context/GlobalModal";

// Slick carousel global styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#dd4498",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#688ad5",
  },
}));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        autoHideDuration={3000}
        maxSnack={3}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <BrowserRouter>
          <SnackbarConfigurator />
          <ModalProviderT>
          <ModalProvider>
          <Routes />
          <GlobalModal/>
          </ModalProvider>
          </ModalProviderT>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
);

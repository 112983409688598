import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
    Card,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControl,
    TextField,
    InputAdornment,
    FormControlLabel,
    Switch,
    CardActions,
    CardContent,
    InputLabel,
    Select,
    Box,
    MenuItem
} from '@mui/material';

// import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from 'notistack';
import Loader from 'components/Loader';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";

import ImageUploader from 'components/Inputs/ImageUploader';
import CustomStepper from 'components/Stepper';

import PendingIcon from '@mui/icons-material/Pending';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BackpackIcon from '@mui/icons-material/Backpack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckIcon from '@mui/icons-material/Check';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CloseIcon from '@mui/icons-material/Close';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { GLOBAL_CURRENCY } from 'constants/appConstants';

let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: GLOBAL_CURRENCY.code,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2
});

const status = {
    1: 'Order Placed',
    2: 'Order Confirmed',
    3: 'Order Packed',
    4: 'Order Shipped',
    5: 'Order Delivered',
    6: 'Return Requested',
    7: 'Return Confirmed',
    8: 'Return Completed',
    10: 'Cancelled'
}



const defaultOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[2],
    id: 2,
    statusId: 2,
    icon: <ThumbUpAltIcon />
}, {
    label: status[3],
    id: 3,
    statusId: 3,
    icon: <BackpackIcon />
}, {
    label: status[4],
    id: 4,
    statusId: 4,
    icon: <LocalShippingIcon />
}, {
    label: status[5],
    id: 5,
    statusId: 5,
    icon: <CheckIcon />
}]

const cancelledOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[2],
    id: 2,
    statusId: 2,
    icon: <ThumbUpAltIcon />
}, {
    label: status[3],
    id: 3,
    statusId: 3,
    icon: <BackpackIcon />
}, {
    label: status[10],
    id: 4,
    statusId: 10,
    icon: <CloseIcon />
}]

const returnOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[5],
    id: 2,
    statusId: 5,
    icon: <CheckIcon />
}, {
    label: status[6],
    id: 3,
    statusId: 6,
    icon: <HourglassBottomIcon />
}, {
    label: status[7],
    id: 4,
    statusId: 7,
    icon: <CheckIcon />
}, {
    label: status[8],
    id: 5,
    statusId: 8,
    icon: <AssignmentReturnIcon />
}]

const getOrderStatusCycle = (status) => {
    const defaultStatusList = [1, 2, 3, 4, 5]
    const returnStatusList = [6, 7, 8]
    const cancelStatusCycle = [9, 10]
    if (_.includes(defaultStatusList, status)) return defaultOrderCycle;
    if (_.includes(returnStatusList, status)) return returnOrderCycle;
    if (_.includes(cancelStatusCycle, status)) return cancelledOrderCycle;
}

const capitalizeFirstLetter = (str) => {
    if (!str) return ""; // Handle null or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const showName = (args) => {
    return _.compact(args).join(' ')
}

const showAddress = (address) => {
    return (
        <div className="address-wrapper">
            <span>{showName([address.first_name, address.last_name])} | {showName([address.country_code, address.phone_number])}</span><br />
            <Divider className='content-divider' />
            <span>{address.street_address}</span><br />
            <span>{address.city}</span><br />
            <span>{address.state}, {address.country} - {address.pin}</span>
        </div>
    )
}

const OrderDetails = ({
    discardUpdateOrder,
    orderId
}) => {
    const [loader, setLoader] = useState(true);
    const [allCategories, setAllCategories] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrderDetail] = useState({});
    const [orderCycle, setOrderCycle] = useState(defaultOrderCycle);

    const getOrderDetail = async (orderId) => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.GET_ORDER(orderId))
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            setOrderDetail(data)
            console.log('getOrderStatusCycle(data.delivery_status) ', getOrderStatusCycle(data.delivery_status))
            if (data.delivery_status)
                setOrderCycle(getOrderStatusCycle(data.delivery_status))
        }
        setLoader(false)
    }

    useEffect(() => {
        if (orderId) {
            getOrderDetail(orderId)
        }
    }, [orderId])

    const getActiveStep = (steps, statusId) => {
        return _.get(_.find(steps, { statusId: statusId }), 'id', 1)
    }

    const updateOrderStatus = async (status) => {
        setLoader(true)
        let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER(orderId), {
            delivery_status: status
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            getOrderDetail(orderId)
        }
    }

    const cancelOrder = async (status) => {
        setLoader(true)
        let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER(orderId), {
            delivery_status: 10
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            getOrderDetail(orderId)
        }
    }

    // const handleStatusUpdate = (event) => {
    //     updateOrderStatus(event.target.value);
    // };

    const [status1, setStatus] = useState(""); // Ensure initial state is valid

    const handleStatusUpdate = (event) => {
        updateOrderStatus(event.target.value);
    };


    return (

        <Grid container className='component-grid'>
            <Loader open={loader} />
            <Grid item xs={12}>
                <Card className='list-wrapper dashboard-card-wrapper'>
                    {!loader ?
                        <CardContent>
                            <div className='header-wrapper header-flex'>
                                <span className='header-text'>Order {`>`} {_.get(order, 'transaction_id')} </span>
                                {_.get(order, 'delivery_status') < 3 || _.get(order, 'delivery_status') == 10 ?
                                    <Button
                                        onClick={() => cancelOrder()}
                                        variant="outlined"
                                        color="error"
                                        disabled={_.get(order, 'delivery_status') == 10}
                                        size="small">
                                        {_.get(order, 'delivery_status') == 10 ? 'Cancelled' : 'Cancel Order'}
                                    </Button> : ''}
                            </div>
                            {/* <div className='header-wrapper'>
                        <span className='header-text'>Order {`>`} {_.get(order, 'order_id')} </span>
                    </div> */}
                            <Divider />
                            <div className="content-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div className="content-text" style={{ marginRight: '16px' }}> {/* Add margin for spacing */}
                                    This is where you will be creating and managing your orders.
                                </div>
                                <FormControl
                                    size="medium" // Use "medium" for a larger size
                                    sx={{
                                        minWidth: 180, // Increased width
                                        backgroundColor: 'black',  // Darker background
                                        borderRadius: '8px', // More rounded corners
                                        border: '1px solid #555', // Added a border
                                        '&:hover': {
                                            borderColor: '#777', // Lighter border on hover
                                        },
                                        '& .MuiInputBase-root': { // Style the input base
                                            height: '40px', // Increased height
                                            padding: '0 16px', // Adjust padding
                                            color: 'white', // Text color white
                                            fontSize: '18px', // Larger font size
                                            '& .MuiSelect-select': {
                                                paddingRight: '32px' //Adjust padding to show the dropdown icon
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Removed default border
                                            },
                                            '&:focus': {
                                                backgroundColor: '#444', // Slightly lighter background on focus
                                                outline: 'none' //remove outline
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'white', // Lighter gray
                                                fontSize: '24px',
                                                position: 'absolute',  // Position the *dropdown* arrow
                                                right: '20px',         // Place it on the right
                                                top: '50%',            // Vertically center it
                                                transform: 'translateY(-50%)'// Larger icon
                                            },
                                        },
                                        MenuProps: {
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#333', // Dark background for menu
                                                    color: 'white', // Text color white
                                                    '& .MuiMenuItem-root': {
                                                        fontSize: '18px',
                                                        '&:hover': {
                                                            backgroundColor: '#444', // Slightly lighter on hover
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <Select
                                        labelId="order-status-label"
                                        id="order-status"
                                        value={order.delivery_status}
                                        onChange={(e) => handleStatusUpdate(e, order)}
                                        displayEmpty
                                    >
                                        <MenuItem value={1} disabled>
                                            Order Placed
                                        </MenuItem>
                                        {/* <MenuItem value={2}>✅ Confirmed</MenuItem> */}
                                        {(order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped" || order.delivery_status_type === "Delivered") ? <MenuItem value={2} disabled>📦 Confirmed</MenuItem> : <MenuItem value={2}>📦 Confirmed</MenuItem>}
                                        {/* <MenuItem value={3}>📦 Packed</MenuItem> */}
                                        {(order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped" || order.delivery_status_type === "Delivered") ? <MenuItem value={3} disabled>📦 Packed</MenuItem> : <MenuItem value={3}>📦 Packed</MenuItem>}
                                        {/* <MenuItem value={4}>🚚 Shipped</MenuItem> */}
                                        {(order.delivery_status_type === "Shipped" || order.delivery_status_type === "Delivered") ? <MenuItem value={4} disabled>🚚 Shipped</MenuItem> : <MenuItem value={4}>🚚 Shipped</MenuItem>}
                                        {/* <MenuItem value={5}>🎉 Delivered</MenuItem> */}
                                        {order.delivery_status_type === "Delivered" ? <MenuItem value={5} disabled>🚚 Delivered</MenuItem> : <MenuItem value={5}>🚚 Delivered</MenuItem>}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='content-wrapper content-flex'>
                                <div className='status-wrapper'>
                                    <CustomStepper
                                        steps={orderCycle}
                                        activeStep={getActiveStep(orderCycle, _.get(order, "delivery_status"))}
                                        completedStep={_.get(order, "delivery_status")} />
                                </div>
                            </div>
                            <div className='content-wrapper content-flex'>
                                <Grid container className='dashboard-details-container' spacing={4} wrap='wrap'>
                                    <Grid item sm={12} className=''>
                                        <span><b>{capitalizeFirstLetter(_.get(order, "product_detail.name"))}</b></span>
                                    </Grid>
                                    {_.get(order, 'product_detail.images') && order.product_detail.images.length > 0 ?
                                        <Grid item sm={3} className='image-grid' >
                                            <div className="image-container">
                                                <img src={order.product_detail.images[0]?.image} style={{ height: "50%", width: "80%" }} />
                                            </div>
                                        </Grid>
                                        : ''}
                                    <Grid item sm={9} className='order-detail-container'>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <span><b>Transaction Id : </b>{_.get(order, 'transaction_id')}</span>
                                                <br />
                                                <span><b>Order Id : </b>{_.get(order, 'order_id')}</span>
                                                <br />
                                                <span><b>Order Status : </b>{status[_.get(order, 'delivery_status')]}</span>
                                                <br />
                                                <span><b>Ordered On : </b>{moment(order.created).format("[On] ddd[,] D MMM")}</span>
                                                <br />
                                                <span><b>Quantity : </b>{order.quantity}</span>
                                                <br />
                                                <span><b>Available Stock : </b>{order.availableStock}</span>
                                                <br />
                                                {/* <span><b>Price : </b>{GLOBAL_CURRENCY.symbol}{order.unit_price}</span> */}
                                                <span><b>Price : </b>{formatCurrency.format(order.unit_price)}</span>
                                                <br />
                                                {!_.isEmpty(order?.payment_mode) && (
                                                    <Grid item xs={12}>
                                                        <div className='payment-mode-wrapper'>
                                                            <Grid container spacing={1} alignItems="center"> {/* Align items vertically */}
                                                                <Grid item xs={6}>
                                                                    <div style={{ wordBreak: 'break-word' }}>{order.payment_mode.payment_method_name}</div> {/* Added break-word */}
                                                                </Grid>
                                                                <Grid item xs={6} textAlign="right">
                                                                    <div>{formatCurrency.format(order.quantity * order.unit_price)}</div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                )}
                                                <Divider className='content-divider' />
                                                <span><b>Total Order Price : </b>{formatCurrency.format(order.total_price)}</span>
                                                <br />
                                                <div style={{ marginBottom: "10px" }}><b>Ordered by : </b>{_.get(order, 'customer_name')}</div>
                                                <br />
                                            </Grid>
                                            <Grid>
                                                <div style={{ marginRight: "20px" }}><b>Delivery Address :</b></div>
                                            </Grid>
                                            <Grid>
                                                {showAddress(order.shipping_address)}
                                            </Grid>
                                            <Grid>
                                                <div style={{ marginTop: "30px", marginRight: "20px" }}><b>Billing Address :</b></div>
                                            </Grid>
                                            <Grid>
                                                <div style={{ marginTop: "30px" }}>{showAddress(order.billing_address)}</div>
                                                <br />
                                            </Grid>
                                            <Grid item xs={6} className='action-wrapper'>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                        : ''}
                    <CardActions className='footer-wrapper'>
                        <div className="action-wrapper">
                            <Button variant="contained" color="error" onClick={() => discardUpdateOrder(false)}>
                                Go Back
                            </Button>
                            {/* <Button variant="contained" type="submit" color="secondary">
                                {selectedProduct ? 'Update Product' : 'Save Product'}
                            </Button> */}
                        </div>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>

    )
}

export default OrderDetails;
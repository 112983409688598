import { useEffect, useState } from 'react';

import {
    Card,
    Divider,
    Grid,
    Button,
    CardContent,
    CardActions,
    FormControl,
    InputAdornment,
    FormLabel,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useForm, Controller } from "react-hook-form";

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ImageUploader from 'components/Inputs/ImageUploader';

import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';

import { countryCode } from 'constants/appData/filters';
import { GLOBAL_COUNTRY_CODE } from 'constants/appConstants';

const StoreSetup = ({ store, handleNext }) => {
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { logo, logoUrl, storeName, hostName, setLogo, contactDetail } = store;

    const { register, formState: { errors }, handleSubmit, control, getValues } = useForm();

    const updateStoreDetails = async (formData) => {
        if (!logo.length) return;

        setLoader(true);

        formData = {
            ...formData,
        }

        if (formData.whatsapp) {
            formData = {
                ...formData,
                whatsapp_country_code: formData.whatsapp_country_code.dial_code,
            }
        } else {
            delete formData['whatsapp']
            delete formData['whatsapp_country_code']
        }

        let res = await axiosInstance.post(APIRouteConstants.WEBSITE.ADD_WEB_DETAILS, formData)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
                setLoader(false);
                return;
            }
        }

        let logoFormData = new FormData();
        if (logo[0] != logoUrl) {
            logoFormData.append('logo', logo[0], logo[0]?.fileName)

            const res = await axiosInstance({
                method: 'post',
                url: APIRouteConstants.WEBSITE.WEB_LOGO_BANNER,
                data: logoFormData
            })

            if (res === false) {
                setLoader(false);
                return
            }

            let { logoData, logoResponse } = res

            if (logoResponse
                && logoResponse.status === 400
                && logoResponse.data) {
                for (let i in logoResponse.data) {
                    enqueueSnackbar(_.capitalize(i) + ': ' + logoResponse.data[i], { variant: 'error' });
                }
            }

            if (!_.isEmpty(logoData)) {
                enqueueSnackbar('Details updated successfully', { variant: 'success' });
            }
        }

        setLoader(false);
        handleNext();
    }

    return (
        <div className="setup-wrapper website-analytics-wrapper">
            <Loader open={loader} />
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='list-wrapper dashboard-card-wrapper'>
                        {!loader ?
                            <form onSubmit={handleSubmit(updateStoreDetails)}>
                                <CardContent>
                                    <div className='header-wrapper'>
                                        <span className='header-text'>Start building your store</span>
                                    </div>
                                    <Divider />
                                    <div className='content-wrapper content-flex'>
                                        <span className='content-text'>
                                            Tell us a bit about your business so we can get you up and running. To help you get started we'll give you a free sub domain.
                                        </span>
                                    </div>
                                    <Grid container className='form-grid-container dashboard-form-container' spacing={4} wrap='wrap'>
                                        {/* <Add /> */}
                                        <Grid item md={6} sm={12}>
                                            <div className='form-wrapper'>
                                                <FormLabel className='form-label'>
                                                    <span className='label-text'>
                                                        Store name
                                                        <span className='required'>*</span>
                                                    </span><br />
                                                    <span className='label-sec-text'>
                                                        This is the name we will display for your online store
                                                    </span>
                                                </FormLabel>
                                                <FormControl fullWidth className='select-wrapper'>
                                                    <Controller
                                                        control={control}
                                                        name="store_name"
                                                        rules={{ required: true }}
                                                        defaultValue={storeName}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <TextField
                                                                margin="dense"
                                                                id="name"
                                                                placeholder="Store Name"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={onChange}
                                                                value={value}
                                                                InputLabelProps={{ shrink: false }}
                                                            />
                                                        )}
                                                    />
                                                    <span className="error-text">
                                                        {errors.store_name?.type === "required" && (
                                                            'Store name is required'
                                                        )}
                                                    </span>
                                                </FormControl>
                                            </div>
                                            {/* {console.log('Get Values ', getValues(), storeName)} */}
                                            <div className='form-wrapper'>
                                                <FormLabel className='form-label'>
                                                    <span className='label-text'>
                                                        Store website address (URL) 
                                                        <span className='required'>*</span>
                                                    </span><br />
                                                    <span className='label-sec-text'>
                                                        You can change your free domain name later.
                                                    </span>
                                                </FormLabel>
                                                <FormControl fullWidth className='select-wrapper'>
                                                    <Controller
                                                        control={control}
                                                        name="host_name"
                                                        rules={{ required: true }}
                                                        defaultValue={hostName}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <TextField
                                                                margin="dense"
                                                                id="name"
                                                                placeholder="Host Name"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={onChange}
                                                                value={value}
                                                                InputLabelProps={{ shrink: false }}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">.webbieshop.com</InputAdornment>,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <span className="error-text">
                                                        {errors.host_name?.type === "required" && (
                                                            'Host name is required'
                                                        )}
                                                    </span>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <div className='form-wrapper'>
                                                <FormLabel className='form-label'>
                                                    <span className='label-text'>
                                                        Upload your logo
                                                        <span className='required'>*</span>
                                                    </span><br />
                                                    <span className='label-sec-text'>
                                                        This is the logo we will display for your online store.
                                                    </span>
                                                </FormLabel>
                                                <FormControl fullWidth className='select-wrapper'>
                                                    <ImageUploader
                                                        imageList={logo}
                                                        setImageList={setLogo}
                                                        id="logo"
                                                        name="logo"
                                                    />
                                                    <span className="error-text">
                                                        {!logo.length && (
                                                            'Logo is required'
                                                        )}
                                                    </span>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <div className='multi-form-ele form-wrapper'>
                                                <FormLabel className='form-label'>
                                                    <span className='label-text'>
                                                        Add social media links below (optional)
                                                    </span><br />
                                                    <span className='label-sec-text'>
                                                        Add social media links for customers to reach you via social media.
                                                    </span>
                                                </FormLabel>
                                                <FormControl className='form-control'>
                                                    <TextField
                                                        margin="dense"
                                                        id="name"
                                                        placeholder="Facebook id"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        defaultValue={contactDetail?.facebook}
                                                        {...register("facebook")}
                                                        size="small"
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">
                                                                <FacebookIcon className='adornment-icon' /> <span>facebook.com/</span>
                                                            </InputAdornment>,
                                                        }}
                                                        InputLabelProps={{ shrink: false }}
                                                    />
                                                </FormControl>
                                                <FormControl className='form-control'>
                                                    <TextField
                                                        margin="dense"
                                                        id="name"
                                                        placeholder="Instagram id"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        defaultValue={contactDetail?.instagram}
                                                        {...register("instagram")}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">
                                                                <InstagramIcon className='adornment-icon' /> <span>instagram.com/</span>
                                                            </InputAdornment>,
                                                        }}
                                                        InputLabelProps={{ shrink: false }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth className='select-wrapper'>
                                                    <Controller
                                                        control={control}
                                                        name="whatsapp"
                                                        rules={{ maxLength: 10, minLength: 10 }}
                                                        defaultValue={contactDetail?.whatsapp}
                                                        render={({ field: { onChange, value } }) => (
                                                            <TextField
                                                                margin="dense"
                                                                id="name"
                                                                placeholder="Whatsapp Number"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={onChange}
                                                                value={value}
                                                                InputLabelProps={{ shrink: false }}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">
                                                                        <WhatsAppIcon className='adornment-icon' />
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            className="select-input"
                                                                            label="Status"
                                                                            size="small"
                                                                            fullWidth
                                                                            sx={{
                                                                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                                                {
                                                                                    border: 0,
                                                                                },
                                                                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                                                {
                                                                                    border: 0,
                                                                                },
                                                                            }}
                                                                            renderValue={(selected) => (
                                                                                <div className="dial-code-wrapper">
                                                                                    <img src={selected.flag} />
                                                                                    {'+' + selected.dial_code}
                                                                                </div>
                                                                            )}
                                                                            defaultValue={GLOBAL_COUNTRY_CODE}
                                                                            {...register("whatsapp_country_code", { required: true })}
                                                                            error={getValues()['whatsapp'] && errors.whatsapp_country_code?.type}
                                                                        >
                                                                            {countryCode.map((code, index) =>
                                                                                <MenuItem className="dial-code-wrapper" value={code}>
                                                                                    <img src={code.flag} />
                                                                                    {'+' + code.dial_code}
                                                                                </MenuItem>
                                                                            )}
                                                                        </Select>
                                                                    </InputAdornment>,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <span className="error-text">
                                                        {(errors.whatsapp?.type === "maxLength" && (
                                                            'Whatsapp number must be 10 digit in length'
                                                        ) || errors.whatsapp?.type === "minLength" && (
                                                            'Whatsapp number must be 10 digit in length'
                                                        ))}
                                                        {getValues()['whatsapp'] && errors.whatsapp_country_code?.type === "required" && (
                                                            'Country code is required'
                                                        )}
                                                    </span>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions className='footer-wrapper'>
                                    <div className="action-wrapper">
                                        {/* <Button variant="outlined">
                                    Skip
                                </Button> */}
                                        <Button type="submit" variant='contained'>
                                            Next Step {'>'}
                                        </Button>
                                    </div>
                                </CardActions>
                            </form> : ''}
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default StoreSetup
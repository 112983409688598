import React, { useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import axiosInstance from "configs/axiosConfig";
import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { Link } from "react-router-dom";
import { APIRouteConstants } from "constants/routeConstants";
import { useSnackbar } from "notistack";
import { CardMedia, Grid } from "@mui/material";
import logo from "assets/footer/positivessl_trust_seal_lg_222x54.png";
function Footer() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [emailError, setEmailError] = useState("");

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // console.log("first", validateEmail(e.target.value));
    if (email === "" || email.trim() === "" || !emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    subscribe(e.target.value);
  };

  const subscribe = async () => {
    setEmailError("");
    setLoading(true);
    let res = await axiosInstance.post(APIRouteConstants.LANDING.SUBSCRIBE, {
      email: email,
    });
    if (res === false) {
      setLoading(false);
      return
    }

    let { data, response } = res;
    if (data.status === 200) {
      setLoading(false);
      setEmailError("");
      setEmail("");
      enqueueSnackbar("You've subscribed successfully.", {
        variant: "success",
      });
    } else {
      setLoading(false);
      enqueueSnackbar("Invalid email address", { variant: "error" });
    }
  };
  // const validateEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  return (
    <footer className="footer">
      <div className="footer__div">
        <div className="footer__div__top">
          <div className="footer__div__top__aboutus">
            <div>About Us:</div>
            <div>
              Webbieshop is from the stable of The Venture Studio who are the
              venture builders and spin out products that solve market problems.
            </div>
            <div>
              <a href="https://www.instagram.com/thewebbieshop" target="_blank">
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/webbieshop"
                target="_blank"
              >
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>
          <div className="footer__div__top__right">
            <ul className="footer__div__top__right__notifications">
              <li className="footer__div__top__right__notifications__heading">
                COMPANY
              </li>
              <li>
                <Link
                  to={"/about"}
                  style={{ whiteSpace: "nowrap" }}
                  onClick={scrollToTop}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link to={"/pricing"} onClick={scrollToTop}>
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  to={"/privacy-policy"}
                  style={{ whiteSpace: "nowrap" }}
                  onClick={scrollToTop}
                >
                  Privacy-policy
                </Link>
              </li>
              <li>
                <Link
                  style={{ whiteSpace: "nowrap" }}
                  to={"/termsandcondition"}
                  onClick={scrollToTop}
                >
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer__div__top__right">
            <ul className="footer__div__top__right__notifications">
              <li className="footer__div__top__right__notifications__heading">
                INFORMATION
              </li>
              {/* <li>
                <Link to={"/partners"}>Become our Partner</Link>
              </li> */}
              <li>
                <Link to={"/shipping"} onClick={scrollToTop}>
                  Shipping
                </Link>
              </li>
              <li>
                <Link to={"/payments"} onClick={scrollToTop}>
                  Payments
                </Link>
              </li>
              <li>
                <Link to={"/faq"} onClick={scrollToTop}>
                  FAQs
                </Link>
              </li>
              <li>
              <Link to={"/contact"} onClick={scrollToTop}>Contact</Link>
                
              </li>
            </ul>
          </div>
          <div className="footer__div__top__right">
            <form
              className="footer__div__top__right__form"
              onSubmit={handleEmailChange}
            >
              <input
                type="text"
                name="email"
                // id="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <p style={{ color: "#DD4498", fontSize: "10px" }}>
                  {emailError}
                </p>
              )}
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={15} style={{ color: "#dd4498" }} />
                </div>
              ) : (
                <button type="submit">Subscribe</button>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="footer__div__line"></div>
      <div className="footer__div__bottom">
        <div className="footer__div__bottom__left">
          <span>
            <Link to={"/privacy-policy"} onClick={scrollToTop}>
            Privacy policy
            </Link>
          </span>
          <span>|</span>
          <span>
            <Link to={"/termsandcondition"} onClick={scrollToTop}>
              Terms & Conditions{" "}
            </Link>
          </span>
        </div>
        <div className="footer__div__bottom__right">
          <Grid textAlign={'end'} container spacing={0} sx={{m:0, justifyContent:{xs:"",sm:"flex-end",lf:"flex-end",md:"flex-end"}}}  alignItems="center">
          <CardMedia component="img" src={logo} alt="logo" sx={{width:"50%", mb:{lg:1,md:1,sm:1}}} />
          </Grid>
          Copyright © 2024 Webbieshop. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  CardActions,
  Typography,
  CardMedia,
  Box,
  TextField,
  InputAdornment,
  FormLabel,
  FormControl,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from '@mui/material';
import _, { size } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import Carousel from 'components/Carousel';
import { useSelector } from 'react-redux';
import { updateUserDetail } from 'utils/auth';
import { GLOBAL_CURRENCY } from 'constants/appConstants';
import ImageUploader from 'components/Inputs/ImageUploader';
import { useSnackbar } from 'notistack';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PendingIcon from '@mui/icons-material/Pending';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OTP from 'components/Inputs/OTP';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Whastapp from 'components/Whatsapp/Whastapp';
import SimpleModal from '../Modal/DocsModal';
import { useModal } from 'utils/context';
// import OrderDetails from './OrderDetails';
import OrderDetails from '../Store/Orders/OrderDetails';
import TableWithAction from 'components/Tables/TableWithAction';
import { orderStatus } from 'constants/appData/filters';
import SearchInput from 'components/Search';
import moment from 'moment';
import { fontWeight, width } from '@mui/system';

let formatCurrency = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'ZAR',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 2
});

const Home = ({ breadcrumbs }) => {

  const [expandedItems, setExpandedItems] = useState({});
  const toggleExpand = (orderId) => {
    setExpandedItems({
      ...expandedItems,
      [orderId]: !expandedItems[orderId],
    });
  };

  const getCardStyle = (index) => {
    const colors = ["#fff", "#f0f0f0", "#e0e0e0", "#d0d0d0", "#c0c0c0"]; // Array of background colors
    const colorIndex = index % colors.length; // Cycle through the colors
    return {
      background: colors[colorIndex],
      borderRadius: "12px",
      padding: "10px",
    };
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Order Failed":
        return "orange";
      case "Cancelled":
        return "red";
      case "Order Placed":
        return "#008000";
      default:
        return "#87CEEB";
    }
  };


  // const [loader, setLoader] = useState(false);
  const [allOrders, setOrderList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [status, setOrderStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [orderId, selectOrder] = useState('');
  let shouldShowSimpleModal;
  const { open, handleClose, handleOpen } = useModal();
  const [loader, setLoader] = useState(true);
  const [salesSummary, setSalesSummary] = useState({});
  const orders = [
    { id: 1235, time: "3 mins ago", items: "2 x Aloo Parantha, 3 x French Fries, 1 x Burger", status: "Pending", color: "#E53935" },
    { id: 1236, time: "3 mins ago", items: "2 x Aloo Parantha, 3 x French Fries, 1 x Burger", status: "Pending", color: "#E53935" },
    { id: 2235, time: "2 mins ago", items: "2 x Aloo Parantha, 3 x French Fries, 1 x Burger", status: "Processing", color: "#FB8C00" },
    { id: 3235, time: "2 mins ago", items: "2 x Aloo Parantha, 3 x French Fries, 1 x Burger", status: "Accepted", color: "#43A047" },
  ];
  const [bestProduct, setBestProductList] = useState([]);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const loggedInUser = useSelector(state => state.userDetails);
  const { user } = loggedInUser;
  const [logoUrl, setLogoUrl] = useState('');
  const [logo, setLogo] = useState([]);
  const [checklist, setCheckListStatus] = useState({});
  const [checkListComplete, setIfCheckListComplete] = useState(false);
  const [shippingAmount, setshippingAmount] = useState(null);
  // console.log("checklist",checklist)

  const getSalesReport = async () => {
    let res = await axiosInstance.get(APIRouteConstants.STORE.SALES_ANALYTICS)
    if (res === false) {
      setLoader(false);
      return
    }

    let { data, response } = res;

    if (data) {
      setSalesSummary(data)
    }
  }


  const getShippingAmount = async () => {
    try {
      setLoader(true);
      let res = await axiosInstance.get(
        APIRouteConstants.SHIPRAZOR.GET_SHIPPING_AMOUNT
      );

      if (res === false) {
        setLoader(false);
        return
      }

      let { data, response } = res;
      if (data && res.status) {
        setshippingAmount(data?.amount);
        sessionStorage.setItem("shippng_amount", JSON.stringify(data?.amount))
      } else {
        enqueueSnackbar(response?.data?.error, { variant: "error" });
      }
      setLoader(false);
    } catch (err) {
      console.log("getShippingAmount", err);
    }
  };


  const getBestSellingProducts = async () => {
    let res = await axiosInstance.get(APIRouteConstants.STORE.TOP_COLLECTION)
    let { data, response } = res;

    if (res === false) {
      setLoader(false);
      return
    }

    if (data) {
      setBestProductList(data)
    }
    setLoader(false)
  }

  console.log("user", user)
  const loadWebsiteDetail = async () => {
    if (!_.isEmpty(user)) {
      var webSetup = false
      // if (user.storeName && user.hostName && user?.logo !== null && user?.logo !== undefined) webSetup = true
      if (user.storeName && user.hostName) webSetup = true

      if (user.logo) {
        setLogo([user.logo])
        setLogoUrl(user.logo)
      }
      setCheckListStatus({
        webSetup: webSetup,
        // shiprazorSetup: user?.shiprazor_key_exist ? true : (user.own_shipping_selected && shippingAmount !== null) ? true : false,
        shiprazorSetup: user?.is_shiprazor_selected ? true : (user.own_shipping_selected && user?.shipping_amount !== null) ? true : false,
        warehouseSetup: user.warehouse_exist,
        categoryExist: user.is_category,
        productExist: user.is_product,
        paymentSetup: user.is_payment_selected
      })
    }
    setLoader(false)
  }
  // console.log("selected", user.is_shiprazor_selected, user?.own_shipping_selected)
  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (user.isPublished) {
        getSalesReport()
        getBestSellingProducts()
      } else {
        let setup = true
        if (!user.storeName || !user.hostName) setup = false
        // else if (!user.shiprazor_key_exist) setup = false
        else if (!user.is_shiprazor_selected && !user.own_shipping_selected) setup = false
        else if (!user.warehouse_exist) setup = false
        else if (!user.is_category) setup = false
        else if (!user.is_product) setup = false
        else if (!user.is_product) setup = false
        else if (!user.is_payment_selected) setup = false
        setIfCheckListComplete(setup)
      }
      loadWebsiteDetail()
    }
    if (user?.own_shipping_selected) {
      getShippingAmount()
    }
  }, [user])

  useEffect(() => {
    handleNext();
  }, [])

  const getUser = async () => {
    let res = await axiosInstance.get(APIRouteConstants.AUTH.ME)

    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data && data.success) {
      const user = data.data;
      updateUserDetail(user)
    }
    setLoader(false);
  }

  const handlePublish = async () => {
    setLoader(true)
    let res = await axiosInstance.put(APIRouteConstants.WEBSITE.PUBLISH_WEBSITE, {
      "is_Published": true
    })

    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (!_.isEmpty(data)) {
      getUser();
    }
  }

  const handleStoreUpdate = async () => {
    let res = await axiosInstance.post(APIRouteConstants.WEBSITE.UPDATE_STORE)
    if (res === false) {
      setLoader(false);
      return
    }
    let { data } = res;

    if (data) {
      getUser()
    }
  }

  const handleNext = async () => {
    setLoader(true)
    await getUser()
    setLoader(false)
  };


  shouldShowSimpleModal = localStorage.getItem("ischecklistopened")




  const getOrderList = async () => {
    setLoader(true)
    let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_ORDERS, {
      params: {
        page: pageCount + 1,
        search: searchString,
        status: status
      },
    })
    if (res === false) {
      setLoader(false);
      return
    }
    let { data } = res;

    if (data) {
      setOrderList(data.results);
      setTotalCount(data.count);
      setSearchString("")
    }
    setLoader(false)
  }

  useEffect(() => {
    getOrderList()
  }, [status, pageCount])

  const handleChangePage = (e, page) => {
    setPageCount(page)
  }

  const handleEdit = (order) => {
    selectOrder(order.id)
  }

  const handleStatusUpdate = (event, orderid) => {
    updateOrderStatus(event.target.value, orderid);
  };

  const getOrderDetail = async (orderId) => {
    let res = await axiosInstance.get(APIRouteConstants.STORE.GET_ORDER(orderId))
    if (res === false) {
      setLoader(false);
      return
    }
    let { data } = res;

    if (data) {
      console.log("oderdetail", data)
      // console.log(data)
      // setOrderDetail(data)
      // console.log('getOrderStatusCycle(data.delivery_status) ', getOrderStatusCycle(data.delivery_status))
      // if (data.delivery_status)
      // setOrderCycle(getOrderStatusCycle(data.delivery_status))
    }
    setLoader(false)
  }

  const updateOrderStatus = async (status, orderId) => {
    console.log("cfghjklskjhgfcxcfghj", status, orderId.id)
    setLoader(true)
    let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER(orderId.id), {
      delivery_status: status
    })
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data) {
      getOrderList(orderId.id)
    }
  }

  console.log("shouldShowSimpleModal", open)
  return (
    <div className="home-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs
          list={breadcrumbs}
          name={user?.isPublished ? "Dashboard" : "Complete your store setup"}
        />
      </div>
      {/* <SimpleModal open={open} handleClose={handleClose}  /> */}
      <Grid
        container
        className="component-grid"
        spacing={3}
        sx={{ gridAutoRows: "1fr" }}
      >
        {user?.isPublished ?
          <Card className="setup-card dashboard-card-wrapper" style={{ height: "100%", width: "100%" }}>
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">Order List</span>
                {/* ... filter and search section */}

                {allOrders.length === 0 ? (
                  <div className='content-wrapper content-flex content-flex-center'>
                    <NoData />
                    <div className='detail-action-wrapper content-flex content-flex-center'>
                      <span className='detail-sec-text'>No order details yet</span>
                    </div>
                  </div>
                ) : (

                  // <Grid container spacing={2} justifyContent="space-around">
                  //   {/* Outer Grid Container */}

                  //   {/* New Orders Column */}
                  //   {/* <Grid item xs={12} sm={6} md={4} lg={3}> */}
                  //   {/* Responsive Grid Item */}
                  //   <Card style={{ backgroundColor: "#D3D3D3", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       New Orders
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Order Placed").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Order Placed")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab1"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>

                  //   {/* Processing Column */}
                  //   <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       Processing
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab2"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>

                  //   {/* Delivered Column */}
                  //   <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       Delivered / Collected
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Delivered").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Delivered")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab3"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>
                  //   {/* </Grid> */}
                  // </Grid>







                  // #  previous

                  // <Grid container spacing={2} justifyContent="space-around">
                  //   {/* Outer Grid Container */}

                  //   {/* New Orders Column */}
                  //   {/* <Grid item xs={12} sm={6} md={4} lg={3}> */}
                  //   {/* Responsive Grid Item */}
                  //   <Card style={{ backgroundColor: "#D3D3D3", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       New Orders
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Order Placed").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Order Placed")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab1"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>

                  //   {/* Processing Column */}
                  //   <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       Processing
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Confirmed" || order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab2"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>

                  //   {/* Delivered Column */}
                  //   <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", paddingLeft: "10px", paddingRight: "10px", borderRadius:"25px" }}>
                  //     <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                  //       Delivered / Collected
                  //     </Grid>
                  //     {allOrders.filter((order) => order.delivery_status_type === "Delivered").length === 0 ? ( // Check if there are no orders
                  //       <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                  //         <NoData />
                  //         <div className='detail-action-wrapper content-flex content-flex-center'>
                  //           <span className='detail-sec-text'>No order details yet</span>
                  //         </div>
                  //       </div>
                  //     ) : ( // If there are orders, display them
                  //       allOrders
                  //         .filter((order) => order.delivery_status_type === "Delivered")
                  //         .map((order) => (
                  //           <CardContent key={order.order_id}> {/* Add a key prop */}
                  //             <div style={{ backgroundColor: "grey.300" }}>
                  //               <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab3"} toggleExpand={toggleExpand} />
                  //             </div>
                  //           </CardContent>
                  //         ))
                  //     )}
                  //   </Card>
                  //   {/* </Grid> */}
                  // </Grid>




                  <Grid container spacing={2} justifyContent="space-between">
                    {/* New Orders Column */}
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Card style={{ backgroundColor: "#D3D3D3", marginTop: "40px", padding: "10px", borderRadius: "25px" }}>
                        <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                          New Orders
                        </Grid>
                        {allOrders.filter(order => order.delivery_status_type === "Order Placed").length === 0 ? (
                          <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                            <NoData />
                            <div className='detail-action-wrapper content-flex content-flex-center'>
                              <span className='detail-sec-text'>No order details yet</span>
                            </div>
                          </div>
                        ) : (
                          allOrders.filter(order => order.delivery_status_type === "Order Placed")
                            .map(order => (
                              <CardContent key={order.order_id}>
                                <div style={{ backgroundColor: "grey.300" }}>
                                  <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab1"} toggleExpand={toggleExpand} />
                                </div>
                              </CardContent>
                            ))
                        )}
                      </Card>
                    </Grid>

                    {/* Processing Column */}
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", padding: "10px", borderRadius: "25px" }}>
                        <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px" }}>
                          Processing
                        </Grid>
                        {allOrders.filter(order => ["Confirmed", "Packed", "Shipped"].includes(order.delivery_status_type)).length === 0 ? (
                          <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                            <NoData />
                            <div className='detail-action-wrapper content-flex content-flex-center'>
                              <span className='detail-sec-text'>No order details yet</span>
                            </div>
                          </div>
                        ) : (
                          allOrders.filter(order => ["Confirmed", "Packed", "Shipped"].includes(order.delivery_status_type))
                            .map(order => (
                              <CardContent key={order.order_id}>
                                <div style={{ backgroundColor: "grey.300" }}>
                                  <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab2"} toggleExpand={toggleExpand} />
                                </div>
                              </CardContent>
                            ))
                        )}
                      </Card>
                    </Grid>

                    {/* Delivered Column */}
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Card style={{ backgroundColor: "#E0E0E0", marginTop: "40px", padding: "10px", borderRadius: "25px" }}>
                        <Grid variant="h6" style={{ fontSize: "1rem", marginTop: "20px", marginLeft: "20px", marginBottom: "10px", marginRight: "20px" }}>
                          Delivered / Collected
                        </Grid>
                        {allOrders.filter(order => order.delivery_status_type === "Delivered").length === 0 ? (
                          <div className='content-wrapper content-flex content-flex-center' style={{ marginTop: "50px" }}>
                            <NoData />
                            <div className='detail-action-wrapper content-flex content-flex-center'>
                              <span className='detail-sec-text'>No order details yet</span>
                            </div>
                          </div>
                        ) : (
                          allOrders.filter(order => order.delivery_status_type === "Delivered")
                            .map(order => (
                              <CardContent key={order.order_id}>
                                <div style={{ backgroundColor: "grey.300" }}>
                                  <OrderCard order={order} handleStatusUpdate={handleStatusUpdate} value={"Tab3"} toggleExpand={toggleExpand} />
                                </div>
                              </CardContent>
                            ))
                        )}
                      </Card>
                    </Grid>
                  </Grid>

                )}
              </div>
            </CardContent>
          </Card>
          :
          <Grid item xs={12} md={12}>
            <Card className="setup-card dashboard-card-wrapper">
              <CardContent className="stepper-wrapper">
                <CheckListItem
                  title={"Set up your website and storefront [Required]"}
                  isCollapsible={true}
                  isMandatory={true}
                  // isDisabled={checklist.webSetup}
                  description={
                    "Tell us a bit about your business so we can get you up and running. To help you get started we'll give you a free sub domain."
                  }
                  status={checklist.webSetup}
                >
                  {!loader ? (
                    <StoreDetail
                      user={user}
                      store={{
                        name: user?.storeName,
                        logo: logo,
                        logoUrl: logoUrl,
                        host: user?.hostName,
                      }}
                      loader={loader}
                      setLogo={setLogo}
                      status={checklist.webSetup}
                      setLoader={setLoader}
                      handleUpdate={handleStoreUpdate}
                    />
                  ) : (
                    ""
                  )}
                </CheckListItem>
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  redirectTo={dashboardRouteConstants.ABOUT}
                  title={"Review and Update about your business [Optional]"}
                  description={
                    "Congrats! You have successfully setup your Store and domain. Please provide more details about your business to go ahead."
                  }
                  status={true}
                  isOptional={true}
                  isDisabled={!checklist.webSetup}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  redirectTo={dashboardRouteConstants.SHIPPING}
                  isMandatory={true}
                  isDisabled={!checklist.webSetup}
                  title={"Please choose your shipping method:"}
                  description={
                    "Would you like to use Shiprazor (recommended) or arrange your own shipping?"
                  }
                  status={checklist.shiprazorSetup}
                >
                  {/* <ShipRazorSetup
                                                    refreshUser={getUser} 
                                                    setLoader={setLoader} /> */}
                </CheckListItem>
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  redirectTo={dashboardRouteConstants.DELIVERY}
                  title={"Choose how to deliver your goods"}
                  status={checklist.warehouseSetup}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  title={"Add categories and products"}
                  redirectTo={
                    !checklist.categoryExist
                      ? dashboardRouteConstants.CATEGORIES
                      : dashboardRouteConstants.PRODUCTS
                  }
                  status={checklist.categoryExist && checklist.productExist}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  title={"Set up payment options"}
                  redirectTo={dashboardRouteConstants.PAYMENTS}
                  status={checklist.paymentSetup}
                />
              </CardContent>
            </Card>
          </Grid>
        }


        <Grid item xs={12} md={6}>
          <Card className="overlay-wrapper dashboard-card-wrapper">
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">Your Dashboard</span>
              </div>
              <Divider />
              <div className="header-wrapper">
                <span className="header-text">Total Sales</span>
                <br />
                <span className="header-sec-text">
                  Overall customer purchases.
                </span>
              </div>
              <div className="content-wrapper content-flex content-flex-right">
                <span className="content-text-right figure-text">
                  {/* {salesSummary['total_sale'] ? GLOBAL_CURRENCY.symbol + salesSummary['total_sale'].toFixed(2) : GLOBAL_CURRENCY.symbol + '0.00'} */}
                  {/* {new Intl.NumberFormat({style:"currency", currency:"ZAR", minimumFractionDigits: 2, currencyDisplay: 'narrowSymbol'}).format(salesSummary['total_sale'] || 0)} */}
                  {formatCurrency.format(salesSummary["total_sale"] || 0)}
                </span>
              </div>
              <Divider />
              <div className="header-wrapper">
                <span className="header-text">Today Sales</span>
                <br />
                <span className="header-sec-text">
                  Total sale by customer purchases.
                </span>
              </div>
              <div className="content-wrapper content-flex content-flex-right">
                <span className="content-text-right figure-text">
                  {/* {salesSummary['today_sale'] ? GLOBAL_CURRENCY.symbol + salesSummary['today_sale'].toFixed(2) : GLOBAL_CURRENCY.symbol + '0.00'} */}
                  {formatCurrency.format(salesSummary["today_sale"] || 0)}
                </span>
              </div>
            </CardContent>
            {user?.isPublished ? (
              ""
            ) : (
              <div className="overlay-card">
                <NoData className="statement-svg" />
                <span className="statement">
                  This content will get available
                  <br /> after publishing website
                </span>
              </div>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className="overlay-wrapper dashboard-card-wrapper">
            {user?.isPublished ? (
              <CardContent>
                <div className="header-wrapper">
                  <span className="header-text">View your website</span>
                </div>
                <Divider />
                <div className="header-wrapper">
                  <span className="header-sec-text">
                    Click here to view your website
                  </span>
                </div>
                <div className="content-wrapper content-flex content-flex-right">
                  <Button
                    onClick={() => {
                      window
                        .open(
                          `https://${user.websiteUrl}.webbieshop.com`,
                          "_blank"
                        )
                        .focus();
                    }}
                    variant="contained"
                  >
                    View your Website
                  </Button>
                </div>
              </CardContent>
            ) : (
              <CardContent>
                <div className="header-wrapper">
                  <span className="header-text">Publish your website</span>
                </div>
                <Divider />
                <div className="header-wrapper">
                  <span className="header-sec-text">
                    Click here to publish your website
                  </span>
                </div>
                <div className="content-wrapper content-flex content-flex-right">
                  <Button onClick={handlePublish} variant="contained">
                    Publish your Website
                  </Button>
                </div>
              </CardContent>
            )}
            {!checkListComplete && !user?.isPublished ? (
              <div className="overlay-card">
                <NoData
                  className="statement-svg"
                  style={{ height: "3rem" }}
                />
                <span className="statement">
                  This content will get available
                  <br /> after publishing website
                </span>
              </div>
            ) : (
              ""
            )}
          </Card>
        </Grid>
      </Grid>
      <Grid container className="component-grid">
        <Grid item xs={12}>
          <Card className="overlay-wrapper dashboard-card-wrapper">
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">Best Selling Products</span>
              </div>
              <Divider />

              {bestProduct.length > 0 ? (
                <div className="content-wrapper content-flex content-flex-center">
                  <Carousel>
                    {bestProduct.map((product) => (
                      <DetailCard product={product} navigate={navigate} />
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="content-wrapper content-flex content-flex-center">
                  <NoData />
                  <span className="content-text">No results found</span>
                </div>
              )}
            </CardContent>
            {user?.isPublished ? (
              ""
            ) : (
              <div className="overlay-card">
                <NoData className="statement-svg" />
                <span className="statement">
                  This content will get available
                  <br /> after publishing website
                </span>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>
      {/* <Whastapp mobile={user?.mobile_number} /> */}
    </div>
  );
}

const CheckListItem = (props) => {
  const { title, description, status, isCollapsible, isMandatory, redirectTo, isDisabled, isOptional } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(!isDisabled && !status)
  }, [isDisabled, status])

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box className="checklist-wrapper">
      <span className='title'></span>
      <ListItemButton
        disabled={isDisabled}
        onClick={() => {
          if (isCollapsible) handleClick()
          else navigate(redirectTo)
        }}>
        <ListItemIcon>
          {status ?
            <CheckIcon color="success" /> :
            isOptional ?
              <PendingIcon style={{ color: '#ffc400' }} /> :
              isMandatory ?
                <PriorityHighIcon color='error' /> :
                <AnnouncementIcon color='warning' />}
        </ListItemIcon>
        {/* <ListItemText primary={title} /> */}
        <ListItemText>
          <span className='title'>
            {title}
          </span><br />
          <span className='description'>
            {description}
          </span>
        </ListItemText>
        {isCollapsible ?
          open ? <ExpandLess /> : <ExpandMore /> :
          <IconButton
            aria-label="delete"
            className='icon-btn-directional'
            size="small">
            <ArrowForwardIosIcon />
          </IconButton>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </Box>
  )
}

const DetailCard = ({ product, navigate }) => (
  <Card className="slider-card content-card">
    <CardMedia
      sx={{ height: '17rem' }}
      image={product?.product_images[0]?.image}
      title={product?.name}
    />
    <CardContent align="left">
      {/* <Typography gutterBottom component="span">
                {product.name ? _.truncate(product.name, { length: 30 }) : ''}
            </Typography> */}
      <CardContent align="left">
        <Typography
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            minHeight: "10vh",
            fontSize: 18,
            alignItems: "baseline"

          }}
          gutterBottom component="span">
          {_.truncate(_.trim(product.name), { length: 30 })}
        </Typography>
        <Typography
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
          }}
          gutterBottom component="span">
          {_.truncate(_.trim(product.name), { length: 40 })}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatCurrency.format(product?.price || 0)}
        </Typography>
      </CardContent>
    </CardContent>
    <CardActions align="center">
      <Button
        color="success"
        size="small"
        onClick={() => {
          navigate(dashboardRouteConstants.PRODUCTS, { state: { productId: product.id } })
        }}
        variant="outlined">View</Button>
    </CardActions>
  </Card>
)

const StoreDetail = ({ setLoader, store, setLogo, handleUpdate, status, loader }) => {
  const { formState: { errors }, handleSubmit, control, getValues } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const updateStoreDetails = async (formData) => {
    const { logo, logoUrl } = store;
    // if (!logo.length) return;

    setLoader(true);

    let res = await axiosInstance.post(APIRouteConstants.WEBSITE.ADD_WEB_DETAILS, formData)
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data && data.success) {
      console.log("response12", data.message)
      enqueueSnackbar(data.message, { variant: 'success' });
      handleUpdate()
    }

    if (response
      && response.status === 400
      && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
        // setLoader(false);
        // return;
      }
    }

    let logoFormData = new FormData();
    if (logo[0] === undefined) {
      setLoader(false);
      return
    }
    if (logo[0] != logoUrl) {
      logoFormData.append('logo', logo[0], logo[0].fileName)

      const { logoData, logoResponse } = await axiosInstance({
        method: 'post',
        url: APIRouteConstants.WEBSITE.WEB_LOGO_BANNER,
        data: logoFormData
      })

      if (logoResponse
        && logoResponse.status === 400
        && logoResponse.data) {
        for (let i in logoResponse.data) {
          enqueueSnackbar(_.capitalize(i) + ': ' + logoResponse.data[i], { variant: 'error' });
          // setLoader(false);
          // return;
        }
      }

      if (!_.isEmpty(logoData)) {
        enqueueSnackbar('Details updated successfully', { variant: 'success' });
      }
    }
    handleUpdate()
  }

  console.log("response", store)
  return (
    <form
      className="step-form-container"
      onSubmit={handleSubmit(updateStoreDetails)}
    >
      <Grid
        container
        className="form-grid-container dashboard-form-container"
        spacing={4}
        wrap="wrap"
      >
        <Grid item md={12} sm={12}>
          <div className="form-wrapper">
            <FormLabel className="form-label">
              <span className="label-text">
                Store name
                <span className="required">*</span>
              </span>
              <br />
              <span className="label-sec-text">
                This is the name we will display for your online store
              </span>
            </FormLabel>
            <FormControl fullWidth className="select-wrapper">
              <Controller
                control={control}
                name="store_name"
                rules={{
                  required: true,
                  maxLength: {
                    value: 16,
                    message: "Store name must be 16 characters or fewer to maintain proper website display.",
                  },
                }}
                defaultValue={store.name}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="Store Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    value={value}
                    disabled={status}
                    InputLabelProps={{ shrink: false }}
                    error={!!errors.store_name}
                    helperText={errors.store_name?.message}
                  />
                )}
              />
              <span className="error-text">
                {errors.store_name?.type === "required" &&
                  "Store name is required"}
              </span>
            </FormControl>
          </div>
          <div className="form-wrapper">
            <FormLabel className="form-label">
              <span className="label-text">
                Store website address (URL)
                <span className="required">*</span>
              </span>
              <br />
              <span className="label-sec-text">
                You can change your free domain name later.
              </span>
            </FormLabel>
            <FormControl fullWidth className="select-wrapper">
              <Controller
                control={control}
                name="host_name"
                rules={{ required: true }}
                defaultValue={store.host}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="Host Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    disabled={status}
                    value={value}
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          .webbieshop.com
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <span className="error-text">
                {errors.host_name?.type === "required" &&
                  "Host name is required"}
              </span>
            </FormControl>
          </div>
        </Grid>
        <Grid item md={12} sm={12}>
          <div className="form-wrapper">
            <FormLabel className="form-label">
              <span className="label-text">
                Upload your logo (Optional)
                {/* <span className='required'>*</span> */}
              </span>
              <br />
              <span className="label-sec-text">
                This is the logo we will display for your online store.
              </span>
              <br />
              <span className="label-sec-text">
                Recommended size: 1024 x 1024 pixels
              </span>
            </FormLabel>
            <FormControl fullWidth className="select-wrapper">

              <ImageUploader
                imageList={store.logo}
                message={"Recommended size: 1024 x 1024 pixels"}
                setImageList={setLogo}
                id="logo"
                name="logo"
                isDisabled={status}
              />

              {/* <span className="error-text">
                                {!store.logo.length && (
                                    'Logo is required'
                                )}
                            </span> */}
            </FormControl>
          </div>
        </Grid>
        {!status ? (
          <Grid item md={12} sm={12}>
            <Button type="submit" variant="contained">
              Save and Continue {">"}
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </form>
  );
}


const OrderCard = ({ order, handleStatusUpdate, value }) => {
  // const [isExpanded, setIsExpanded] = useState(false); // State for expansion

  // const toggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  // };
  // function capitalizeFirstLetter(str) {
  //   if (!str) return str; // Handle null or undefined
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // }

  // function timeAgo(timestamp) {
  //   if (!timestamp) return "No date available"; // Handle missing timestamp

  //   const now = moment();
  //   const then = moment(timestamp);
  //   const diff = now.diff(then);

  //   if (diff < 60000) {
  //     return "just now";
  //   } else if (diff < 3600000) {
  //     const minutes = Math.round(diff / 60000); // Round to nearest minute
  //     return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  //   } else if (diff < 86400000) {
  //     const hours = Math.round(diff / 3600000); // Round to nearest hour
  //     return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  //   } else if (diff < 2592000000) { // Less than 30 days (a month)
  //     const days = Math.round(diff / 86400000); // Round to nearest day
  //     return `${days} day${days > 1 ? 's' : ''} ago`;
  //   } else if (diff < 31536000000) { // Less than a year
  //     const months = Math.round(diff / 2592000000); // Round to nearest month
  //     return `${months} month${months > 1 ? 's' : ''} ago`;
  //   } else {
  //     return moment(timestamp).format("ddd, D MMM YYYY"); // Format for older dates
  //   }
  // }

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function timeAgo(timestamp) {
    if (!timestamp) return "No date available";

    const now = moment();
    const then = moment(timestamp);
    const diff = now.diff(then);

    if (diff < 60000) {
      return "just now";
    } else if (diff < 3600000) {
      const minutes = Math.round(diff / 60000);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diff < 86400000) {
      const hours = Math.round(diff / 3600000);
      return `${hours} hr${hours > 1 ? 's' : ''} ago`;
    } else if (diff < 2592000000) {
      const days = Math.round(diff / 86400000);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (diff < 31536000000) {
      const months = Math.round(diff / 2592000000);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      return moment(timestamp).format("ddd, D MMM YYYY");
    }
  }

  const selectStyles = {
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    "& .MuiSvgIcon-root": { color: "white" },
    "& .MuiSelect-select": { color: "white" },
  };

  const menuItemStyles = {
    fontSize: "16px",
    color: "white",
    backgroundColor: "black",
    "&:hover": { backgroundColor: "#333" },
  };

  return (
    <Grid>
      <div style={{ paddingBottom: "15px" }}>
        <Card style={{
          paddingTop: "10px", paddingBottom: "10px",
          // borderRadius:"25px" 
        }}>
          {/* <Grid variant="h6" style={{ fontWeight: "bold", fontSize: "17px", marginRight: "20px", marginLeft: "20px"}}>
                {order.customer_name}
              </Grid> */}
          {/* ... (Card Content remains the same) ... */}
          <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", marginBottom: "5px", marginRight: "5px" }}>
            <Grid variant="h6" style={{ fontSize: "0.8rem", }}>
              • {capitalizeFirstLetter(order.customer_name)}
            </Grid>
            <Grid style={{ fontSize: "0.7rem", color: "gray" }}>
              {timeAgo(order.created)}
            </Grid>
          </CardContent>
          <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            {/* <img
              src={order.product_image} 
              style={{
                width: '20%',
                // borderRadius: '40%', // Make it a circle
                objectFit: 'cover', // Prevent image distortion
                border: '2px solid white',  // Optional: Add a white border
              }}
            /> */}

            <div >
              {/* {order.customer_name && (
                  <span style={{ marginBottom: "5px", fontSize: "0.8rem", }}>Customer Name : <span>{order.customer_name}</span></span>
                )} */}
              {/* <Grid variant="h6" style={{ fontSize: "0.8rem", marginRight: "10px" }}>
                • {capitalizeFirstLetter(order.customer_name)}
              </Grid> */}
              {order.product_name && (
                <Grid variant="h6" style={{ fontSize: "0.8rem", marginRight: "20px" }}>
                  # {capitalizeFirstLetter(order.product_name)}
                </Grid>
              )}
              {/* {order.quantity && (
                <span style={{ marginBottom: "5px", fontSize: "0.8rem", color: "gray" }}>Qty : <span style={{ color: "black" }}>{order.quantity}</span></span>
              )} */}
              {/* <Grid variant="h6" style={{ fontWeight: "bold", fontSize: "17px", marginRight: "20px" }}>
                #{order.order_id}
              </Grid> */}
            </div>
            <div style={{ textAlign: "right", }}>
              {/* <Grid style={{ fontSize: "0.7rem", }}>
                {moment(order.created).format(" ddd[,] D MMM YYYY")}
              </Grid> */}
              {/* <Grid style={{ fontSize: "0.8rem", color: "gray" }}>
                {timeAgo(order.created)}
              </Grid> */}
              <Grid variant="body2" color="textSecondary" marginTop="5px">{order.time}</Grid>
              {/* <Button
              style={{
                backgroundColor: "grey",
                color: "white",
                marginTop: "5px",
                fontSize: "13px",
                width: "140px"
              }}
            >
              {order.delivery_status_type}
            </Button> */}
              {/* // # previous */}
              {value === "Tab1" && (
                <Grid item xs={12} sm={6}> {/* Use Grid for responsiveness */}
                  {/* <FormControl fullWidth size="small" sx={{ backgroundColor: "black", color: "white" }}> */}
                  <FormControl size="small" fullWidth sx={{
                    width: { xs: '28vw', sm: '9vw', md: '9vw' }, // Responsive width
                    // minWidth: '150px', // Minimum width
                    backgroundColor: "black",
                    color: "white"
                  }}>
                    <Select
                      labelId="order-status-label"
                      id="order-status"
                      value={order.delivery_status}
                      onChange={(e) => handleStatusUpdate(e, order)}
                      displayEmpty
                      sx={{
                        fontSize: "0.8rem",
                        color: "white",
                        "& .MuiSvgIcon-root": { color: "white" },
                        "& .MuiSelect-select": { color: "white" },
                      }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenuItem-root": {
                            fontSize: "0.8rem",
                            color: "white",
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "#333" },
                          },
                          "& .MuiList-root": {
                            backgroundColor: "black",
                          },
                        },
                      }}
                    >
                      <MenuItem value={1} disabled style={{ fontSize: "15px" }}>
                        Order Placed
                      </MenuItem>
                      <MenuItem value={2}>✅ Confirmed</MenuItem>
                      <MenuItem value={3}>📦 Packed</MenuItem>
                      <MenuItem value={4}>🚚 Shipped</MenuItem>
                      <MenuItem value={5}>🎉 Delivered</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {value === "Tab2" && (
                <Grid item xs={12} sm={6}>
                  {/* <FormControl size="small" sx={{ width: '150px', textAlign: 'center' }}> */}
                  <FormControl size="small" fullWidth sx={{
                    width: { xs: '28vw', sm: '9vw', md: '9vw' }, // Responsive width
                    // minWidth: '150px', // Minimum width
                    backgroundColor: "black",
                    color: "white"
                  }}>
                    <Select
                      labelId="order-status-label"
                      id="order-status"
                      value={order.delivery_status}
                      onChange={(e) => handleStatusUpdate(e, order)}
                      displayEmpty
                      sx={{
                        fontSize: "0.8rem",
                        color: "white",
                        "& .MuiSvgIcon-root": { color: "white" },
                        "& .MuiSelect-select": { color: "white" },
                      }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenuItem-root": {
                            fontSize: "0.8rem",
                            color: "white",
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "#333" },
                          },
                          "& .MuiList-root": {
                            backgroundColor: "black",
                          },
                        },
                      }}
                    >

                      <MenuItem value={2} disabled>✅ Confirmed</MenuItem>
                      {(order.delivery_status_type === "Packed" || order.delivery_status_type === "Shipped") ? <MenuItem value={3} disabled>📦 Packed</MenuItem> : <MenuItem value={3}>📦 Packed</MenuItem>}
                      {order.delivery_status_type === "Shipped" ? <MenuItem value={4} disabled>🚚 Shipped</MenuItem> : <MenuItem value={4}>🚚 Shipped</MenuItem>}
                      <MenuItem value={5}>🎉 Delivered</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {value === "Tab3" && (
                // <FormControl size="small" sx={{ width: '150px', textAlign: 'center' }}>
                <Grid item xs={12} sm={6}>
                   <FormControl size="small" fullWidth sx={{
                    width: { xs: '28vw', sm: '9vw', md: '9vw' }, // Responsive width
                    // minWidth: '150px', // Minimum width
                    backgroundColor: "black",
                    color: "white"
                  }}>
                    <Select
                      labelId="order-status-label"
                      id="order-status"
                      value={order.delivery_status}
                      onChange={(e) => handleStatusUpdate(e, order)}
                      displayEmpty
                      sx={{
                        fontSize: "0.8rem",
                        color: "white",
                        "& .MuiSvgIcon-root": { color: "white" },
                        "& .MuiSelect-select": { color: "white" },
                      }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenuItem-root": {
                            fontSize: "0.8rem",
                            color: "white",
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "#333" },
                          },
                          "& .MuiList-root": {
                            backgroundColor: "black",
                          },
                        },
                      }}
                    >

                      <MenuItem value={5} disabled>🎉 Delivered</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>)}


            </div>
          </CardContent>
          {/* {order.quantity && (
            <span style={{ marginBottom: "5px", fontSize: "0.8rem", marginLeft: "20px" }}>Quantity : <span>{order.quantity}</span></span>
          )} */}
          <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", marginBottom: "5px", marginRight: "5px", marginTop: "5px", }}>
            {order.quantity && (
              <span style={{ fontSize: "0.7rem", color: "gray" }}>Qty : <span style={{ color: "black" }}>{order.quantity}</span></span>
            )}
            <div style={{ textAlign: "right", }}>
              <span
                style={{
                  color: "Gray",
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: "0.7rem"
                }}
                onClick={toggleExpand}
              >
                {isExpanded ? "View Less" : "View More"}
              </span>
            </div>
          </CardContent>
          {isExpanded && ( // Use isExpanded for conditional rendering
            <Grid item xs={12} style={{ marginTop: "10px", marginRight: "20px", color: "black" }}>
              <div style={{ display: "flex", flexDirection: "column", marginLeft: "15px" }}>
                {/* {order.quantity && (
                  <span style={{ marginBottom: "5px", fontSize: "0.8rem", }}>Quantity : <span>{order.quantity}</span></span>
                )} */}
                {order.order_id && (
                  <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>Order Id : <span style={{ color: "black" }}>{order.order_id}</span></span>
                )}
                {order.total_price && (
                  <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>Total Price : <span style={{ color: "black" }}>{order.total_price}</span></span>
                )}
                {order.payment_mode.payment_method_name && (
                  <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>Mode Of Payment : <span style={{ color: "black" }}>{order.payment_mode.payment_method_name}</span></span>
                )}
                {order.customer_name && (
                  // <span style={{ marginBottom: "5px", fontSize: "0.8rem", }}>Customer Name : <span>{order.customer_name}</span></span>
                  <Grid style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>
                    <span style={{ marginBottom: "5px", fontSize: "0.7rem", color: "gray" }}>Date :<span style={{ color: "black" }}>{moment(order.created).format(" ddd[,] D MMM YYYY, h:mm A")}</span></span>
                  </Grid>
                )}
                {/* <Grid style={{ fontWeight: "bold", fontSize: "15px" }}>
                {moment(order.created).format(" ddd[,] D MMM YYYY")}
              </Grid> */}
                {/* ... more details as needed ... */}
              </div>
            </Grid>
          )}
          <div style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "1px",
            backgroundColor: "gray.300",
          }} />
        </Card>
      </div>
    </Grid>
  );
};


export default Home;





import UpdateStore from "views/Dashboard/Store/UpdateStore";

export const authRouteConstants = {
  HOME: "/",
  CONATCT: "/contact",
  PRICING: "/pricing",
  ABOUT: "/about",
  FAQ:"/faq",
  PRIVACYPOLICYV1: "/privacy-policy",
  PRIVACYPOLICY: "/returnandrefunds",
  TERMSANDCONDISTION: "/termsandcondition",
  PAYMENTS: "/payments",
  PARTNERS: "/partners",
  SHIPPING: "/shipping",
  AUTH: "/auth",
  LOGIN: "/auth/login",
  SIGNUP: "/auth/signup",
  FORGOTPASSWORD: "/auth/forgot-password",
  LOGOUT: "/auth/logout",
};

export const dashboardRouteConstants = {
  INITIAL_SETUP: "/initial-setup",
  HOME: "/dashboard",
  tutorial:"/dashboard/tutorial",
  WEBSITE: "/website",
  WEB_ANALYTICS: "/website/analytics",
  WEB_TEMPLATE: "/website/templates",
  CUSTOM_WEB_TEMPLATE: "/website/customtemplates",
  DOMAIN: "/website/domain",
  CONTACT: "/website/contact",
  ABOUT: "/website/about",
  WEB_TERMS_AND_CONDITION: "/website/terms-conditions",
  STORE_ANALYTICS: "/store/analytics",
  PRODUCTS: "/store/products",
  CATEGORIES: "/store/categories",
  UpdateStore: "/store/updatestore",
  CUSTOMERS: "/reports/customers",
  ORDERS: "/reports/orders",
  VOUCHERS: "/marketing/vouchers",
  PAYMENTS: "/store/payments",
  SHIPPING: "/store/shipping",
  DELIVERY: "/store/delivery",
  REVIEWS: "/store/reviews",
  ACC_DETAIL: "/account/details",
  BILLING_DETAIL: "/account/billing",
  MY_STORE: "/account/store",
  PRIVACY_POLICY:"/website/returnandrefunds",
  REFUND_POLICY:"/website/refundpolicy"
};

export const APIRouteConstants = {
  AUTH: {
    SIGNUP: "/accounts/merchant-create",
    LOGIN: "/accounts/merchant-login",
    ME: "/accounts/me/merchant",
    FORGOTPASSEMAIL: "/core/email_for_forgotpassword/",
    OTPVALIDATECHANGEPASSWORD: "/core/verify-otp-resetpassword/",
    RESETPASSWORD: "/core/reset-password/",
    EMAILOTPVERIFY: "/accounts/verify-otp-for-email/",
  },
  WEBSITE: {
    WEB_DETAILS: "/accounts/add/website-detail",
    WEB_LOGO_BANNER: "/accounts/logo-banner/",
    WEB_DOMAIN: "/accounts/website-domain/",
    WEB_CONTACTS: "/accounts/contact-detail/",
    WEB_ABOUT_US: "/accounts/about-us-website/",
    WEB_TERMS_AND_CONDITION: "/accounts/terms-and-conditions/",
    ADD_WEB_DETAILS: "/accounts/add/website-detail",
    UPDATE_STORE: "/accounts/merchant/store-setup/complete",
    PUBLISH_WEBSITE: "/accounts/IsPublished-update/",
    SPECIAL_SALE:"/accounts/update_special_sale/",
    UPLOAD_CUSTOM_IMAGE:"/accounts/upload-images-and-text/",
    COMMUNITY:"/accounts/promo-text-update/",
    PRIVACY_POLICY:"/accounts/privacy_policy/",
  },
  SHIPRAZOR: {
    SR_ACCOUNT: "/shiprazor/check-shiprazor-account/",
    VERIFY_OTP: "/shiprazor/user-otp-verify-api/",
    ADD_VENDORE_SHIPRAZOR:"/shiprazor/update-shiprazor-vendor-api-key/",
    ADD_API_KEY: "/shiprazor/store-shiprazor-api-key/",
    GET_ADDRESSES: "/shiprazor/shiprazor-pickup-addresses",
    GET_SHIPRAZOR_STATUS: "/accounts/get-shiprazor-status/",
    UPDATE_SHIPRAZOR_STATUS: "/accounts/shiprazor-status-update/",
    UPDATE_OWNSHIPPING_STATUS: "/accounts/update-ownshipping-status/",
    GET_SHIPPING_AMOUNT: "/accounts/get-shipping-amount/",
    ADD_SHIPPING_AMOUNT: "/accounts/add-shipping-amount/",
  },
  STORE: {
    SALES_ANALYTICS: "/transaction/sale-summary/",
    TOP_COLLECTION: "/product/best-selling-product-list/",
    ALL_PRODUCTS: "/product/merchant/products/",
    ADD_PRODUCT: "/product/merchant/products/",
    GET_PRODUCT: (id) => `/product/merchant-product-update/${id}`,
    UPDATE_PRODUCT: (id) => `/product/merchant-product-update/${id}`,
    SEARCH_CATEGORIES: "/core/all_product_categories_without_pagination/",
    ALL_CATEGORIES: "/core/all-category-list/",
    ADD_CATEGORY: "/product/product-categories/",
    ALL_CUSTOMERS: "/customer/customer-list/",
    ALL_ORDERS: "/core/merchant_orders/",
    GET_ORDER: (id) => `/accounts/order/${id}/`,
    UPDATE_ORDER: (id) => `/accounts/order/update_status/${id}/`,
    ALL_VOUCHERS: "/core/vouchers-list/",
    ADD_VOUCHER: "/accounts/create-voucher/",
    UPDATE_VOUCHER: (id) => `/accounts/vouchers/${id}/`,
    SEND_OTP_VOUCHER: "/accounts/send-otp-for-voucher",
    ALL_PAYMENT_MODES: "/accounts/payment-methods/",
    UPDATE_PAYMENT_MODE: (id) => `/accounts/select-payment-mode/${id}/`,
    GET_PEACH_CREDENTIAL: "/accounts/peachcredentials/",
    GET_STICITT_CREDENTIAL: "/sticitt_pay/update-sticitt-creds/",
    SET_PEACH_CREDENTIAL: "/accounts/peachcredentials/",
    SET_STICITT_CREDENTIAL: "/sticitt_pay/update-sticitt-creds/",
    ALL_LOGISTIC_OPTIONS: "/core/logistic-service-details/",
    // UPDATE_DELIVERY_OPTION: "",
    GET_DELIVERY_LOCATION: "/accounts/add/merchant-address",
    UPDATE_DELIVERY_LOCATION: "/accounts/add/merchant-address",
    ALL_REVIEWS: "/core/reviews/",
    UPLOAD_BULK_CATEGORIES: "/product/bulk-upload-categories/",
    DELETE_CATEGORIY:(id) => `/product/delete-category-&-childrens/${id}`,
    UPDATE_CATEGORY: (id) => `/product/update-category/${id}`,
    DELETE_PRODUCT: (id) => `/product/delete-product/${id}`,
    UPLOAD_BULK_PRODUCTS: "/product/bulk-upload-products/",
  },
  ACCOUNT: {
    ALL_PLANS: "/core/subscription-list",
    UPDATE_PLAN: "/accounts/add/merchant-subscription",
    ALL_INVOICES: "",
  },
  LANDING: {
    SUBSCRIBE: "/core/subscribe/",
    FEEDBACK: "/core/feedback/",
  },
};

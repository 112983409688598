import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  FormLabel,
  FormControl,
  TextField,
  InputAdornment,
  Button,
  CardActions,
  Select,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";
import Loader from "components/Loader";
import { countryCode } from "constants/appData/filters";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { GLOBAL_COUNTRY_CODE } from "constants/appConstants";

const Contact = ({ breadcrumbs }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      country_code: GLOBAL_COUNTRY_CODE,
      whatsapp_country_code: GLOBAL_COUNTRY_CODE,
      whatsapp_status: false,
    },
  });

  const whatsappValue = watch("whatsapp", "");
  const whatsappstatus = watch("whatsapp_status", "");
  const whatsappcountrycode = watch("whatsapp_country_code", "");
  const [contact, setContactDetails] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [updatestep, setUpdatestep] = useState(0);

  const getWebContacts = async () => {
    setLoader(true);
    let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_CONTACTS);
    let { data } = res;

    if (!_.isEmpty(data)) {
      setContactDetails(data);
      const updated_whatsapp_country_code = countryCode.find(
        (a) => a.dial_code == data?.whatsapp_country_code
      );
      const updated_country_code = countryCode.find(
        (a) => a.dial_code == data?.country_code
      );
      reset({
        country_code: data?.country_code
          ? updated_country_code
          : GLOBAL_COUNTRY_CODE,
        whatsapp_country_code: data?.whatsapp_country_code
          ? updated_whatsapp_country_code
          : GLOBAL_COUNTRY_CODE,
        whatsapp_status: data?.whatsapp_status,
      });
    }

    setLoader(false);
  };

  useEffect(() => {
    getWebContacts();
  }, [updatestep]);

  const updateWebContacts = async (formData) => {
    setUpdatestep(0);
    if (formData.mobile_number) {
      formData = {
        ...formData,
        country_code: formData.country_code.dial_code,
      };
    } else {
      delete formData["mobile_number"];
      delete formData["country_code"];
    }
    if (formData.whatsapp) {
      formData = {
        ...formData,
        whatsapp_status: whatsappstatus,
        whatsapp_country_code: formData?.whatsapp_country_code?.dial_code,
      };
    } else {
      delete formData["whatsapp"];
      delete formData["whatsapp_status"];
      delete formData["whatsapp_country_code"];
    }

    setLoader(true);

    let res = await axiosInstance.post(
      APIRouteConstants.WEBSITE.WEB_CONTACTS,
      formData
    );
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }

    if (data) {
      enqueueSnackbar("Contact details updated successfully", {
        variant: "success",
      });
      setUpdatestep(1);
    }

    setLoader(false);
  };

  const getColor = () => {
    return whatsappstatus ? "#688AD5" : "#DD4498";
  };

  return (
    <div className="store-analytics-wrapper website-analytics-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs list={breadcrumbs} name={"Contact details"} />
      </div>
      <Grid container className="component-grid">
        <Grid item xs={12}>
          {!loader && !_.isEmpty(contact) ? (
            <form onSubmit={handleSubmit(updateWebContacts)}>
              <Card className="dashboard-card-wrapper">
                <CardContent>
                  <div className="header-wrapper">
                    <span className="header-text">Contact details</span>
                  </div>
                  <Divider />
                  <div className="content-wrapper">
                    <span className="content-text">
                      Let your customers know how they can connect with you.
                      Please share your contact information as well as your
                      social media links.
                    </span>
                  </div>
                  <Grid
                    container
                    className="form-grid-container dashboard-form-container"
                    spacing={4}
                    wrap="wrap"
                  >
                    {/* <Add /> */}
                    <Grid item md={6} sm={12} xs={12}>
                      <div className="form-wrapper">
                        <FormLabel className="form-label">
                          <span className="label-text">
                            Business email address
                          </span>
                          <br />
                          <span className="label-sec-text">
                            Add an email address for customers to contact you.
                          </span>
                        </FormLabel>
                        <FormControl className="form-control">
                          <TextField
                            margin="dense"
                            placeholder="Business Email"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputLabelProps={{ shrink: false }}
                            defaultValue={contact?.email_address}
                            {...register("email_address", {
                              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                            })}
                            error={errors.email_address?.type}
                            helperText={
                              errors.email_address?.type === "pattern" &&
                              "Email is invalid"
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-wrapper">
                        <FormLabel className="form-label">
                          <span className="label-text">
                            Business phone number
                          </span>
                          <br />
                          <span className="label-sec-text">
                            Add a phone number for customers to contact you.
                          </span>
                        </FormLabel>
                        <FormControl fullWidth className="select-wrapper">
                          <Controller
                            control={control}
                            name="mobile_number"
                            rules={{ maxLength: 10, minLength: 9 }}
                            defaultValue={contact?.mobile_number}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                margin="dense"
                                id="name"
                                placeholder="Business Number"
                                type="number"
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={onChange}
                                value={value}
                                InputLabelProps={{ shrink: false }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className="select-input"
                                        label="Status"
                                        size="small"
                                        fullWidth
                                        sx={{
                                          ".MuiOutlinedInput-notchedOutline": {
                                            border: 0,
                                          },
                                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: 0,
                                            },
                                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: 0,
                                            },
                                        }}
                                        renderValue={(selected) => (
                                          <div className="dial-code-wrapper">
                                            <img src={selected.flag} />
                                            {"+" + selected.dial_code}
                                          </div>
                                        )}
                                        defaultValue={
                                          contact.country_code
                                            ? _.find(countryCode, {
                                                dial_code: contact.country_code,
                                              })
                                            : GLOBAL_COUNTRY_CODE
                                        }
                                        {...register("country_code", {
                                          required: true,
                                        })}
                                        error={
                                          getValues()["mobile_number"] &&
                                          errors.country_code?.type
                                        }
                                      >
                                        {countryCode.map((code, index) => (
                                          <MenuItem
                                            className="dial-code-wrapper"
                                            value={code}
                                          >
                                            <img src={code.flag} />
                                            {"+" + code.dial_code}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          <span className="error-text">
                            {(errors.mobile_number?.type === "minLength" &&
                              "Mobile number must be 9 or more digit in length") ||
                              (errors.mobile_number?.type === "maxLength" &&
                                "Mobile number must be 10 or less in length")}
                            {getValues()["mobile_number"] &&
                              errors.country_code?.type === "required" &&
                              "Country code is required"}
                          </span>
                        </FormControl>
                      </div>
                      <div className="form-wrapper">
                        <FormLabel className="form-label">
                          <span className="label-text">
                            Store or business address
                          </span>
                          <br />
                          <Typography className="label-sec-text">
                            Add Business Hours{" "}
                            <Typography component="span" sx={{ fontSize: "0.6rem" }}>
                              (Let your customers know when you're available! Add your work timings to ensure they can reach you during your business hours.)
                            </Typography>{" "}
                            <Typography component="span" sx={{ fontSize: "0.6rem", color: "grey" }}>
                              - Optional
                            </Typography>
                          </Typography>

                        </FormLabel>
                        <FormControl className="form-control">
                          <div className="quill-wrapper">
                            <Controller
                              control={control}
                              name="business_address"
                              defaultValue={contact?.business_address}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <ReactQuill
                                  className="editor"
                                  value={value}
                                  onChange={onChange}
                                  theme="snow"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <div className="multi-form-ele form-wrapper">
                        <FormLabel className="form-label">
                          <span className="label-text">
                            Update social profiles (optional)
                          </span>
                          <br />
                          <span className="label-sec-text">
                            Design and update a social profile for your Shop
                            page.
                          </span>
                        </FormLabel>
                        <FormControl className="form-control">
                          <TextField
                            margin="dense"
                            id="name"
                            placeholder="Facebook Id"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                            {...register("facebook")}
                            defaultValue={contact?.facebook}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FacebookIcon className="adornment-icon" />{" "}
                                  <span>facebook.com/</span>
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{ shrink: false }}
                          />
                        </FormControl>
                        <FormControl className="form-control">
                          <TextField
                            margin="dense"
                            id="name"
                            placeholder="Instagram Id"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                            {...register("instagram")}
                            defaultValue={contact?.instagram}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <InstagramIcon className="adornment-icon" />{" "}
                                  <span>instagram.com/</span>
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{ shrink: false }}
                          />
                        </FormControl>
                        <FormControl className="form-control">
                          <TextField
                            margin="dense"
                            id="name"
                            placeholder="Linkedin Id"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                            {...register("linkedIn")}
                            defaultValue={contact?.linkedIn}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LinkedInIcon className="adornment-icon" />{" "}
                                  <span>linkedin.com/</span>
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{ shrink: false }}
                          />
                        </FormControl>
                        <FormControl className="form-control">
                          <TextField
                            margin="dense"
                            id="name"
                            placeholder="Twitter Id"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                            {...register("twitter")}
                            defaultValue={contact?.twitter}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TwitterIcon className="adornment-icon" />{" "}
                                  <span>twitter.com/</span>
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{ shrink: false }}
                          />
                        </FormControl>
                        <FormControl fullWidth className="select-wrapper">
                          <Grid className="whatsapp_switch_container" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <Typography className="label-sec-text">
                              Show WhatsApp icon on website to users so that
                              they can contact you{" "}
                            </Typography>
                            <Controller
                              control={control}
                              name="whatsapp_status"
                              defaultValue={contact.whatsapp_status}
                              render={({ field: { onChange, value } }) => {
                                // console.log(
                                //   "first1234",
                                //   value,
                                //   contact.whatsapp_status
                                // );
                                return (
                                  <>
                                  <Grid display={"flex"} alignItems={'baseline'} pl={2}  >
                                  <Typography className="label-sec-text" sx={{fontWeight:"700", color:"#688ad5"}}>OFF</Typography>
                                    <Switch
                                      checked={value}
                                      onChange={onChange}
                                      // disabled={whatsappValue == ""}
                                      sx={{
                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                          color: "white",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                          {
                                            backgroundColor: getColor(),
                                          },
                                        "& .MuiSwitch-switchBase": {
                                          color: "white",
                                        },
                                        "& .MuiSwitch-switchBase + .MuiSwitch-track":
                                          {
                                            backgroundColor: getColor(),
                                          },
                                      }}
                                    />
                                    
                                  <Typography className="label-sec-text" sx={{fontWeight:"700", color:"#CD1479"}}>ON</Typography>
                                  </Grid>
                                  </>
                                );
                              }}
                            />
                          </Grid>

                          <Controller
                            control={control}
                            name="whatsapp"
                            rules={{ maxLength: 10, minLength: 10 }}
                            defaultValue={contact?.whatsapp}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                margin="dense"
                                id="name"
                                placeholder="Whatsapp Number"
                                type="text"
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={onChange}
                                value={value}
                                InputLabelProps={{ shrink: false }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <WhatsAppIcon className="adornment-icon" />
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className="select-input"
                                        label="Status"
                                        size="small"
                                        fullWidth
                                        sx={{
                                          ".MuiOutlinedInput-notchedOutline": {
                                            border: 0,
                                          },
                                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: 0,
                                            },
                                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: 0,
                                            },
                                        }}
                                        renderValue={(selected) => {
                                          return (
                                            <div className="dial-code-wrapper">
                                              <img src={selected.flag} />
                                              {"+" + selected.dial_code}
                                            </div>
                                          );
                                        }}
                                        defaultValue={
                                          contact.whatsapp_country_code
                                            ? _.find(countryCode, {
                                                dial_code:
                                                  contact.whatsapp_country_code,
                                              })
                                            : GLOBAL_COUNTRY_CODE
                                        }
                                        {...register("whatsapp_country_code", {
                                          required: true,
                                        })}
                                        error={
                                          getValues()["whatsapp"] &&
                                          errors.whatsapp_country_code?.type
                                        }
                                      >
                                        {countryCode.map((code, index) => (
                                          <MenuItem
                                            className="dial-code-wrapper"
                                            value={code}
                                          >
                                            <img src={code.flag} />
                                            {"+" + code.dial_code}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          <span className="error-text">
                            {(errors.whatsapp?.type === "maxLength" &&
                              "Whatsapp number must be 10 digit in length") ||
                              (errors.whatsapp?.type === "minLength" &&
                                "Whatsapp number must be 10 digit in length")}
                            {getValues()["whatsapp"] &&
                              errors.whatsapp_country_code?.type ===
                                "required" &&
                              "Country code is required"}
                          </span>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className="footer-wrapper">
                  <div className="action-wrapper">
                    <Button type="submit" variant="contained" color="secondary">
                      Update
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </form>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;

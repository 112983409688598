import React, { useState, useMemo } from 'react';
import {
    Card,
    Divider,
    Grid,
    Button,
    CardContent,
    TextField,
    useMediaQuery,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CustomBreadcrumbs from "components/Breadcrumbs";
import Loader from 'components/Loader';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const QRCode = ({ breadcrumbs }) => {
    const [loader, setLoader] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const { enqueueSnackbar } = useSnackbar();

    const [quantity, setQuantity] = useState('');
    const [number, setNumber] = useState('');
    const [qrPurpose, setQrPurpose] = useState('');
    const [singleQRGenerated, setSingleQRGenerated] = useState(false);
    const [multipleQRGenerated, setMultipleQRGenerated] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [isEditable, setIsEditable] = useState(true);

    const createSingleQR = async () => {
        setLoader(true);
        let res = await axiosInstance.post(APIRouteConstants.STORE.CREATEQR, {});
        if (res === false) {
            setLoader(false);
            return;
        }
        let { data, response } = res;

        if (response && response.status === 400 && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('QR Code Generated Successfully', { variant: 'success' });
            setSingleQRGenerated(true);
        }
        setLoader(false);
    };

    const createMultipleQR = async () => {
        setLoader(true);
        let res = await axiosInstance.post(APIRouteConstants.STORE.CREATEQR, {
            scenario: "BULK",
            quantity: quantity,
            number: number,
            qr_purpose: qrPurpose,
        });
        if (res === false) {
            setLoader(false);
            return;
        }
        let { data, response } = res;

        if (response && response.status === 400 && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('QR Code Generated Successfully', { variant: 'success' });
            setMultipleQRGenerated(true);
            setIsEditable(false);
        }
        setLoader(false);
    };

    const singleQRDownload = async () => {
        setDownloading(true);
        try {
            const res = await axiosInstance.get(APIRouteConstants.STORE.QRDOWNLOAD, {
                responseType: 'blob',
            });

            if (res && res.data) {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'qrcode.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                enqueueSnackbar('QR Code PDF Downloaded Successfully', { variant: 'success' });
            } else {
                enqueueSnackbar('Failed to download QR Code PDF', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error downloading QR Code PDF:', error);
            enqueueSnackbar('Error downloading QR Code PDF', { variant: 'error' });
        }
        setDownloading(false);
    };

    const multipleQRDownload = async () => {
        setDownloading(true);
        try {
            const res = await axiosInstance.get(APIRouteConstants.STORE.QRDOWNLOAD, {
                responseType: 'blob',
                params: {
                    scenario: "BULK",
                },
            });

            if (res && res.data) {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'document.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                enqueueSnackbar('PDF Downloaded Successfully', { variant: 'success' });
            } else {
                enqueueSnackbar('Failed to download PDF', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error downloading QR Code PDF:', error);
            enqueueSnackbar('Error downloading QR Code PDF', { variant: 'error' });
        }
        setDownloading(false);
    };

    const isMultipleQRButtonDisabled = useMemo(() => {
        return !(quantity && number && qrPurpose);
    }, [quantity, number, qrPurpose]);

    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
        setMultipleQRGenerated(false);
    };

    const handleNumberChange = (e) => {
        setNumber(e.target.value);
        setMultipleQRGenerated(false);
    };

    const handleQrPurposeChange = (e) => {
        setQrPurpose(e.target.value);
        setMultipleQRGenerated(false);
    };

    const handleEditClick = () => {
        setIsEditable(true);
    };

    return (
        <div className="store-analytics-wrapper website-analytics-wrapper">
            <Loader open={loader || downloading} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"QR Code"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    {/* Main Container Box */}
                    <Card className='list-wrapper dashboard-card-wrapper'>
                        <CardContent>
                            {/* Heading and Content */}
                            <Typography variant="h6" gutterBottom style={{ marginTop: 25 }}>
                                QR Code Generation
                            </Typography>

                            <Divider style={{ margin: '20px 0' }} />
                            <Typography variant="body2" gutterBottom>
                                Generate QR codes for various purposes. Use single QR code for one-time use or generate multiple QR codes for batch processing.
                            </Typography>
                            {/* Single QR Code Box */}
                            <Box sx={{ backgroundColor: 'rgba(100, 100, 100, 0.1)', p: 2, mb: 2 }} style={{ marginTop: 30 }}>
                                <div className='header-wrapper header-flex' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span className='header-text'>Single QR Code Generation</span>
                                    {singleQRGenerated ? (
                                        <Button variant="contained" style={{ backgroundColor: 'black', color: 'white' }} onClick={singleQRDownload}>
                                            Download Single PDF
                                        </Button>
                                    ) : (
                                        <Button variant="contained" style={{ backgroundColor: 'black', color: 'white' }} onClick={createSingleQR}>
                                            Generate QR Code
                                        </Button>
                                    )}
                                </div>
                            </Box>

                            {/* Multiple QR Codes Box */}
                            <Box sx={{ backgroundColor: 'rgba(100, 100, 100, 0.1)', p: 2, mt: 2 }} style={{ marginBottom: 25 }}>
                                <div className='header-wrapper header-flex' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span className='header-text'><span style={{ color: 'black' }}>Generate Multiple QR Codes</span> by Filling the Following Details</span>
                                    {multipleQRGenerated && (
                                        <IconButton onClick={handleEditClick} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </div>
                                <Grid container spacing={2} style={{ marginTop: 10 }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Quantity"
                                            variant="outlined"
                                            value={quantity}
                                            onChange={handleQuantityChange}
                                            disabled={!isEditable}
                                            InputProps={{
                                                readOnly: !isEditable,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Number"
                                            variant="outlined"
                                            value={number}
                                            onChange={handleNumberChange}
                                            disabled={!isEditable}
                                            InputProps={{
                                                readOnly: !isEditable,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="QR Purpose"
                                            variant="outlined"
                                            value={qrPurpose}
                                            onChange={handleQrPurposeChange}
                                            disabled={!isEditable}
                                            InputProps={{
                                                readOnly: !isEditable,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                    {multipleQRGenerated && !isEditable ? (
                                        <Button variant="contained" style={{ backgroundColor: 'black', color: 'white' }} onClick={multipleQRDownload}>
                                            Download Multiple PDF
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'black', color: 'white' }}
                                            onClick={createMultipleQR}
                                            disabled={isMultipleQRButtonDisabled}
                                        >
                                            Generate Multiple QR Codes
                                        </Button>
                                    )}
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default QRCode;
import { useState, useEffect } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Card,
  Divider,
  Grid,
  CardContent,
  CardActions,
  Switch,
  FormLabel,
  FormControl,
  TextField,
  InputAdornment,
  Collapse,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axiosInstance from "configs/axiosConfig";
import {
  APIRouteConstants,
  dashboardRouteConstants,
} from "constants/routeConstants";
import Loader from "components/Loader";
import CustomBreadcrumbs from "components/Breadcrumbs";
import FormDialog from "components/Dialog/FormDialog";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { updateUserDetail } from "utils/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OTP from "components/Inputs/OTP";
import {  Box } from '@mui/material';
import { useModal } from "Context/GlobalModalContext";
import { RemoveRedEye } from "@mui/icons-material";
const ShippingMethod = ({ breadcrumbs }) => {
  const [loader, setLoader] = useState(false);
  const loggedInUser = useSelector((state) => state.userDetails);
  const { user } = loggedInUser;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const [isActiveShiprazor, setisActiveShiprazor] = useState(user?.is_delivery_option_selected);
  const [isActiveShiprazor, setisActiveShiprazor] = useState(false);
  const [isActiveOwnshipping, setisActiveOwnshipping] = useState(false);
  const [shiprazorId, setShiprazorId] = useState("");
  const [shippingAmount, setshippingAmount] = useState(null);
  const [isPaymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [paymentFormData, setFormData] = useState({
    entityId: "",
    userName: "",
    password: "",
  });
  const handleShippingAmount = (e) => {
    setshippingAmount(e.target.value);
  };

  const getUser = async () => {
    let res = await axiosInstance.get(APIRouteConstants.AUTH.ME);
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data && data.success) {
      const user = data.data;
      updateUserDetail(user);
    }
    setLoader(false);
  };

  const getShiprazorStatus = async () => {
    try {
      setLoader(true);
      let res = await axiosInstance.get(
        APIRouteConstants.SHIPRAZOR.GET_SHIPRAZOR_STATUS
      );
      if (res === false) {
        setLoader(false);
        return
      }
      let { data, response } = res;
      if (data && res.status) {
        data?.data?.forEach((element) => {
          if (element?.shipping_comapany_name === "Shiprazor Service") {
            console.log("element", element);
            setisActiveShiprazor(element?.status);
            setShiprazorId(element?.id);
          } else if (element?.shipping_comapany_name === "SelfDelivery") {
            console.log("element12", element);
            setisActiveOwnshipping(element?.status);
            if (element?.status) {
              // getShippingAmount();
            }
          }
        });
      }
      setLoader(false);
    } catch (err) {
      console.log("getShiprazorStatus", err);
    }
  };

  const getShippingAmount = async () => {
    try {
      setLoader(true);
      let res = await axiosInstance.get(
        APIRouteConstants.SHIPRAZOR.GET_SHIPPING_AMOUNT
      );
      if (res === false) {
        setLoader(false);
        return
      }
      let { data, response } = res;
      if (data && res.status) {
        setshippingAmount(data?.amount);
        sessionStorage.setItem("shippng_amount", JSON.stringify(data?.amount))
      } else {
        enqueueSnackbar(response?.data?.error, { variant: "error" });
        setisActiveOwnshipping(false);
      }
      setLoader(false);
    } catch (err) {
      console.log("getShippingAmount", err);
    }
  };

  const addShippingAmount = async () => {
    try {
      // console.log("shippingAmount", shippingAmount);
      setLoader(true);
      let res = await axiosInstance.put(
        APIRouteConstants.SHIPRAZOR.ADD_SHIPPING_AMOUNT,
        { amount: shippingAmount }
      );
      if (res === false) {
        setLoader(false);
        return
      }
      let { data, response } = res;
      if (data && res.status) {
        setshippingAmount(data?.amount)
        sessionStorage.setItem("shippng_amount", JSON.stringify(data?.amount))
        getUser()
        enqueueSnackbar("Shipping amount added successfully", { variant: "success" });
      } else {
        enqueueSnackbar(response?.data?.amount[0], { variant: "amount" });
        setisActiveOwnshipping(false);
      }
      setLoader(false);
    } catch (err) {
      console.log("addShippingAmount", err);
      setLoader(false);
    }
  };
  const updateShiprazorStatus = async () => {
    try {
      setLoader(true);
      const requestBody = {
        status: !isActiveShiprazor,
        amount: shippingAmount,
      };
      const requestBody1 = { status: !isActiveShiprazor };
      const updatedrequestbody = isActiveShiprazor ? requestBody : requestBody1;

      let res = await axiosInstance.put(
        `${APIRouteConstants.SHIPRAZOR.UPDATE_SHIPRAZOR_STATUS}${shiprazorId}`,
        updatedrequestbody
      );
      if (res === false) {
        setLoader(false);
        return
      }
      let { data, response } = res;


      // if (response.status === 400) {
      //   for (let i in response.data) {
      //     enqueueSnackbar(_.capitalize(i) + ': ' + response?.data[i], { variant: 'error' });
      //   }

      // }

      if (data && res.status) {
        setisActiveShiprazor(data?.status);
        console.log("response", data)
        if (data?.message) {
          enqueueSnackbar(data?.message, { variant: "success" });
          addShippingAmount()
        } else {
          // addShippingAmount()
          enqueueSnackbar("Shiprazor enabled sucessfully", {
            variant: "success",
          });

        }
      }
      setLoader(false);
    } catch (err) {
      console.log("updateShiprazorStatus", err);
    }
  };
  const updateOwnShippingStatus = async () => {
    try {
      setLoader(true);

      // if (user?.shipping_amount == null) {
      //   enqueueSnackbar("If you don't want to add a shipping cost now, you can enter 0.", { variant: "error" });
      //   return
      // }


      let res = await axiosInstance.put(
        `${APIRouteConstants.SHIPRAZOR.UPDATE_OWNSHIPPING_STATUS}`, { status: !isActiveOwnshipping });
      if (res === false) {
        setLoader(false);
        return
      }
      let { data, response } = res;
      console.log("updateOwnShippingStatus", data, response);

      if (response?.data?.error) {
        enqueueSnackbar(response?.data?.error, { variant: "success" });
      }
      if (data && res.status) {
        console.log("updateOwnShippingStatus1", data);
        setisActiveShiprazor(data?.status);
        // getShippingAmount();
        if (data?.msg) {
          enqueueSnackbar(data?.msg, { variant: "success" });
        }
      }
      setLoader(false);
    } catch (err) {
      console.log("updateShiprazorStatus", err);
    }
  };


  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    getShiprazorStatus();
    getUser()
    if (user?.own_shipping_selected) {
      getShippingAmount();
    }
  }, [isActiveShiprazor, isActiveOwnshipping]);
  useEffect(() => {
    if (user?.own_shipping_selected) {
      getShippingAmount();
    }
  }, [user?.own_shipping_selected]);
  console.log("user1", user)

  const updateShippingModeShiprazor = async (mode) => {
    setisActiveShiprazor(!isActiveShiprazor);
    updateShiprazorStatus();
  };

  const updateShippingModeOwnShipping = async (mode) => {
    setisActiveOwnshipping(!isActiveOwnshipping);
    updateOwnShippingStatus()
  };

  const { openModal } = useModal();

  const handleOpenTotorialshiprazor = (titile,url) => {
    openModal(titile, url);
  };

  return (
    <div className="website-analytics-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs list={breadcrumbs} name={"Shipping"} />
      </div>
      <Grid container className="component-grid">
        <Grid item xs={12}>
          <Card className="list-wrapper dashboard-card-wrapper">
            <CardContent>
              <div
                className="header-wrapper"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <span className="header-text">Shipping Methods</span>
                <span style={{ fontSize: "0.8rem" }}>
                  You have to select anyone of the shipping methods to proceed
                  to the next step.
                </span>
              </div>
              <Divider />
              {/* <div className="content-wrapper content-flex">
                <span className="content-text">
                To start accepting payments on your online store, you need to
                set up the payment method(s) you prefer to use for your
                business. It is important to offer a variety of methods to pay
                including Credit card, Mobile Money etc. The more ways you
                  enable your customers to pay, the higher chance you have of
                  making sales. Always make it easier for your customers to pay
                  in the way that suits them.
                  </span>
                  </div> */}
              <div className="content-wrapper content-flex">
                <Grid container className="filter-wrapper">
                  <Grid item sm={12} className="payment-card">
                    <div className="payment-header">
                      <div className="detail-sec-wrapper">
                        {
                          <div>
                            <img
                              className="logo-image"
                              src={require("../../../../assets/images/shiprazor_trans.png")}
                              alt=""
                            />
                          </div>
                        }
                        <div>
                          <span className="detail-sec-text">
                            {"Shiprazor"}
                            <br />
                          </span>
                        </div>
                      </div>
                      <Switch
                        checked={isActiveShiprazor}
                        disabled={user?.shiprazor_key_exist === false}
                        color="success"
                        onChange={() => {
                          updateShippingModeShiprazor();
                        }}
                      />
                    </div>
                    {user?.shiprazor_key_exist ? (
                      <Box sx={{ textAlign: "left" }}>
                        <Box
                          sx={{
                            display: { md: "flex", lg: "flex" },
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: {
                                xs: "0.6rem",
                                sm: "0.7rem",
                                md: "0.75rem",
                              },
                              lineHeight: 1.5,
                              color: "text.primary",
                            }}
                          >
                            Add the URL below. This will allow us to receive
                            real-time order shipment and tracking updates:
                            <Tooltip title="Know more.." arrow>
                              <IconButton
                                style={{ padding: 0 }}
                                onClick={handleToggle}
                              >
                                <InfoOutlinedIcon sx={{ maxHeight: "20px" }} />
                              </IconButton>
                            </Tooltip>
                            {/* <br />
                            https://api.webbieshop.com/shiprazor/shiprazor-webhook/ */}
                          </Typography>
                          <Button
                            size="small"
                            onClick={() =>
                              handleOpenTotorialshiprazor(
                                "Here’s how you can add the URL to Shiprazor",
                                "https://webbie.s3.af-south-1.amazonaws.com/Sample_files_webbieshop/ADD_Webhook_url Video.mp4"
                              )
                            }
                            variant="outlined"
                            sx={{
                              fontSize: {
                                xs: "0.61rem", // Reduced font size
                                sm: "0.62rem",
                                md: "0.65rem",
                                lg: "0.66rem",
                              },
                              background: "#688ad5", // Default background color
                              color: "#fff",
                              borderColor: "#fff",
                              pl: 0.5, // Reduced padding left
                              pr: 0.5, // Reduced padding right
                              minWidth: "50px", // Adjust button width
                              height: "30px", // Adjust button height
                              textTransform: "none",
                              marginRight: 1,
                              "&:hover": {
                                backgroundColor: "#506bb3", // Background color on hover
                                borderColor: "#ccc", // Optional: change border color on hover
                              },
                            }}
                          >
                            Tutorial
                          </Button>

                          {/* <Button
                            size="small"
                            onClick={handleToggle}
                            variant="contained"
                            sx={{
                              fontSize:{
                                xs:"0.6rem",sm:"0.7rem",md:"0.8rem",lg:"0.8rem"
                              },
                                textDecoration: "none",
                              textTransform: "none",
                            }}
                          >
                            Know More
                          </Button> */}
                        </Box>

                        <Collapse in={open}>
                          <Box
                            sx={{
                              mt: 0,
                              pl: { xs: 1, sm: 2 }, // Adjust padding for mobile
                              overflowWrap: "break-word", // Prevent text from overflowing
                              wordWrap: "break-word", // Allow long words to break and wrap
                            }}
                          >
                            <Typography
                              fontSize={{ xs: "0.7rem", sm: "0.75rem" }}
                              color="text.secondary"
                            >
                              <ol
                                style={{
                                  paddingLeft: "0.5rem",
                                  margin: 0,
                                  fontSize: "0.7rem",
                                }}
                              >
                                <li>Go to your Dashboard.</li>
                                <li>Click on Settings.</li>
                                <li>Select Configure Webhook.</li>
                                <li>
                                  Add below URL:
                                  
                                  <p style={{ wordWrap: "break-word" }}>
                                    https://api.webbieshop.com/shiprazor/shiprazor-webhook/
                                  </p>
                                </li>
                              </ol>
                            </Typography>
                          </Box>

                          {/* <Box sx={{ mt: 1 }}>
                            <Typography
                              fontSize={"0.75rem"}
                              color="text.secondary"
                            >
                              Adding this URL will allow us to receive real-time
                              updates on order shipments and tracking.
                            </Typography>
                          </Box> */}
                        </Collapse>
                      </Box>
                    ) : (
                      <Box sx={{ textAlign: "left" }}>
                        <Box
                          sx={{
                            display: { md: "flex", lg: "flex" },
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: {
                                xs: "0.6rem",
                                sm: "0.7rem",
                                md: "0.75rem",
                              },
                              lineHeight: 1.5,
                              color: "text.primary",
                            }}
                          >
                            Please follow these steps to generate the API key:
                            <Tooltip title="Know more.." arrow>
                              <IconButton
                                style={{ padding: 0 }}
                                onClick={handleToggle}
                              >
                                <InfoOutlinedIcon sx={{ maxHeight: "20px" }} />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                          <Button
                            size="small"
                            onClick={() =>
                              handleOpenTotorialshiprazor(
                                " Here’s how you can generate the API key for Shiprazor",
                                "https://webbie.s3.af-south-1.amazonaws.com/Sample_files_webbieshop/Generate-api-key Video.mp4"
                              )
                            }
                            variant="contained"
                            sx={{
                              fontSize: {
                                xs: "0.5rem", // Reduced font size
                                sm: "0.6rem",
                                md: "0.7rem",
                                lg: "0.6rem",
                              },
                              background: "#688ad5", // Default background color
                              color: "#fff",
                              borderColor: "#fff",
                              pl: 0.5, // Reduced padding left
                              pr: 0.5, // Reduced padding right
                              minWidth: "70px", // Adjust button width
                              height: "30px", // Adjust button height
                              textTransform: "none",
                              marginRight: 1,
                              "&:hover": {
                                backgroundColor: "#506bb3", // Background color on hover
                                borderColor: "#ccc", // Optional: change border color on hover
                              },
                            }}
                          >
                            Tutorial
                          </Button>
                        </Box>

                        <Collapse in={open}>
                          <Box sx={{ mt: 0, pl: 2 }}>
                            <Typography
                              fontSize={"0.75rem"}
                              color="text.secondary"
                            >
                              <ol
                                style={{
                                  paddingLeft: "0.5rem",
                                  margin: 0,
                                  fontSize: "0.7rem",
                                }}
                              >
                                <li>Go to Dashboard.</li>
                                <li>Click on Settings.</li>
                                <li>Select Configure API.</li>
                                <li>Generate API Key.</li>
                              </ol>
                            </Typography>
                          </Box>
                        </Collapse>
                      </Box>
                    )}
                    {
                      <ShipRazorSetup
                        refreshUser={getUser}
                        setLoader={setLoader}
                        user={user}
                      />
                    }
                  </Grid>
                  <Grid flexDirection={"column"} display={"flex"}>
                    {/* <span style={{ paddingBottom: 5, fontSize: 16 }}>
                    Prefer to handle shipping yourself? Turn on your own shipping.
                    </span> */}
                    {/* <span style={{ paddingBottom: 15, fontSize: 16 }}>
                      Switch on your own shipping
                    </span> */}
                  </Grid>
                  <Grid item sm={12} className="payment-card">
                    <span style={{ paddingBottom: 5, fontSize: 16 }}>
                      Prefer to handle shipping yourself? Turn on your own
                      shipping.
                    </span>
                    <div className="payment-header">
                      <div className="detail-sec-wrapper">
                        {
                          <img
                            style={{
                              filter: "drop-shadow(1px 1px 1px #f7f7f7)",
                            }}
                            className="logo-image"
                            src={require("../../../../assets/images/images/truck.png")}
                            alt=""
                          />
                        }
                        <div>
                          <span className="detail-sec-text">
                            {"Own shipping"}
                            <br />
                          </span>
                        </div>
                      </div>
                      <Switch
                        checked={isActiveOwnshipping}
                        color="success"
                        // disabled={user?.shipping_amount === null}
                        onChange={() => {
                          updateShippingModeOwnShipping();
                        }}
                      />
                    </div>
                    {/* {isActiveOwnshipping === false && ( */}
                    {isActiveOwnshipping && (
                      <>
                        <Grid
                          item
                          sm={12}
                          pt={3}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <TextField
                            size="small"
                            label="Enter shipping cost"
                            placeholder="Enter shipping cost"
                            value={shippingAmount}
                            focused={shippingAmount != null ? true : false}
                            onChange={handleShippingAmount}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#1876D2",
                                  border: "1px solid #1876d2", // Change this to your desired color
                                },
                              },
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#1876D2", // Change this to your desired color for label
                                },
                              },
                            }}
                          />
                          <Button
                            size="small"
                            style={{ marginLeft: 10, background: "#1876D2" }}
                            variant="contained"
                            onClick={addShippingAmount}
                          >
                            Add
                          </Button>
                        </Grid>
                        <div style={{ paddingTop: 5 }}>
                          <p style={{ fontSize: "0.6rem" }}>
                            If you don't want to add a shipping cost now, you
                            can enter 0.
                          </p>
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
              </div>
            </CardContent>
            {!user?.isPublished ? (
              <CardActions className="footer-wrapper">
                <div className="action-wrapper">
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ backgroundColor: "white" }}
                    onClick={() => {
                      navigate(dashboardRouteConstants.HOME);
                    }}
                  >
                    Next {">"}
                  </Button>
                </div>
              </CardActions>
            ) : (
              ""
            )}
          </Card>
        </Grid>
        <FormDialog
          setOpen={() => setPaymentDialogOpen(!isPaymentDialogOpen)}
          viewSave={true}
          viewCancel={true}
          open={isPaymentDialogOpen}
          className="credential-dialog"
          title={"Set Peach Payments Checkout Credentials"}
          // handleSave={() => addPeachPaymentCredentials()}
        >
          <div className="credential-wrapper">
            <Grid container>
              <Grid xs={12} className="form-wrapper">
                <FormLabel className="form-label">
                  <span className="label-text">
                    Entity Id
                    <span className="required">*</span>
                  </span>
                </FormLabel>
                <FormControl className="form-control">
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="Entity Id"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setFormData({
                        ...paymentFormData,
                        entityId: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} className="form-wrapper">
                <FormLabel className="form-label">
                  <span className="label-text">
                    Secret Token
                    {/* <span className='required'>*</span> */}
                  </span>
                </FormLabel>
                <FormControl className="form-control">
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="Username"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setFormData({
                        ...paymentFormData,
                        userName: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} className="form-wrapper">
                <FormLabel className="form-label">
                  <span className="label-text">
                    Password
                    {/* <span className='required'>*</span> */}
                  </span>
                </FormLabel>
                <FormControl className="form-control">
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="xxxxxxxxxxxxxx"
                    type="password"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setFormData({
                        ...paymentFormData,
                        password: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </FormDialog>
      </Grid>
    </div>
  );
};

export default ShippingMethod;

const ShipRazorSetup = ({ setLoader, refreshUser, user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showVerify, setOpenVerify] = useState(false);
  const [otp, setOTP] = useState("");

  console.log("Shiprazor12 ", user?.shiprazor_key_exist);
  // const { shiprazor_key_exist } = user;
  
  
  const verifyShipRazorAcc = async () => {
    setLoader(true);
    let res = await axiosInstance.post(APIRouteConstants.SHIPRAZOR.SR_ACCOUNT);
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;


    if (
      response &&
      _.get(response, "data") &&
      _.get(response.data, "shiprazor_response") &&
      response.data.shiprazor_response.status == 400
    ) {
      enqueueSnackbar(response.data.error, { variant: "error" });
    }

    // setOpenVerify(true);

    if (data && _.get(data, "shiprazor_response")) {
      enqueueSnackbar("OTP sent to your registerd phone number", {
        variant: "success",
      });
      setOpenVerify(true);
      // details.hostName = data['host_name']
      // setHostName(data['host_name'])
    }
    setLoader(false);
  };

  const verifyOTP = async () => {
    setLoader(true);
    let res = await axiosInstance.post(APIRouteConstants.SHIPRAZOR.VERIFY_OTP, {
      otp: otp,
    });
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (
      response &&
      _.get(response, "data") &&
      _.get(response.data, "shiprazor_response") &&
      response.data.shiprazor_response.status == 400
    ) {
      enqueueSnackbar(response.data.error, { variant: "error" });
    }

    if (data && _.get(data, "success")) {
      setOpenVerify(true);
      refreshUser();
    }
    setLoader(false);
  };

  return (
    <Grid
      container
      className="step-form-container form-grid-container dashboard-form-container"
      spacing={0}
      wrap="wrap"
    >
      {!user?.shiprazor_key_exist ? (
        <>
          {!showVerify ? (
            <Grid item md={12} sm={12} className="action-grid" pt={2}>
              {/* <img className="logo-image" src={require('../../../../assets/images/shiprazor.jpeg')} alt="" /> */}
              {/* <span className=''>Don’t have an account. Create one now for FREE </span> */}
              <Button
                href="https://www.shiprazor.com/signup"
                target="_blank"
                variant="contained"
                color="secondary"
                style={{ marginRight: "1rem" }}
              >
                Create Account Now
              </Button>
              <Button
                onClick={() => setOpenVerify(true)}
                variant="contained"
                color="success"
              >
                Have an account? Link Now.
              </Button>
            </Grid>
          ) : (
            <Grid item md={12} sm={12}>
              <div className="form-wrapper">
                <FormLabel className="form-label">
                  <span
                    className="label-text"
                    style={{ fontSize: "0.8rem", fontWeight: "400" }}
                  >
                    Enter API key generated from your Shiprazor Dashboard
                  </span>
                </FormLabel>
                <FormControl fullWidth className="select-wrapper">
                  {/* <OTP
                    separator={<span>-</span>}
                    value={otp}
                    onChange={setOTP}
                    length={6}
                  /> */}

                  <ApiKeyInput
                    setLoader={setLoader}
                    refreshUser={refreshUser}
                  />
                  <span className="error-text">
                    {/* {errors.store_name?.type === "required" && (
                                'Store name is required'
                                )} */}
                  </span>
                </FormControl>
                {/* <div style={{ marginTop: "1rem" }} className="action-wrapper">
                  <Button
                    sx={{ width: "fit-content", mr: 1 }}
                    variant="contained"
                    onClick={verifyOTP}
                    color="secondary"
                  >
                    Verify
                  </Button>
                  <Button
                    sx={{ width: "fit-content" }}
                    // variant="outlined"
                    onClick={() => {
                      setOpenVerify(false);
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </div> */}
              </div>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Button
            size="small"
            href="https://www.shiprazor.com/signup"
            target="_blank"
            variant="contained"
            color="secondary"
            sx={{mt:2}}
            style={{ marginRight: "1rem" }}
          >
            Go to Shiprazor
          </Button>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            lg={12}
            height={"2rem"}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            className="action-grid"
            pl={0}
            pb={4}
            pt={4}
          >
            <CheckCircleOutlineIcon color="success" />
            <Button variant="text" color="success">
              Already Linked
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};




const ApiKeyInput = ({setLoader,refreshUser}) => {
  const [apiKey, setApiKey] = useState('');

  const handleInputChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleSubmit = async () => {
    setLoader(true);
    console.log('API Key:', apiKey);
    // return
    let res = await axiosInstance.post(APIRouteConstants.SHIPRAZOR.ADD_VENDORE_SHIPRAZOR,{api_key:apiKey});
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    // console.log("response",response.status)
    if (
      response && response.data&&
      response.status == 400
    ) {
      enqueueSnackbar(response.data.error, { variant: "error" });
    }

    if (data && data?.message) {
      enqueueSnackbar("Your Shiprazor account successfully linked", {
        variant: "success",
      });
      setApiKey("")
      refreshUser();
    }
    setLoader(false);
  };


  // const  = () => {
  //   // Handle API key submission (e.g., save it or use it in a request)
  //   console.log('API Key:', apiKey);
  //   // Clear the input field after submission
  //   setApiKey('');
  // };

  return (
    <Box 
      sx={{ 
        display:{md:'flex',sm:"flex",lg:"flex"}, 
        alignItems: 'baseline', 
        justifyContent: 'space-between', 
      }}
    >
      <TextField
        label="API Key"
        variant="standard"
        value={apiKey}
        size="medium"
        onChange={handleInputChange}
        fullWidth
        sx={{ maxWidth: 300 }}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSubmit}
        sx={{ mt:{xs: 2} }}
      >
        Submit
      </Button>
    </Box>
  );
};

